import React, { useEffect, useState } from 'react'

import { TextInput, Checkbox, Button, Group, Box, Radio, Select, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEditProfileDetailsMutation, useGetCurrentSalaryQuery, useGetExpSalaryQuery, useGetLocationQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { useDisclosure } from '@mantine/hooks';
import { useLingui } from '@lingui/react';


const OtherDetails = () => {
    const { i18n } = useLingui();
    const candidateId = localStorage.getItem('candidateId')
    const navigate = useNavigate()

    const token = useSelector((state) => state.user.token)
    const userDetails = useSelector((state) => state?.user.candidateData)

    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);

    const { data: curSalaryData, isLoading: curSalaryLoading, isError: curSalaryError, isFetching: curSalaryFetching, refetch: curSalaryRefetch } = useGetCurrentSalaryQuery({
        skip: !token
    })

    const { data: expSalaryData, isLoading: expSalaryLoading, isError: expSalaryError, isFetching: expSalaryFetching, refetch: expSalaryRefetch } = useGetExpSalaryQuery({
        skip: !token
    })


    const { data: locationData, isLoading: locationLoading, isError: locationError, isFetching: locationFetching, refetch: locationRefetch } = useGetLocationQuery({
        skip: !token
    })
    useEffect(() => {
        if (token) {
            curSalaryRefetch()
            expSalaryRefetch()
        }
    }, [token, curSalaryRefetch, expSalaryRefetch]);




    const [editProfile, { isLoading, isSuccess }] = useEditProfileDetailsMutation()

    const form = useForm({
        initialValues: {
            currentsalary: userDetails?.currentSalaryId?.toString() || '',
            expsalary: userDetails?.expectedSalaryId?.toString() || '',
            location: userDetails?.locationId?.toString() || '',
            license: userDetails?.bikeAndDl?.toLowerCase() || ''

        },

        validate: {
            currentsalary: (value) => (value == '' ? <Trans>Current Salary is Required</Trans> : null),
            expsalary: (value) => (value == '' ? <Trans>Expected Salary is Required</Trans> : null),
            location: (value) => (value == '' ? <Trans>Location is Required</Trans> : null),
            license: (value) => (value == '' ? <Trans>License is Required</Trans> : null),
        },
    });

    const handleSubmit = (values) => {
        openLoading()
        const convertedObject = {
            expectedSalaryId: parseInt(values.expsalary, 10),
            currentSalaryId: parseInt(values.currentsalary, 10),
            locationId: parseInt(values.location, 10),
            bikeAndDl: values.license,
        };
        editProfile(convertedObject)
            .then(() => {
                closeLoading();
                navigate('/myprofile')
            })
            .catch((error) => {
                console.error("Edit profile error:", error);
                closeLoading();
            });

    }


    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                <div>

                    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}
                        style={{ display: "flex", flexDirection: "column", gap: ".5rem", height: "auto", padding: "10px" }}>


                        <Select
                            key={i18n.locale}
                            label={<Trans>What's Your Current Salary</Trans>}
                            placeholder={t`Select current Salary`}
                            data={curSalaryData?.map((item) => ({
                                value: item?.id.toString(),   // Use the id as the value
                                label: item?.masterName // Display the masterName
                            }))}
                            searchable
                            // onChange={handleSelectChange3}
                            // value={selectedValue3}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('currentsalary',)}
                        />


                        <Select
                            label={t`What's Your Salary Expectation`}
                            placeholder={t`Select Salary Expectation`}
                            data={expSalaryData?.map((item) => ({
                                value: item?.id.toString(),   // Use the id as the value
                                label: item?.masterName // Display the masterName
                            }))}
                            searchable
                            // onChange={handleSelectChange3}
                            // value={selectedValue3}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('expsalary')}
                        />

                        <Select
                            label={t`Choose Your Location`}
                            placeholder={t`Select Your Location`}
                            data={locationData?.map((item) => ({
                                value: item?.id.toString(),   // Use the id as the value
                                label: item?.masterName // Display the masterName
                            }))}
                            searchable
                            // onChange={handleSelectChange3}
                            // value={selectedValue3}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('location')}
                        />


                        <label><Trans>Do you have a bike or Driving License?</Trans></label>
                        <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                            <Radio
                                label={<Trans>Yes</Trans>}
                                value="yes"
                                checked={form.values.license === 'yes'}
                                onChange={(event) => form.setFieldValue('license', event.currentTarget.value)}
                            />
                            <Radio
                                label={<Trans>No</Trans>}
                                value="no"
                                checked={form.values.license === 'no'}
                                onChange={(event) => form.setFieldValue('license', event.currentTarget.value)}
                            />

                        </div>



                        <Group justify="center" mt="md" >
                            <Button

                                type="submit" style={{
                                    width: '100%',
                                    marginBottom: '5rem',
                                }}><Trans>Save</Trans></Button>
                        </Group>
                    </form>

                </div>
            </Box>
        </>
    )
}

export default OtherDetails