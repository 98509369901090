import React from 'react'
import {
  Card,
  Text,
  Image,
  Button,
  Center,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';

const WhiteCollar = () => {

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate('/onboardingbasicinformation');
  }


  return (
    <div>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        // borderRadius: "30px",
        maxWidth: "600px",
        top: "55px",
        // background: "white",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>



        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
            <Trans>Basic Information</Trans>
          </Text>
        </div>
      </div>

      <Center>
        <Text style={{ marginTop: "250px", fontWeight: "bold" }}>
          <Trans>This app is only for Blue and Gray Collar</Trans>
        </Text>
      </Center>

      <Button style={{width: "50%", marginLeft: "25%", marginTop:"20px"}} mb={"10px"} color='purple' onClick={arrowBack}>
       <Trans>Back</Trans>
                    </Button>

      
    </div>
  )
}

export default WhiteCollar