import React from 'react'
import {
  Card,
  Text,
  Image,
  Button,
  Center,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import iimtmainimg from '../../public/iimtmain.jpg'
import { RxCross2 } from "react-icons/rx";
// import iimtmainimg1 from '../../public/midbanner/iimt2.jpg'

const HomeTrandingMidBannerDescription = () => {

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate('/');
  }


  return (
    <div>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        // borderRadius: "30px",
        maxWidth: "600px",
        top: "55px",
        // background: "white",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>



        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
           Description
          </Text>
        </div>
      </div>

      
      <div style={{marginTop: "100px", marginRight:"20px", margin:"20px"}}>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"10px", marginBottom:"4px", paddingTop:"50px"  }}>
                        <RxCross2 
                        onClick={arrowBack} 
                        color='black' size={30}  />
                        </div>
   <Center style={{marginTop: "0px"}}>
      <Image src={iimtmainimg} width="100%" height="100%" style={{borderRadius:"10px", paddingTop:"0px"}}/>
      </Center>
      </div>

      {/* <Button style={{width: "50%", marginLeft: "25%", marginTop:"10px"}} mb={"10px"} color='#0180ff' onClick={arrowBack}>
       <Trans>Back</Trans>
                    </Button> */}

         <div style={{marginTop: "60px"}}>
                 <h6 style={{color:'white'}}>.</h6>
                </div>   
        

      
    </div>
  )
}

export default HomeTrandingMidBannerDescription