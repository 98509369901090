import React,{useRef} from 'react'
import {Carousel} from '@mantine/carousel'
import Autoplay from 'embla-carousel-autoplay'
import classes from '../styles/Loginshowcase.module.css'


const images = [
  "onboardimages/onboard2.png",
  "onboardimages/onboard1.png",
  "onboardimages/onboard3.png",
  "onboardimages/onboard4.png",
  "onboardimages/onboard5.png",
  "onboardimages/onboard6.png",
  "onboardimages/onboard7.png"
];

const LoginShowcaseCarousel = () => {

   const autoplay = useRef(Autoplay({delay:4000}))


  const slides = images.map((url) => (
    <Carousel.Slide key={url}>
      <img src={url} width="100%" height="100%" style={{borderRadius:"10px"}}/>
    </Carousel.Slide>
  ));
   


  return (
    <div className={classes.wrapper}>
     <Carousel withIndicators styles={
      {
        container : {transform:"none"},
        slide:{height:"300px"},
        controls:{display:"none"},
        indicator:{backgroundColor:"#0180ff"},
        indicators:{marginTop:"3rem"} 
      }
     }
     plugins={[autoplay.current]}
     loop
    //  onMouseEnter={autoplay.current.stop}
    //  onMouseLeave={autoplay.current.reset}
     >{slides}</Carousel>
    </div>
  )
}

export default LoginShowcaseCarousel