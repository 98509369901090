import React, { useState, useEffect } from 'react';
import { Text, Center, Loader } from '@mantine/core';
import { useGetJobAppliedDetailQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import classes from '../styles/JobsCard.module.css';
import AppliedJobsCard from '../components/AppliedJobsCard'
import { Trans } from '@lingui/macro';



const AppliedJob = () => {

  const candidateid = localStorage.getItem('candidateId')
  const token = useSelector((state) => state?.user.token)




  const { data: jobappliedDetailsdata, refetch: refetchAppiedJobDetails } = useGetJobAppliedDetailQuery({ candidateid }, {
    skip: !token
  })

  // console.log("jobappliedDetailsdata-----", jobappliedDetailsdata?.content)


  useEffect(() => {
    if (token) {
      refetchAppiedJobDetails();
    }

  }, [token, refetchAppiedJobDetails]);


  return (
    <>
      <Center>
        <Text style={{ marginTop: "10px", fontWeight: "bold" }}>
          <Trans>My Applied Jobs</Trans>
        </Text>
      </Center>


      {jobappliedDetailsdata?.content?.length != null ? (
        <div>
          <div>
            {jobappliedDetailsdata?.content?.length > 0 && (
              jobappliedDetailsdata?.content?.map(job => {
                return (
                  <AppliedJobsCard
                    jobId={job?.jobId}
                    applicationStatus={job?.applicationStatus}
                    appliedDate={job?.appliedDate}
                    jobTitle={job?.jobTitle}
                    trade={job?.trade}
                    salary={job?.salary}
                    logo={job?.logo}
                    companyName={job?.companyName}
                    workType={job?.typeOfWork}
                    location={job?.location}
                  />
                )
              })
            )}
          </div>
          <div style={{ marginTop: "60px" }}>
            <h6>.</h6>
          </div>
        </div>

      ) : (
        <div>
          <Center>
            <Loader style={{ marginTop: "220px" }} color="#034078" size={30} />
          </Center>
        </div>
      )}
    </>

  )
}

export default AppliedJob