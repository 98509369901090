import React, { useState, useEffect } from 'react';
import { Text, Center, Card, Group, Chip, Button, Paper, Loader, Modal, Stack } from '@mantine/core';
import classes from '../styles/OnboardingBasicInformationCard.module.css';
import { UserAuth } from '../context/AuthContext';
import master from '/src/data/master.json';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { useWindowScroll } from '@mantine/hooks';
import { useDispatch, useSelector } from "react-redux";
import { onboardingField } from "../redux/userSlice";
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const OnboardingQuestions = () => {
  const { i18n } = useLingui();
  const { user } = UserAuth();
  var userId = localStorage.getItem('userId');

  const [proData, setProData] = useState([]);
  const dispatch = useDispatch();
  const [masterParentIdData, setMasterParentIdData] = useState(0);
  //const [page, setPage] = useState(0);
  const [masterParentIdFil, setMasterParentIdFil] = useState(null);
  const [masterOnboardingIdFil, setMasterOnboardingIdFil] = useState(null);

  const [noOptionData, setNoOptionData] = useState(0);
  const [onboardingParentIdData, setOnboardingParentIdData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [openedOptionAlertEmpty, setOpenedOptionAlertEmpty] = useState(false);
  const [openedAlertEmpty, setOpenedAlertEmpty] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({}); // Modify state to hold selected options across pages
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentBackPage, setcurrentBackPage] = useState(0);
  const [currentNextPage, setcurrentNextPage] = useState(0);
  const [resultBackPage, setResultCurrentBackPage] = useState(null);
  const [resultNextPage, setResultCurrentNextPage] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query
  const posturl = import.meta.env.VITE_BASE_URL + `/candidate/onboarding/question`;
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [scroll, scrollTo] = useWindowScroll();
  const onboardingGetField = useSelector((state) => state?.user?.onboardingField);

  const collegeId = localStorage.getItem("collegeId")
  const candidateId = localStorage.getItem("candidateId")

  
  const getAllCollages = async (collegeId)=>{
    const url = import.meta.env.VITE_BASE_URL + `/master?masterTypeId=16&sort=true&active=true`;
    try {
      const response = await fetch(`${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const collages = await response?.json(); 
      const findCollage = await collages.find(college => college.id == collegeId)
      console.log("cccc-", findCollage)
      return findCollage; 

    } catch (error) {
      console.error('Error:', error);
    }
  }


  useEffect(() => {
    const findCollage = async ()=>{
      if(collegeId){
         if(user?.accessToken){
           const findCollage =  await getAllCollages(collegeId)
            if(findCollage){
              const {id} = findCollage; 
              const url = import.meta.env.VITE_BASE_URL + `/candidate`;
                      try {
                        const response = await fetch(`${url}`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${user?.accessToken}`,
                            Accept: 'application/json',
                          },
                          body: JSON.stringify({
                            candidateCollegeInstituteId : id
                          })
                        });

                        if (!response.ok) {
                          throw new Error('Network response was not ok');
                        }else{
                          localStorage.removeItem("collegeId")
                        }

                      } catch (error) {
                        console.error('Error:', error);
                      }
            }
         } 
       }
    }
    findCollage();
  }, [user])
  


  useEffect(() => {
    const fetchData = async () => {
      // console.log("FetchDatamasterParentId1111111111", masterParentIdData, onboardingParentIdData, currentPage)
      const url = import.meta.env.VITE_BASE_URL + `/candidate/onboarding/question?masterParentId=${masterParentIdData}&onboardingParentId=${onboardingParentIdData}&page=${currentPage}&size=1`;

      try {
        const response = await fetch(`${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
            Accept: 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok in ProfileData');
        }

        const profileResult = await response.json();
        setProData(profileResult?.dtos);
        setIsLastPage(profileResult?.lastPage);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (user?.accessToken) {
      fetchData();
      fetchCandidateProfileData();
    }
    setMasterParentIdData(0);
  }, [user, currentPage]);

  // useEffect(() => {
  //   if (user?.accessToken) {
  //     fetchBackData(currentBackPage);
  //   }

  // }, [user, currentBackPage]);


  // useEffect(() => {
  //   if (user?.accessToken) {
  //     fetchNextData(currentNextPage+1);
  //   }

  // }, [user, currentNextPage]);



  

  const fetchCandidateProfileData = async () => {
    const url = import.meta.env.VITE_BASE_URL + `/candidate`;
    try {
      const response = await fetch(`${url}?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ProfileData');
      }

      const profileResult = await response.json();
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  ////////////////////Back Button////////////////

  // const fetchBackData = async (currentBackPage, item1, item2) => {
  //  // console.log("fetchBackDataCurrentBackPage222222222", currentBackPage, item1, item2)
  //   setProData(null)

  //   const url = import.meta.env.VITE_BASE_URL + `/candidate/onboarding/question?masterParentId=${item1}&onboardingParentId=${item2}&page=${currentBackPage}&size=1`;

  //   try {
  //     if(currentBackPage>=0){

      
  //     const response = await fetch(`${url}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${user?.accessToken}`,
  //         Accept: 'application/json',
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok in BackData');
  //     }

  //     const backResult = await response.json();
  //     //console.log("aaalllllllllljjjjj",backResult)
  //     setResultCurrentBackPage(backResult?.dtos)

    
  //     // setProData(profileResult?.dtos);
  //     // setIsLastPage(profileResult?.lastPage);
  //   }
  //   } catch (error) {
  //     console.error('Error fetching profile data:', error);
  //   }
  // };


  ///////////////////////////////////////////////


    ////////////////////Next Button////////////////

    // const fetchNextData = async (currentNextPage) => {

    //  // console.log(currentPage, "currentPageinnext")

    //   const url = import.meta.env.VITE_BASE_URL + `/candidate/onboarding/question?page=${currentPage}&size=1`;
  
    //   try {
    //     if(currentNextPage>=0){
  
        
    //     const response = await fetch(`${url}`, {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${user?.accessToken}`,
    //         Accept: 'application/json',
    //       },
    //     });
  
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok in NextData');
    //     }
  
    //     const nextResult = await response.json();
    //     setResultCurrentNextPage(nextResult?.dtos)
  
      
    //     // setProData(profileResult?.dtos);
    //     // setIsLastPage(profileResult?.lastPage);
    //   }
    //   } catch (error) {
    //     console.error('Error fetching profile data:', error);
    //   }
    // };
  
  
    ///////////////////////////////////////////////

  const handleOptionClick = async (optionId, questionId,parentId,onbordingParentId) => {

   // console.log("ddddddkkkkkkk",optionId)

  // console.log("mmmmmmmmmmmmmmm222222", masterParentIdData)


    const onboardingFieldRedux = {
      optionId,
      questionId
    };
    fetchCandidateProfileData();
    dispatch(onboardingField(onboardingFieldRedux));
    setMasterParentIdData(optionId);
    setOnboardingParentIdData(questionId);
    try {
      const masterItem = master.find(item => item.id === questionId);
      const propertyName = masterItem ? masterItem.column_name : '';
      const noOption = masterItem ? masterItem.noOption : null;
      setNoOptionData(noOption);

     // console.log("rrrrrriiiiiiiii", propertyName)

      // if (noOption === optionId) {
      //  // navigate('/whitecollar');
      //   setOpenedAlertEmpty(true);
      // }

      const bodyData = {
        [propertyName]: optionId,
        //tradeId: 19,
      };

      const response = await fetch(`${posturl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok onboardPost');
      }

     // const onboardResult = await response.json();
     // console.log("ssssshhhhh", onboardResult)
      

      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [questionId]: {"option_id":optionId, "parent_id":parentId,"questionId":questionId,"onbordingParentId":onbordingParentId, "page": currentPage + 1},
      }));

    //   if (optionId && questionId) {
    //    // console.log(optionId, questionId, currentPage, "kkllllllllllllkkkkkk");
    //     if (currentPage == 0) {
    //         localStorage.setItem("onboardingOptionId1", optionId);
    //         localStorage.setItem("onboardingQuestionId1", questionId);
    //         localStorage.setItem("onboardingPage1", currentPage);
    //     } else if (currentPage == 1) {
    //         localStorage.setItem("onboardingOptionId2", optionId);
    //         localStorage.setItem("onboardingQuestionId2", questionId);
    //         localStorage.setItem("onboardingPage2", currentPage);
    //     } else if (currentPage == 3) {
    //         localStorage.setItem("onboardingOptionId3", optionId);
    //         localStorage.setItem("onboardingQuestionId3", questionId);
    //         localStorage.setItem("onboardingPage3", currentPage);
    //     } else if (currentPage == 4) {
    //         localStorage.setItem("onboardingOptionId4", optionId);
    //         localStorage.setItem("onboardingQuestionId4", questionId);
    //         localStorage.setItem("onboardingPage4", currentPage);
    //     } else if (currentPage == 5) {
    //         localStorage.setItem("onboardingOptionId5", optionId);
    //         localStorage.setItem("onboardingQuestionId5", questionId);
    //         localStorage.setItem("onboardingPage5", currentPage);
    //     }
    // }

      //console.log("333333", selectedOptions, currentPage)
     

      // let pageArray = []
      // pageArray.push({...selectedOptions}) 
      // console.log("pageArrayqqq",pageArray)

      // const resultArray = Object.values(selectedOptions);

      // console.log("SelectedOptionsdfndjnvd", resultArray)

      // const filterArr = resultArray.filter((item)=> {
      //   return item.page == currentPage
        
      // })

      // console.log(filterArr,"schsncnbsmbc")



    } catch (error) {
      console.error('Error submitting option ID:', error);
    }
  };

  const handleNextPage = async () => {   

   // console.log("mmmmmmmmmmmmmmm", selectedOptions, currentPage)

    const resultArray = Object.values(selectedOptions);
    const filterArr = resultArray.filter((item)=> {
     // console.log("a999a,", currentPage, item?.page + 1)
      return item?.page == currentPage
    })

    // console.log("schsncnbsmbc123", masterParentIdData)
   // console.log("33333", currentPage, filterArr)

   // fetchBackData(currentPage - 1, filterArr[0]?.option_id, filterArr[0]?.questionId)
    

      // fetchBackData(currentPage)
      setSearchQuery('');
      scrollTo({ y: 0 });
  
  /*if (selectedOptions[resultNextPage[0]?.options[0]?.parentModel?.masterTypeId] !== undefined) {
    setMasterParentIdData(selectedOptions[resultNextPage[0]?.options[0]?.parentModel?.masterTypeId]?.option_id);
   setOnboardingParentIdData(selectedOptions[resultNextPage[0]?.options[0]?.parentModel?.masterTypeId]?.questionId)
  }*/

  //  if (filterArr[0]?.option_id !== 0 && filterArr[0]?.questionId !== undefined) {
  //   //console.log("SelectedOptionsdfndjnvd", currentPage )
  
  //     setMasterParentIdData(filterArr[0]?.option_id);
  //     setOnboardingParentIdData(filterArr[0]?.questionId)
       
  // }
  
     // console.log("ok testing",filterArr[0]?.option_id,"ques",currentPage,"option_id",selectedOptions[resultNextPage[0]?.id]?.option_id)
      if (!isLastPage) {
        if(masterParentIdData === undefined || masterParentIdData === 0 || masterParentIdData === null)
       {
        if (masterParentIdData === 0 || masterParentIdData === null) {
          
            setOpenedOptionAlertEmpty(true);
          
          
        } else {

          /*
          if (noOptionData === masterParentIdData) {
            navigate('/whitecollar');
          }else{
            setProData(null)
            setCurrentPage(prevPage => prevPage + 1);
            fetchNextData(currentPage+2)
          }
          */
            setProData(null)
            setCurrentPage(prevPage => prevPage + 1);
            //fetchNextData(currentPage+2)
        }
      }
      else if(masterParentIdData !== undefined || masterParentIdData !== 0 || masterParentIdData !== null){
        setProData(null)
        setCurrentPage(prevPage => prevPage + 1);
        //fetchNextData(currentPage+2)
      }
      else{
        setOpenedOptionAlertEmpty(true);
      }
  
      }
    

  
  };


  const handleBackPage = async () => {

    const resultArray = Object.values(selectedOptions);
    const filterArr = resultArray.filter((item)=> {
     // console.log("a999a,", currentPage, item?.page + 1)
      return item?.page == currentPage - 1
    })

    // console.log("schsncnbsmbc", currentPage, selectedOptions)
    //console.log("77777", currentPage - 1, filterArr)

   

   

    // if(filterArr){
    //   setMasterParentIdFil(filterArr[0]?.option_id)
    //   setMasterOnboardingIdFil(filterArr[0]?.questionId)
    // }

    // console.log("SelectedOptionsdfndjnvd", currentPage, 
      //filterArr[0]?.option_id, filterArr[0]?.questionId
    //)
    

    // scrollTo({ y: 0 });
     //setProData(null)

     setProData(null)

    // setSearchQuery('');
    if(filterArr[0]?.option_id && filterArr[0]?.questionId !== undefined){
      if (filterArr[0]?.page == 0) {
        setSelectedOptions(0)
        //fetchBackData(0, 0, 0)
      }
      // else{
      //   fetchBackData(currentPage - 1, filterArr[0]?.option_id, filterArr[0]?.questionId)
      // }
      
    }
    
    // fetchNextData(currentPage)
    if (filterArr[0]?.option_id && filterArr[0]?.questionId !== undefined) {
      //console.log("SelectedOptionsdfndjnvd", currentPage )
      // if (filterArr[0]?.page == 0) {
      //   setMasterParentIdData(0);
      //   setOnboardingParentIdData(0)
      // }
      // else{
      //   setMasterParentIdData(filterArr[0]?.option_id);
      //   setOnboardingParentIdData(filterArr[0]?.questionId)
      // }

      // if (filterArr[0]?.page == 4) {
      //   setMasterParentIdData(931);
      //   setOnboardingParentIdData(1)
      // }
        // if (filterArr[0]?.page == 3) {
        //   setMasterParentIdData(26);
        //   setOnboardingParentIdData(2)
        // }
      //  if(filterArr[0]?.page == 2){
      //   setMasterParentIdData(497);
      //   setOnboardingParentIdData(7)
      // }else
       if (filterArr[0]?.page == 0) {
        setMasterParentIdData(0);
        setOnboardingParentIdData(0)
      }
      else{
        setMasterParentIdData(filterArr[0]?.option_id);
        setOnboardingParentIdData(filterArr[0]?.questionId)
      }

     
        
    }

  
     

     setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
    // const selectedOptionId = selectedOptions[onboardingParentIdData];
   
  };

  const handleSubmit = async () => {
    scrollTo({ y: 0 });
    if (masterParentIdData === 0 || masterParentIdData === null) {
      setOpenedOptionAlertEmpty(true);
    } else {
      navigate('/joblocation');
    }
  };



  const closeOptionAlertEmpty = () => {
    setOpenedOptionAlertEmpty(false);
  };

  const closeAlertEmpty = () => {
    setOpenedAlertEmpty(false);
  };

  // Function to handle search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      
      <Modal
        withCloseButton={false}
        opened={openedOptionAlertEmpty}
        onClose={() => setOpenedOptionAlertEmpty(false)}
        centered
      >
        <div style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "0px" }}>
          <div style={{ textAlign: "center" }}>
          </div>
          <Text style={{ color: "#1F7A8C", textAlign: "center" }} fw={500} size="lg" mb="md">
          <Trans>Please Select Option</Trans>
          </Text>
        </div>
        <Stack align="center">
          <Group position="center" mt="md">
            <Button style={{ marginTop: "0px", borderColor: "#1F7A8C" }} variant="outline" onClick={closeOptionAlertEmpty}>
              <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
              <Trans>OK</Trans>
              </Text>
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Modal
        withCloseButton={false}
        opened={openedAlertEmpty}
        onClose={() => setOpenedAlertEmpty(false)}
        centered
      >
        <div style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "0px" }}>
          <div style={{ textAlign: "center" }}>
          </div>
          <Text align="left" fw={500} size="sm" mb="md">
          <Trans>This app is only for Blue and Gray Collar</Trans>
          </Text>
        </div>
        <Stack align="center">
          <Group position="center" mt="md">
            <Button onClick={closeAlertEmpty}><Trans>OK</Trans></Button>
          </Group>
        </Stack>
      </Modal>



    

      <div style={{ 
       // width: '100%', marginTop: "115px", 
       position: "fixed",
       width: '100%',
      //  display: "grid",
       maxWidth: "600px",
     // backgroundColor: "orange"
        }}>

        <div style={{
        // position: "fixed",
        // width: "100%",
        // height: "30%",
        // zIndex: "1",
        // maxWidth: "600px",
        // top: "15px",
        // paddingBottom: "40px",
        // display: "grid",
        // alignItems: "center",
        // justifyContent: "'center",
        // alignContent: "center",
      //  backgroundColor: "green"
      }}>
        <div style={{ 
         // marginTop: "-20px", marginBottom: "20px", marginLeft: "50px",marginRight: "50px",
            textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
          <Trans>Basic Information</Trans>
          </Text>
        </div>
      </div>

        {/* //////////////////////////////////// */}

        <div>
          {proData?.length > 0 ? (
            proData?.map((item, index) => {
              return (
                <div key={index}>
                  
                    <Card radius="md">
                      <div style={{
                        marginTop: "-10px"
                       // backgroundColor: "purple"
                        // position: "fixed",
                        // width: "100%",
                        // height: "6%",
                        // zIndex: "1",
                        // maxWidth: "600px",
                        // top: "145px",
                      }}>
                        <Card style={{ backgroundColor: "#0180ff", }} withBorder radius="md">
                          <div style={{ marginTop: "0px", }}>
                            
                              <Text style={{ fontWeight: "bold", color: 'white', textAlign: "center", fontSize: "18px", marginBottom: "0px" }}>
                              <Trans id={`question.${item.id}`}>{item?.displayName}</Trans>

                              {/* <Trans id={`question.50`}>{item?.displayName}</Trans> */}
                              </Text>
                           
                          </div>
                        </Card>

                        <div style={{ justifyContent: 'center', marginTop: '5px' }}>
                        <input
                         key={i18n.locale}
      type="text"
      placeholder={t`Search options...`}
      value={searchQuery}
      onChange={handleSearch}
      style={{
         width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
        marginBottom: '10px',
        boxSizing: 'border-box',
        outline: 'none',
      }}
    />

                        </div>

                      </div>
                      <div style={{ 
                        //marginTop: '10px',
                        //backgroundColor: "pink",
                        display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: '55vh',
             //marginBottom: '80px',
            // marginRight: "10px",
            flex: 1,
                        //overflowY: 'auto',
                        //zIndex: "1",
                         }}>
                       
                        {item?.options
                          ?.filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase())) // Filter options based on search query
                          .filter(option => item?.id !== 3 || option?.id !== 30)
                          .map((option, optionIndex) => (
                            <label key={optionIndex} style={{
                              
                              alignItems: "center",
                              display: 'flex',
                              justifyContent: "center",
                              alignSelf: "center",
                              marginRight: '8px',
                              marginLeft: '8px',

                              //width: "100%",
                            }}>
                              <Chip
                                style={{ marginTop: '10px' }}
                                checked={ masterParentIdData!= 0 ? selectedOptions[item?.id]?.option_id === option.id : ""}
                                 onChange={() => setSelectedOptions(prevSelectedOptions => ({
                                   ...prevSelectedOptions,
                                   [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                 }))}
                                size='lg'
                                onClick={() => handleOptionClick(option.id, item?.id,option.parentId,item?.parent)}
                              >
                                  <Center>
                                  <Text
                                    style={{
                                      textAlign: 'center',
                                      textWrap: "wrap",
                                      marginLeft: '0px',
                                      fontSize: '14px',
                                      paddingTop: '18px',
                                      height: '60px',
                                      width: '280px',
                                    }}
                                  >
                                    {option.masterName}
                                  </Text>
                                  </Center>
                                
                                  
                               
                              </Chip>
                            </label>
                          ))}
                      </div>
                      <div style={{marginBottom:"80px"}}>
                    <Text style={{color: "white"}}>.</Text>
                  </div>
                    </Card>
                 
                  {/* <div style={{marginBottom:"120px"}}>
                    <Text style={{color: "white"}}>.</Text>
                  </div> */}
                </div>
              );
            })
          ) : (
            <Center style={{ marginTop: "150px" }}>
              <Loader color="#0180ff" size={30} />
            </Center>
          )}
        </div>

        {/* //////////////////////////////////// */}
        <div style={{
          position: "fixed",
          width: "100%",
          height: "10%",
          maxWidth: "600px",
          bottom: "0px",
          background: "white",
          padding: "0px",
          display: "grid",
          alignItems: "center",
          justifyContent: "'center",
          alignContent: "center",
        }}>
          {isLastPage ? (
            <div>
              <Paper radius="md" ml={'5px'} mr={'5px'} mt={'20px'} mb={'10px'}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                
                  <Button onClick={handleBackPage} w="30%" style={{ marginLeft: "15px", border: "none", marginRight: "0px", cursor: "pointer", background: "#ccc", color: '#000' }}>
                    <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                    <Trans>Back</Trans>
                    </Text>
                  </Button>
                  <Button onClick={handleSubmit} w="100%" style={{ marginLeft: "10px", border: "none", marginRight: "10px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                    <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                    <Trans>Submit</Trans>
                    </Text>
                  </Button>
                </div>
              </Paper>
            </div>
          ) : (
            <div>
              <Paper radius="md" ml={'5px'} mr={'5px'} mt={'20px'} mb={'10px'}>
                <div style={{ display: "flex", flexDirection: "row" }}>
            
                  {currentPage > 0 && (
                    <Button onClick={handleBackPage} w="30%" style={{ marginLeft: "15px", border: "none", marginRight: "0px", cursor: "pointer", background: "#ccc", color: '#000' }}>
                      <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                      <Trans>Back</Trans>
                      </Text>
                    </Button>
                  )}
                  <Button onClick={handleNextPage} w="100%" style={{ marginLeft: "10px", border: "none", marginRight: "10px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                    <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                    <Trans>Next</Trans>
                    </Text>
                  </Button>
                </div>
              </Paper>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OnboardingQuestions;
