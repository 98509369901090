import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import '@mantine/core/styles.css'
import { MantineProvider } from '@mantine/core'
import { ApiProvider } from "@reduxjs/toolkit/query/react"
import { apiSlice } from "./redux/api/apiSlice.js"
import { Notifications } from '@mantine/notifications';
import { Provider } from 'react-redux'
import { AuthContextProvider } from './context/AuthContext.jsx'
import store from './redux/store.js'
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import '@mantine/carousel/styles.css';
import OneSignal from 'react-onesignal'
import { loadCatalog } from '../i18n.js'


  const fetchData = async () => {
    try {
      await loadCatalog("en");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call the async function
  fetchData();



Bugsnag.start({
    apiKey: '740c9f9a61373a43d33a0db1c74b5380',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '740c9f9a61373a43d33a0db1c74b5380' })
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const OneSignalId = import.meta.env.VITE_VERSION_ONE_SIGNAL_ID

OneSignal.init({ appId: OneSignalId });    

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <MantineProvider>
    <Notifications />
      <ApiProvider api={apiSlice}>
      <Provider store={store}>
        <AuthContextProvider>
            <ErrorBoundary>
          <App />
            </ErrorBoundary>
        </AuthContextProvider>
      </Provider>
      </ApiProvider>
    </MantineProvider>

  </BrowserRouter>

)
