import { i18n } from "@lingui/core";

// Import language modules dynamically
const languageModules = {
  en: () => import("/src/locales/en.po"),
  hi: () => import("/src/locales/hi.po"),
  gu: () => import("/src/locales/gu.po"),
  ma: () => import("/src/locales/ma.po"),
  pu: () => import("/src/locales/pu.po"),
  te: () => import("/src/locales/te.po"),
  ta: () => import("/src/locales/ta.po"),
  be: () => import("/src/locales/be.po"),
  od: () => import("/src/locales/od.po"),
  ka: () => import("/src/locales/ka.po"),

  // Add more languages as needed
};

export const locales = {
  en: "English",
  hi: "Hindi",
  gu: "ગુજરાતી",
  ma: "मराठी",
  pu: "ਪੰਜਾਬੀ",
  te: "తెలుగు",
  ta: "தமிழ்",
  be: "বাংলা",
  od: "ଓଡିଆ",
  ka: "ಕನ್ನಡ",
};
export const defaultLocale = "en";

/**
 * Load messages for the requested locale and activate them.
 */
export async function loadCatalog(locale) {
  try {
    // Dynamically import the language module
    //const { messages } = await languageModules[locale]();
    const { messages } = await import(`./src/locales/${locale}.po`);

    // Load and activate the messages
    i18n.loadAndActivate({ locale, messages });
  } catch (error) {
    console.error(
      `Error loading messages for ${locale}. Falling back to default.`,
      error
    );

    // Fallback to default language
    const { messages } = await languageModules[defaultLocale]();

    // Load and activate the fallback messages
    i18n.loadAndActivate({ locale: defaultLocale, messages });
  }
}
