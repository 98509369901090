import React,{useState, useEffect} from 'react'
import classes from '../styles/Shraminshala.module.css'
import {useGetshraminshalavideosQuery} from '../redux/api/apiSlice'
import {useSelector} from 'react-redux'
// import Videocard from '../components/Videocard/Videocard'
import { useDisclosure } from '@mantine/hooks';
import ReactPlayer from 'react-player/lazy'
import { Card, Image, Text, Group, Modal, BackgroundImage } from '@mantine/core';
import logo from '../../public/shraminLogo.png'
import 'lite-youtube-embed';
import 'lite-youtube-embed/src/lite-yt-embed.css'
import { Trans } from '@lingui/macro';


const Shraminshala = () => {
  const [videos, setvideos] = useState([])
  const [opened, { open, close }] = useDisclosure(false);
  const token = useSelector((state) => state?.user.token)
  const [videoid  , setvideoid] = useState();

  const {data: videodata, isLoading, isError, isFetching,refetch} = useGetshraminshalavideosQuery({
        skip:!token
  })

  //console.log("kdkmvkdmkv",videodata)


   useEffect(() => {
    if (token) {
        refetch();
    }
   }, [token, refetch]);


   useEffect(() => {
    if (videodata) {
      setvideos(videodata)
    }
   }, [videodata]);




    //  const modalOpen = (id)=>{
    //       setvideoid(id)
    //       open();
    //  }

    //console.log("zldnvlzv",videos[0]?.videoVirtualPath)
    

        
  
  return (
    <div className={classes.wrapper}>
   
    {videos?.length > 0 && (
                    videos?.map(video=>{
                      const videoId = video?.videoVirtualPath?.substring(32);
                      //console.log("zldnvlzv",videoId)
                      return <>
                      <Card withBorder radius="md" p="md" mx={5} className={classes.card}>
    <Card.Section style={{backgroundColor:"green"}}>
    <lite-youtube
    //videoid="https://www.youtube.com/watch?v=_QWRDcB2LH8"
    videoid={videoId}
    //  style={{
    //     position: 'relative',
    //             display: 'block',
    //             width: '100%',
    //             height: '380px',
    //             overflow: 'hidden',

    //   }}
      >
</lite-youtube>
    
    </Card.Section>

    <Card.Section className={classes.section} mt="sm" p="4">
        <div>
           <img src={logo} height="45px" width="45px"/>
        </div>
        <div>
          <Group justify="apart">
            <Text fz="sm" fw={500} mr={10} >
              {video?.title}
            </Text>
          </Group>
          <Text fz="sm" mt="xs" fw={500}>
            <Trans>Posted Date -</Trans> {video?.createdAt?.split('-').reverse().join('/')}
          </Text>
        </div>
    </Card.Section>
  </Card>
                      </>
               
                    })
                )}
             
            {/* <Modal opened={opened} onClose={close} centered size="auto" >
              <ReactPlayer url={`https://www.youtube.com/watch?v=${videoid}`} controls={true} width="100%" height="300px" />
            </Modal> */}
   
           <div
              style={{marginTop: "80px"}}>
           </div>
    </div>
  )
}

export default Shraminshala