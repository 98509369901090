import { Card, Image, Text, Group, Badge, Center, Button } from '@mantine/core';
import classes from './Coursecard.module.css';
import {useNavigate} from 'react-router-dom'
import { Trans } from '@lingui/macro';


function FeaturesCard(props) {
  const navigate = useNavigate();
  const {
       id ,
       title,
       name,
       duration,
       fee,
       location,
       seats,
       education,
       logo
  } = props
   

 

  const handleRedirect = (cid)=>{
     navigate(`/coursedetail?courseid=${cid}`)     
  }



  return (
    <Card withBorder radius="md" className={classes.card} onClick={()=>{handleRedirect(id)}}>
      <Group mt="md" className={classes.groupContainer}>
          <div>
           
                <Text fw={500} color="#0180ff">
                   {title}
                </Text>
               
                <Text fz="sm" c="dimmed">
                    {name}
                </Text>

              <div style={{marginTop:"15px"}}>

                  <Text size="sm" mb={5} mt={5} fw={600}>
                     <Trans>Course Duration</Trans>  : <span>{duration}</span>
                  </Text>
                  {/* <Text size="sm" mb={5} mt={5} fw={600}>
                     <Trans>Course Fee</Trans>  : <span>{fee}</span>
                  </Text> */}
                  <Text size="sm" mb={5} mt={5} fw={600}>
                      <Trans>Location</Trans>  : <span>{location}</span>
                  </Text>
                  <Text size="sm" mb={5} mt={5} fw={600}>
                      <Trans>No of Seats</Trans>  : <span>{seats}</span>
                  </Text>
                  <Text size="sm" mb={5} mt={5} fw={600}>
                      <Trans>Minimum Education</Trans>  : <span>{education}</span>
                  </Text>
              </div>

          </div>
          <div style={{textAlign:"center"}}>
               {logo &&  <img style={{width:"70px", height:"70px"}} src={logo} alt="job-logo" />}
              
               <div style={{marginTop:"1.5rem",paddingTop:"1rem"}}>
               <button className={classes.viewjobbtn}><Trans>view</Trans></button>
               </div>
          </div>
      </Group>  
    </Card>
  );
}


export default FeaturesCard