import React, { useEffect, useState } from 'react';
import { Modal, Button, Group, Text, Stack } from '@mantine/core';
import { getToken, deleteToken } from "firebase/messaging";
import { fcmToken } from "../redux/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { messaging } from '../configFirebase';
import { useCandidateSignUpMutation } from "../redux/api/apiSlice.js";
import { FaBell, FaRegBell } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Trans } from '@lingui/macro';
import logo from '../../public/shraminLogo.png'


const NotificationPermissionModal = ({shouldopen, modalClose}) => {
    const [candidateSignUp] = useCandidateSignUpMutation()
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();


     

    useEffect(() => {
         if(shouldopen){
            setOpened(true)
         }else{

                if("Notification" in window){
                    const lastDeniedTime = localStorage.getItem("lastNotificationDeniedTime");
            
                    const openModal = () => {
                        if (Notification.permission === "default") {
                            setOpened(true);
                        }
                        if (Notification.permission === "granted") {
                            if ('serviceWorker' in navigator) {
                                navigator.serviceWorker.getRegistration()
                                    .then(function(registration) {
                                        if (registration) {
                                            getToken(messaging, {
                                                vapidKey: import.meta.env.VITE_VAP_ID,
                                                serviceWorkerRegistration: registration
                                            })
                                                .then((currentToken) => {
                                                    if(currentToken) {
                                                        dispatch(fcmToken(currentToken));
                                                        candidateSignUp(currentToken)
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.error("Error in getting FCM token:", error);
                                                });                            } else {
                                            console.log('No Service Worker is currently registered.');
                                        }
                                    })
                                    .catch(function(error) {
                                        console.error('Error while getting Service Worker registration:', error);
                                    });
                            }
            
                        }
            
                    };
            
                    if (lastDeniedTime) {
                        const lastDeniedDate = new Date(lastDeniedTime);
                        const currentDate = new Date();
                        const timeDiff = currentDate - lastDeniedDate;
                        const hoursDiff = timeDiff / (1000 * 60 * 60);
            
                        if (hoursDiff > 24) {
                            setTimeout(openModal, 3000);
                        }
                    } else {
                        setTimeout(openModal, 3000);
                    }
                }
         }
         

    }, [shouldopen]);


 

    const requestPermission = () => {
        
          if("Notification" in window){
              Notification.requestPermission().then(permission => {
                  if (permission === "granted") {
                      if ('serviceWorker' in navigator) {
                          navigator.serviceWorker.getRegistration()
                              .then(function(registration) {
                                  if (registration) {
                                      getToken(messaging, {
                                          vapidKey: import.meta.env.VITE_VAP_ID,
                                          serviceWorkerRegistration: registration
      
                                      })
                                          .then((currentToken) => {
                                              if(currentToken) {      
                                                  dispatch(fcmToken(currentToken));
                                                  candidateSignUp(currentToken)
                                                  setOpened(false);
                                              }
                                          })
                                          .catch((error) => {
                                              setOpened(false);
                                              console.error("Error in getting FCM token:", error);
                                          });                            
                                  } 
                                  else 
                                  {
                                      console.log('No Service Worker is currently registered.');
                                  }
                              })
                              .catch(function(error) {
                                  console.error('Error while getting Service Worker registration:', error);
                              });
                      }
                  } else if (permission === "denied") {
                      // alert("Please enable notifications in your browser settings.");
                      setOpened(false);

                      if(modalClose){
                          modalClose();
                      }
                  }
              });
          }
    };


    const denyPermission = () => {
        localStorage.setItem("lastNotificationDeniedTime", new Date().toString())
        setOpened(false);

        if(modalClose){
            modalClose();
        }
    };

 

    return (
        <Modal
            withCloseButton={false}
            opened={opened}
            centered
            closeOnClickOutside={false}
        >
             <div style={{display:"grid", gridTemplateColumns:"20% 80%", gridGap:"10px"}}>
                <div style={{textAlign:"center"}}>
              <img src={logo} alt="shramin-logo" style={{height:"50px", width:"50px"}} />
                </div>
              <Text align="left" fw={500} size="sm" mb="md">
              रोज़ाना नौकरी के अलर्ट और अप्लाई के बाद अपडेट्स के लिए 'Yes' और 'Allow' बटन क्लिक करें
              </Text>
             </div>

             
            <Stack align="center">
                <Group position="center" mt="md">
                    <Button onClick={denyPermission} variant='transparent'><Trans>No</Trans></Button>
                    <Button onClick={requestPermission}><Trans>Yes</Trans></Button>
                </Group>
            </Stack>

        </Modal>
    );
};

export default NotificationPermissionModal;
