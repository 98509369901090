import {
    Card,
    Text,
    SimpleGrid,
    UnstyledButton,
    useMantineTheme,
    Button,
    Grid
  } from '@mantine/core';

  import searchlogo from '../assets/logos/Search.png'
  import govtjoblogo from '../assets/logos/Home.png'
  import personlogo from '../assets/logos/Person.png'
  import ActivePlayLogo from '../assets/logos/play.png'
  import ActiveCareerLogo from '../assets/logos/career.png'
  import courselogo from '../assets/logos/courses.png'


  import {useNavigate} from 'react-router-dom'
  import classes from '../styles/ActionHomeSection.module.css';
  import { Trans } from '@lingui/macro';
  import { i18n } from '@lingui/core';
  import { I18nProvider } from '@lingui/react';
  import { defaultLocale } from '../../i18n';
  import { loadCatalog } from '../../i18n'
import NotificationPermission from "./NotificationPermission.jsx";

import { IoMdDownload } from "react-icons/io";
import { PiWhatsappLogoLight } from "react-icons/pi";
import CustomModalInApp from '../components/CustomInstallModalInApp'
import { FaTelegram } from "react-icons/fa6";
import { FaShareAlt } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";



  //i18n.activate(loadCatalog);

  const mockdata = [
    { title: <Trans>Jobs</Trans>,  color: 'violet', icon : searchlogo, link:"/jobs?tab=all" },
    { title: <Trans>Govt. Jobs</Trans>, color: 'indigo', icon : govtjoblogo, link:"/jobs?tab=govt" },
    { title: <Trans>Career</Trans>, color: 'blue', icon : ActiveCareerLogo , link:"/shraminshala" },
    { title: <Trans>Courses</Trans> , color: 'green' , icon : courselogo , link:"/courses" },
    // { title: <Trans>Play</Trans> ,  color: 'teal' , icon : ActivePlayLogo , link : "/play" },
    // { title: <Trans>Profile</Trans>,  color: 'cyan' , icon : personlogo , link:"/myprofile" },
  ];

   function ActionsGrid() {
    const theme = useMantineTheme();
    const navigate = useNavigate();


    const handleRedirect = (link)=>{
         navigate(link)
    }

    const sharewhatsappbtn = () => {
      window.open("https://whatsapp.com/channel/0029VaAYCuQEawdmh2V3fH2q")
    }
  
    const sharetelegrambtn = () => {
      window.open("https://t.me/shraminjobs")
    }
  
    const handleRedirectbtn = ()=>{
      navigate(`/chatbot`)     
   }

   const shareallbtn = () => {
    //console.log("abcd") 
    if (navigator.share) {
      navigator.share({
        title: 'ShramIN App',
        text: `SharmIN Jobs App` + `${"\n"}` + `India's best job search app for skilled technicians` + `${"\n"}` ,
        url: 'https://www.shramin.app/'
      }).then(() => {
       // console.log('Thanks for sharing!');
      }).catch(err => {
        //console.log("Error while using Web share API:");
        console.log(err);
      });
    } else {
      alert("Browser doesn't support this API !");
    }
  };


    const items = mockdata.map((item) => (
            <UnstyledButton style={{marginLeft: "3px", marginRight:"3px", boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} key={item.title} className={classes.item} onClick={()=>{handleRedirect(item.link)}}>
              <img src={item.icon} style={{height:"30px", width :"30px"}}/>
              <Text mt={7} color='#0180ff' style={{textAlign:"center", fontSize: "14px"}}>
                {item.title}
              </Text>
            </UnstyledButton>

    ));

    return (
    <div style={{margin: "10px" , backgroundColor: "#fff", borderWidth:"0.5", borderColor:"#D2D2D2" }}>
      <Card padding="lg" style={{ flexDirection: "row",  padding: "10px",}}>
        {/* <Card radius="md" style={{display: "flex",
        margin: "5px", padding: "2px",
backgroundColor: "#fff",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"}}> */}
          {items}
        </Card>

    

        <div style={{borderRadius:"10px"}}>
        <Grid style={{margin:"20px", paddingBottom:"20px"}}>
      <Grid.Col style={{backgroundColor:"white", borderRadius:"20px", boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} span={3}>
      <Button onClick={sharewhatsappbtn} style={{width: '100%', backgroundColor:"white"}}>
               <PiWhatsappLogoLight 
                      style={{fontSize:"30px", color:"green", fontWeight:"300"}}
                      cursor="pointer"
                      onClick={sharewhatsappbtn}
                      />
             </Button>
             <Text onClick={sharewhatsappbtn} style={{textAlign:"center", cursor:"pointer", marginTop:"4px", fontSize:"11px"}}><Trans>Join WhatsApp Channel</Trans></Text>
      </Grid.Col>

      <Grid.Col style={{backgroundColor:"white", borderRadius:"20px", boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} span={3}>
      <Button onClick={sharetelegrambtn} style={{width: '100%', backgroundColor:"white"}}>
                <FaTelegram 
                      style={{fontSize:"27px", color:"#0190ff", fontWeight:"300"}}
                      cursor="pointer"
                      onClick={sharetelegrambtn}
                      />
             </Button>
             <Text onClick={sharetelegrambtn} style={{textAlign:"center", cursor:"pointer", marginTop:"4px", fontSize:"11px"}}><Trans>Join Telegram Channel</Trans></Text>
      </Grid.Col>

      <Grid.Col style={{backgroundColor:"white", borderRadius:"20px", boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} span={3}>
      <Button onClick={handleRedirectbtn} style={{width: '100%', backgroundColor:"white"}}>
                <BsChatDots 
                      style={{fontSize:"28px", color:"#a656de", fontWeight:"300"}}
                      cursor="pointer"
                      onClick={handleRedirectbtn}
                      />
             </Button>    
             <Text onClick={handleRedirectbtn} style={{textAlign:"center", cursor:"pointer", marginTop:"4px", fontSize:"11px"}}><Trans>Career Guidance</Trans></Text>
      </Grid.Col>

      <Grid.Col style={{backgroundColor:"white", borderRadius:"20px", boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} span={3}>
             <Button onClick={shareallbtn} style={{width: '100%', backgroundColor:"white"}}>
              <FaShareAlt 
                      style={{fontSize:"24px", color:"#0190ff", fontWeight:"300"}}
                      cursor="pointer"
                      onClick={shareallbtn}
                      />
            </Button> 
            <Text onClick={shareallbtn} style={{textAlign:"center", cursor:"pointer", marginTop:"4px", fontSize:"11px"}}><Trans>Share with Friends</Trans></Text>
      </Grid.Col>

    </Grid>
            
        </div>
       


       {/* </Card> */}
      </div>
   
    );
  }


  export default ActionsGrid
