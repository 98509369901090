import React from 'react'
import classes from '../styles/Landinggreaternoida.module.css'
import shraminlogo from '../../public/shraminLogo.png'
import techman from '../../public/technician-man.png'
import whatsapplogo from '../../public/whatsapp.png'
import { Button } from '@mantine/core';
import {Link} from 'react-router-dom'


const Landinggreaternoida = () => {
  
  return (
    <div className={classes.wrapper}>
         <div className={classes.fixedwrapper}>
          <h1 className={classes.mainheading}>
            <img src={shraminlogo} height="60px" width="60px" style={{marginRight:"15px"}}/>
            ShramIN Jobs
            </h1>
         </div>
         <div className={classes.contentwrapper}>
          
                <h1 className={classes.text1}>
                 ग्रेटर नोएडा की फैक्ट्रियों में अच्छी नौकरी पाना हुआ आसान !
                </h1>
                <h1 className={classes.text2}>
                    इलेक्ट्रीशियन, वेल्डर, फिटर, मशीन ऑपरेटर के लिए <span style={{color:"yellow", fontSize:"26px"}}>5000+</span> जॉब
                </h1>
                    <a className={classes.wtsapp} href="https://api.whatsapp.com/send?phone=+919210223456&text=Hello">
                     <img src={whatsapplogo} alt="whatsapp-logo" height="25px" width="25px" style={{marginRight:"5px"}} />
                    +91 9210223456
                    </a>

         </div>
         <div className={classes.buttonwrapper}>
              <Link to="/">
                <Button className={classes.registerbtn} radius="xl">Register Now (Free)</Button>
              </Link>
         </div>
         <div className={classes.gridwrapper}>
            <div className={classes.rightgrid}>
             <img src={techman} height="100%" width="70%"/>
            </div>
         </div>
    </div>
  )
}

export default Landinggreaternoida