import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import {getMessaging , isSupported} from "firebase/messaging";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain:import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

// const messaging = isSupported() ?    getMessaging(app) : null

 

  var messaging ; 

  isSupported().then((result) => {
    if(result){
      messaging = getMessaging(app)
    }else{
      messaging = null 
    }
  }).catch((err) => {
    messaging = null 
  });



const provider = new GoogleAuthProvider();


export {auth,provider, messaging };

