import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "userdetails",
    initialState: {
        data: {},
        token: null,
        candidateData: {},
        fcmtoken: null,
        onboardingField: null,
    },

    reducers: {
        userData: (state, action) => {
            state.data = action.payload;
        },
        appToken: (state, action) => {
            state.token = action.payload
        },
        candidateData: (state, action) => {
            state.candidateData = action.payload
        },
        fcmToken: (state, action) => {
            state.fcmtoken = action.payload
        },
        onboardingField: (state, action) => {
            state.onboardingField = action.payload
        },


    }
})


export const { userData, appToken, candidateData, fcmToken, onboardingField } = userSlice.actions;
export default userSlice.reducer
