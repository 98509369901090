import { Trans } from "@lingui/macro";
import { Badge, Flex, Group, Paper, Stack, Text, Card, Center, Loader, Indicator } from "@mantine/core";
import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import NotificationStatusModal from "../components/NotificationStatus.jsx";
import { UserAuth } from "../context/AuthContext";
import { useGetAllNotification } from "../services/Queries";
import classes from "../styles/ChatBot.module.css";

const NotificationScreen = () => {
  // const [value, setValue] = useState<string | null>(null);
  // const [allNotificationData, setAllNotificationData] = useState(null);

  const [opened, setOpened] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [openedShare, setOpenedShare] = useState(false);
  const [loading, setloading] = useState(true)

 

  let notificationOpen = "yes";

  const { user } = UserAuth();
  const candidateId = localStorage.getItem("candidateId");

  const token = user?.accessToken;

  const allNotification = useGetAllNotification(candidateId, token);

  //  if (allNotification.isPending) return <>Loading...</>;

  if (allNotification.isError) return <>Error...</>;

  //details = allNotification.data;

  

  var allNotificationData = allNotification?.data

  //console.log("datakkkkk555", allNotificationData);

  // console.log("llllwwwwwwww1223", "Notification" in window)
  //   console.log("llllwwwwwwww1122", Notification.permission)

  const modalopen = () => {
    setModalOpen(true);
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  //  {("Notification" in window) && (
  //   <>
  //     {Notification.permission === 'default' && (
  //       <AiOutlineAlert
  //       style={{fontSize:"32px", color:"#0180ff", position:"relative", bottom:"5px", fontWeight:"300"}}
  //       cursor="pointer"
  //       onClick={modalopen}
  //       />
  //     )}
  //   </>
  // )}

  // const { isPending, error, data } = useQuery({
  //   queryKey: ['repoData'],
  //   queryFn: () =>
  //     fetch('https://shramin-dev.azurewebsites.net/notifications/candidate/9c5588be-2de3-4084-bcc7-57f4e34a40ed?page=0&size=10').then((res) =>
  //       res.json(),
  //     ),
  // })

  // const { isPending, error, data } = useQuery({
  //   queryKey: ['repoData'],
  //   queryFn: () =>
  //     fetch('https://shramin-dev.azurewebsites.net/notifications/candidate/9c5588be-2de3-4084-bcc7-57f4e34a40ed?page=0&size=10', {
  //       headers: {
  //          'Content-Type': 'application/json',
  //          "Authorization": `Bearer ${token}`,
  //          "Accept": 'application/json'
  //       },
  //     }).then((res) => {
  //       if (!res.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return res.json();
  //     }),
  // });

  // if (isPending) return 'Loading...'

  // if (error) return 'An error has occurred: ' + error.message

  // //console.log("datakkkkk", data)

  // console.log("datakkkkk", data)

  ///////////////

  // const { isPending, error, data } = useQuery({
  //   queryKey: ['repoData'],
  //   queryFn: () => {
  //     // Check if the accessToken is available
  //     if (!user?.accessToken) {
  //       // You can either throw an error or return a default value
  //       throw new Error('Access token is not available');
  //     }

  //     return fetch('https://shramin-dev.azurewebsites.net/notifications/candidate/9c5588be-2de3-4084-bcc7-57f4e34a40ed?page=0&size=10', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${user.accessToken}`, // No need to use optional chaining here since we have already checked
  //         'Accept': 'application/json'
  //       },
  //     }).then((res) => {
  //       if (!res.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return res.json();
  //     });
  //   },
  // });

  // // Handle different states
  // if (isPending) return 'Loading...';

  // if (error) return 'An error has occurred: ' + error.message;

  // console.log("Data:", data);

  const denyPermission = () => {
    // console.log("mmmmmmmmmmmmmmmmmwwwwwwwwwwyyyyyyyyy")
    localStorage.setItem("lastNotificationDeniedTime", new Date().toString());
    setOpened(false);

    // if(modalClose){
    //     modalClose();
    // }
  };

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate("/");
  };

  /////////////////Post////////////////////////

  const fetchMyNotificationDetailData = async (item) => {

    const url = import.meta.env.VITE_BASE_URL + '/notifications/candidate/';

    //const url = `https://shramin-stag.azurewebsites.net/notifications/candidate/`; 

    try {

      const obj = {};
      obj.id = item?.id
      obj.notificationsId = item?.notificationsId
      obj.readStatus = true
      obj.candidateId = item?.candidateId

      const response = await fetch(`${url}${item?.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in notificationDetailData');
      }
      const notificationDetaildata = await response.json();
      localStorage.removeItem("notificationCount")
      // if(notificationDetaildata?.message === "success"){
      //   fetchGetMyNotificationDetailData()
      // }

    } catch (error) {
      console.error('Error fetching notificationDetail :', error);
    }
  };

  ///////////////////////////////////////////

  const notificationRedirectDetail = (item) => {

    window.location.href = item?.actionUrl;

    fetchMyNotificationDetailData(item)
  };

  return (
    <div>
      {modalOpen && (
        <NotificationStatusModal notificationOpen={notificationOpen} />
      )}

      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "8%",
          // borderRadius: "30px",
          maxWidth: "600px",
          top: "55px",
          // background: "white",
          padding: "0px",
          display: "grid",
          alignItems: "center",
          justifyContent: "'center",
          alignContent: "center",
          backgroundColor: "white",
          zIndex: 1
        }}
      >
        <div>
          <IoArrowBack
            onClick={arrowBack}
            color="black"
            size={26}
            style={{ marginLeft: "10px", marginTop: "5px" }}
          />
        </div>
        <div
          style={{
            marginTop: "-40px",
            marginLeft: "50px",
            marginRight: "50px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            <Trans>Notification</Trans>
            
          </Text>
        </div>
      </div>

      <div style={{ marginTop: "108px" }}>
        <div style={{ paddingTop: "15px" }}>
        {("Notification" in window) && (
                  <>
                    {(Notification.permission === 'default') &&
                      <>
                     {opened && (
                       <Paper
                         withBorder
                         radius="sm"
                         style={{
                           marginRight: "10px",
                           marginLeft: "10px",
                           textAlign: "center",
                           backgroundColor: "white",
                         }}
                       >
                         <Flex direction="column">
                           <span style={{ fontWeight: "bold" }}>
                             <Text fw={500} size="sm" pt={10} mb="md">
                               रोज़ाना नौकरी के अलर्ट और अप्लाई के बाद अपडेट्स के लिए
                               'Yes' बटन क्लिक करें
                             </Text>
                           </span>
          
                           <Stack align="center">
                             <Group position="center" mb="sm">
                               <Badge
                                 onClick={denyPermission}
                                 color="red"
                                 w={80}
                                 size="lg"
                                 radius="sm"
                                 p="sm"
                               >
                                 <span
                                   style={{ textTransform: "none", fontWeight: 600 }}
                                 >
                                   <Trans>No</Trans>
                                 </span>
                               </Badge>
                               <Badge
                                 onClick={modalopen}
                                 color="green"
                                 w={80}
                                 size="lg"
                                 radius="sm"
                                 p="sm"
                               >
                                 <span
                                   style={{ textTransform: "none", fontWeight: 600 }}
                                 >
                                   <Trans>Yes</Trans>
                                 </span>
                               </Badge>
                             </Group>
                           </Stack>
                         </Flex>
                       </Paper>
                     )}
                   </>
                    }
                  </>
                )}
       

       {(allNotificationData?.length == 0) ? (
           <>
           <Card style={{marginTop: "50px", paddingTop: "150px", paddingBottom: "150px"}}>
           <Center>
           <Text>
           <Trans>Data not available at this time</Trans>
           </Text>
           </Center>
           </Card>
           
           </>
        ) : (

          <>
          {(allNotificationData?.length > 0) ? (
 <Paper
 style={{
   marginTop: "10px",
   marginLeft: "10px",
   marginRight: "10px",
   backgroundColor: "white",
 }}
>

 <>
  {allNotificationData?.sort((a, b) => {
        const dateA = new Date(a?.created_at);
        const dateB = new Date(b?.created_at);
        
        // Sort in descending order
        return dateB - dateA;
      })
  ?.map((item) => {
   let notificationDate = item?.created_at;
   if (item !== undefined || item !== null) {
     notificationDate = notificationDate
       ?.slice(0, 10)
       .split("-")
       .reverse()
       .join("-");
   }
   return (
     <div>
       {item.readStatus ? (
        <Paper radius="md" className={classes.card} onClick={()=>{notificationRedirectDetail(item)}} style={{boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", padding: "20px", marginBottom:"8px", marginLeft:"4px", marginTop:"2px"}}>
        <div>
          <Flex>
            <Flex align="start" gap="sm">
            
            <Flex direction="column">
            <div style={{fontWeight:700, fontSize:"14px"}}>{item?.title}</div>
            </Flex>
            </Flex>
          </Flex>
          
          <Flex direction="column" style={{marginTop:"12px",gap:"2px"}}>
            <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}>{item?.message}</Flex>
          </Flex>

          <Flex gap="md" style={{marginTop:"12px"}}>
          <Badge variant="light" style={{backgroundColor:"#d5fbce"}} color="black" size="sm" radius="sm">{notificationDate}</Badge>
          </Flex>
         
          </div>
        </Paper>
       ) : (
        // <Indicator onClick={()=>{notificationRedirectDetail(item)}} zIndex={"0"} inline label="New" size={20} offset={10}>
        <Paper radius="md" className={classes.card} onClick={()=>{notificationRedirectDetail(item)}} style={{boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", backgroundColor:"#edfaff", padding: "20px", marginBottom:"8px", marginLeft:"4px", marginTop:"2px"}}>
        <div>
          <Flex>
            <Flex align="start" gap="sm">
            
            <Flex direction="column">
            <div style={{fontWeight:700, fontSize:"14px"}}>{item?.title}</div>
            </Flex>
            </Flex>
          </Flex>
          
          <Flex direction="column" style={{marginTop:"12px",gap:"2px"}}>
            <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}>{item?.message}</Flex>
          </Flex>

          <Flex gap="md" style={{marginTop:"12px"}}>
          <Badge variant="light" style={{backgroundColor:"#d5fbce"}} color="black" size="sm" radius="sm">{notificationDate}</Badge>
          </Flex>
         
          </div>
        </Paper>
        // </Indicator>
       )}
     </div>
   );
 })}

   </>


</Paper>
          ) : (
            <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Loader style={{position:"relative", bottom:"100px"}}/>
              </div>   

          )}
          </>

         

         

        )}

          <div style={{ marginTop: "180px" }}>
            <Text style={{ color: "white" }}>.</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationScreen;
