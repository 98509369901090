import React, {useState, useEffect} from 'react'
import styles from '../styles/JobPage.module.css'
import AllJobsSection from '../components/Alljobsection'
import Privatejobsection from '../components/Privatejobsection'
import Walkinjobsection from '../components/Walkinjobsection'
import Govtjobsection from '../components/Govtjobsection'
import { Trans } from '@lingui/macro'

import { useLocation } from 'react-router-dom';
import NotificationPermission from "../components/NotificationPermission.jsx";



const Jobs = () => {
    const [activeButton, setActiveButton] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const tab = queryParams.get('tab');

    useEffect(() => {
       if(tab){
         if(tab == 'govt'){
            setActiveButton(3)
          }
          else if(tab=='all'){
            setActiveButton(1)
          }
          else if(tab=='walkin'){
            setActiveButton(2)
          }
          else
          {
            setActiveButton(4)
          }
       }else{
         setActiveButton(4)
       }
    }, [tab])



   const handleClick = (id)=>{
       setActiveButton(id)
   }


  return (
    <div className={styles.jobpagecontainer}>
        <NotificationPermission/>
       <div className={styles.jobpagebuttoncontainer}>
            <button  onClick={() => handleClick(1)} className={`${styles.roundedbutton} ${activeButton == 1 ? styles.activeTabPrivate : styles.privatejobTab}`}><Trans>Private Jobs</Trans></button>
            <button  onClick={() => handleClick(2)} className={`${styles.roundedbutton} ${activeButton == 2 ?styles.activeTabWalk :styles.walkinjobTab}`}><Trans>Walk-In Jobs</Trans></button>
            <button  onClick={() => handleClick(3)} className={`${styles.roundedbutton} ${activeButton == 3 ? styles.activeTabGovt :styles.govtjobTab}`}><Trans>Govt. Jobs</Trans></button>
            <button  onClick={() => handleClick(4)} className={`${styles.roundedbutton} ${activeButton == 4 ? styles.activeTab :styles.alljobTab}`}><Trans>All Jobs</Trans></button>
       </div>

       <div className={styles.jobpagealljobscontainer}>
          {activeButton == 1 && <Privatejobsection/>}
          {activeButton == 2 && <Walkinjobsection/>}
          {activeButton == 3 && <Govtjobsection/>}
          {activeButton == 4 && <AllJobsSection/>}
       </div>

      

    </div>
  )
}

export default Jobs
