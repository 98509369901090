import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ProtectedRoute = () => {

  const navigate = useNavigate()
 // const { user } = UserAuth();
   const userId = localStorage.getItem("userId");


  
  if (!userId) {
    return <Navigate to='/' replace />;
  }

  return <Outlet />;
  // return children;
};

export default ProtectedRoute;