import { Card, Image, Text, Group, Badge, Center, Button } from '@mantine/core';
import classes from '../styles/JobsCard.module.css';
import { IoHammerOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaPerson } from "react-icons/fa6";
import { FaMoneyBills } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro';
import { FaBookOpenReader } from "react-icons/fa6";


function FeaturesCard(props) {
  const navigate = useNavigate();

  const {
    title,
    company,
    trade,
    location,
    state,
    education,
    experience,
    salary,
    logo,
    id,
    jobTypeId
  } = props



  const handleRedirect = (jid) => {
    navigate(`/jobdescription?jid=${jid}`)
  }



  return (
    <Card key={id} withBorder radius="md" className={classes.card} onClick={() => { handleRedirect(id) }}>
      <Group mt="md" 
      //className={classes.groupContainer}
      >
        <div style={{flex: "1px",flexDirection: "row"}}>
          {jobTypeId == null && (
            <>
              <Text fw={500} color="#1F7A8C">
                {title}
              </Text>

            </>
          )}

          {jobTypeId == 119 && (
            <>
              <Text fw={500} color="#38A78A">
                {title}
              </Text>

            </>
          )}

          {jobTypeId == 9732 && (
            <>
              <Text fw={500} color="#034078">
                {title}
              </Text>

            </>
          )}

          <Text fz="sm" c="dimmed">
            {company}
          </Text>

          <div style={{ marginTop: "15px" }}>
          {logo ? (<Text style={{ width: "110%"}} size="sm" mb={5} mt={5} fw={600}>
              <IoHammerOutline style={{ color: "gray", marginRight: "5px" }} /> <Trans>Trade/Job Category</Trans>  : <span style={{whiteSpace: "pre-wrap",display: "unset"}}>{trade}</span>
            </Text>) : (
              <Text style={{ width: "140%"}} size="sm" mb={5} mt={5} fw={600}>
              <IoHammerOutline style={{ color: "gray", marginRight: "5px" }} /> <Trans>Trade/Job Category</Trans>  : <span style={{whiteSpace: "pre-wrap",display: "unset"}}>{trade}</span>
            </Text>
            )}
            <Text style={{ width: "140%"}} size="sm" mb={5} mt={5} fw={600}>
              <FaLocationDot style={{ color: "gray", marginRight: "5px" }} /> <Trans>Location</Trans>  :  <span style={{whiteSpace: "pre-wrap",display: "unset"}}>{location} {(state) ? `(${state})` : ""}</span>
            </Text>
            <Text size="sm" mb={5} mt={5} fw={600}>
              <FaBookOpenReader style={{color:"gray", marginRight:"5px"}}/> <Trans>Education</Trans> : <span>{education}</span>
            </Text>
            <Text size="sm" mb={5} mt={5} fw={600}>
              <FaPerson style={{ color: "gray", marginRight: "5px" }} /> <Trans>Experience</Trans> : <span>{experience}</span>
            </Text>
            <Text size="sm" mb={5} mt={5} fw={600} >
              <FaMoneyBills style={{ color: "gray", marginRight: "5px" }} /> <Trans>Salary</Trans> : <span>{salary}</span>
            </Text>
          </div>

        </div>
        <div style={{ textAlign: "center" }}>
        {logo ? ( <div> <img style={{ width: "70px", height: "70px" }} src={logo} alt="job-logo" />
          {jobTypeId == null && (
            <div style={{ marginTop:"3rem", paddingTop:"0rem" }}>
              <button className={classes.viewjobbtnPrivate}><Trans>view</Trans></button>
            </div>
          )}
          {jobTypeId == 119 && (
            <div style={{ marginTop:"3rem", paddingTop:"0rem" }}>
              <button className={classes.viewjobbtnGovt}><Trans>view</Trans></button>
            </div>
          )}
          {jobTypeId == 9732 && (
            <div style={{ marginTop:"3rem", paddingTop:"0rem" }}>
              <button className={classes.viewjobbtnWalk}><Trans>view</Trans></button>
            </div>
          )}
          </div>) : (
            <div>
            {jobTypeId == null && (
            <div style={{ marginTop:"8rem", paddingTop:"0rem" }}>
              <button className={classes.viewjobbtnPrivate}><Trans>view</Trans></button>
            </div>
          )}
          {jobTypeId == 119 && (
            <div style={{ marginTop:"8rem", paddingTop:"0rem" }}>
              <button className={classes.viewjobbtnGovt}><Trans>view</Trans></button>
            </div>
          )}
          {jobTypeId == 9732 && (
            <div style={{ marginTop:"8rem", paddingTop:"0rem" }}>
              <button className={classes.viewjobbtnWalk}><Trans>view</Trans></button>
            </div>
          )}
          </div>
          )}
        </div>
      </Group>
    </Card>
  );
}


export default FeaturesCard