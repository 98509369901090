import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import { Modal, LoadingOverlay, Notification } from '@mantine/core';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styles from '../styles/MyProfile.module.css'
import { useDisclosure } from '@mantine/hooks';




const WebcamPlayVideo = ({ handleClick }) => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [uploading, setUploading] = useState(false);
    const token = useSelector((state) => state?.user.token)
    const candidateId = localStorage.getItem('candidateId')
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)

    // const [uploadPlayVideo] = useUploadPlayVideoMutation()
    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);

    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: 'video/webm',
        });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(({ data }) => {
        if (data?.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
        }
    }, [setRecordedChunks]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);


    const handleUpload = useCallback(async () => {

        const url = import.meta.env.VITE_BASE_URL + '/candidate';

        openLoading()
        if (recordedChunks.length) {
            try {
                setUploading(true);

                const formData = new FormData();
                const blob = new Blob(recordedChunks, { type: 'video/webm' });
                formData.append('video', blob, 'react-webcam-stream-capture.webm');
                //console.log(formData, candidateId,"formDatacandidateId")

                const response = await fetch(`${url}/${candidateId}/multiple-video`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    //setShowAlertSuccess(true)
                    closeLoading()
                    console.log(handleClick(false))
                   // setFile(null)
                    console.log('Video uploaded successfully');
                }


                // try {
                //     uploadPlayVideo(formData, candidateId).then((res) => {
                //         closeLoading()
                //         setFile(null)
                //     }).catch((err) => {
                //         console.log('ttttttt')
                //     })
                //     console.log(handleClick(false))
                // } catch (error) {
                //     console.error('Error uploading video:', error);
                //     alert('An error occurred. Please try again.');
                // }

                // else {
                //     console.error('Failed to upload video');
                // }
            } catch (error) {
                console.error('Error uploading video:', error);
            } finally {
                setUploading(false);
                setRecordedChunks([]);
            }
        }
    }, [recordedChunks]);

    return (
        <>

          {showAlertSuccess && (
                <Notification
                  color='#eee'
                  onClose={() => setShowAlertSuccess(false)}
                  style={{
                    backgroundColor: "#2aa63d",
                    color: "#eee",
                    position: "fixed",
                    width: "100%",
                    maxWidth: "600px",
                    zIndex: 9999,
                    top: 60
                  }}
                >
                  <span style={{ color: "#eee", fontSize: "1rem" }}>
                  <Trans>Video uploaded successfully</Trans>
                  </span>
                </Notification>
              )}

            <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            <div style={{ display: "flex", justifyContent: 'center' }}>
                <Webcam muted={true} audio={true} ref={webcamRef} className={styles.webcam} />
            </div>
            <div>
                {capturing ? (
                    <button className={styles.upload_video_btn11} onClick={handleStopCaptureClick}><Trans>Stop Capture</Trans></button>
                ) : (
                    <button className={styles.upload_video_btn11} onClick={handleStartCaptureClick}><Trans>Start Capture</Trans></button>
                )}
                {recordedChunks.length > 0 && !uploading && (
                    <button style={{ marginLeft: "10px" }} onClick={handleUpload} className={styles.upload_video_btn11}><Trans>Upload</Trans></button>
                )}
                {uploading && <p><Trans>Uploading...</Trans></p>}
            </div>
        </>
    );
};

export default WebcamPlayVideo;
