import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mantine/hooks";
import {
  Stepper,
  Button,
  Group,
  Card,
  Select,
  Flex,
  Slider,
  Text,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { SiBookmeter } from "react-icons/si";

import {
  useGetStateDetailsQuery,
  useGetProfileDetailsQuery,
  useGetActiveTradeProfileQuery
} from "../../redux/api/apiSlice";
import { UserAuth } from "../../context/AuthContext";
import { Trans, t } from "@lingui/macro";

const Steppercomponent = ({ isSmallScreen, isSmallestScreen }) => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.user?.token);
  const [active, setActive] = useState(0);
  const [trades, setTrades] = useState([]);
  const [btncontent, setbtncontent] = useState("Next Step");
  const [prevcontent, setprevcontent] = useState("Back");
  var userId = localStorage.getItem("userId");


  const { data: activeTradeData, isLoading: activeTradeLoading, isError: activeTradeError, isFetching: activeTradeFetching, refetch: activeTradeRefetch } = useGetActiveTradeProfileQuery({
        skip: !token
    })

  const { data: userData, refetch: refetchuser } = useGetProfileDetailsQuery(
    userId,
    {
      skip: !token,
    }
  );

  const { data: locationAllData, refetch: refetchlocation } =
    useGetStateDetailsQuery({
      skip: !token,
    });

  const [errors, setErrors] = useState({});

  // setting all the values to null
  const [values, setvalues] = useState({
    category: null,
    education: null,
    experience: 0,
    location: null,
  });

  useEffect(() => {
    if (token) {
      activeTradeRefetch()
      refetchlocation();
      refetchuser();
      if(activeTradeData){
        setTrades(activeTradeData);
      }
      
    }
  }, [token, activeTradeRefetch, refetchlocation, refetchuser]);

  // useEffect(() => {
  //   async function fetchtrade() {
  //     try {
  //       const response = await fetch(
  //         `https://shramin-stag.azurewebsites.net/master?masterTypeId=1&sort=true&active=true`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${user?.accessToken}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       );

  //       const data = await response?.json();

  //       setTrades(data);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  //   fetchtrade();
  // }, [user]);

  const getdatatodefault = () => {
    const categoryMasterName = userData?.trade?.masterName;
    const isCategoryPresent = trades?.some(
      (item) => item?.masterName === categoryMasterName
    );

    setvalues({
      category: isCategoryPresent ? categoryMasterName : null,
      education: null,
      experience: expmap[userData?.experience?.masterName] || 0,
      location: userData?.selectedLocation?.masterName || null,
    });
  };

  useEffect(() => {
    if (userData) {
      getdatatodefault();
    }
  }, [userData, trades]);

  useEffect(() => {
    const changebtncont = () => {
      if (active >= 3) {
        setbtncontent("Calculate Salary");
        setprevcontent("Reset");
      } else {
        setbtncontent("Next Step");
        setprevcontent("Back");
      }
    };
    changebtncont();
  }, [active]);

  const expmap = {
    Fresher: 0,
    "0-2 Yrs": 1,
    "3-5 yrs": 2,
    "5-10 yrs": 3,
    "10+ yrs": 4,
  };

  const exparr = [
    "Fresher",
    "1-2 years",
    "2-5 years",
    "5-10 years",
    "10+ years",
  ];

  const exp = [
    { value: 0, label: "Fresher" },
    { value: 1, label: "1-2 Years" },
    { value: 2, label: "2-5 Years" },
    { value: 3, label: "5-10 Years" },
    { value: 4, label: "10+ Years" },
  ];

  const handlevalues = (field, value) => {
    setvalues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value ? null : "This field is required",
    }));
  };

  const validateStep = () => {
    const newErrors = {};
    if (active == 0) {
      if (values.category === null)
        newErrors.category = "This field is required";
      if (values.jobtype === null) newErrors.jobtype = "This field is required";
    } else if (active == 1) {
      if (values.education === null)
        newErrors.education = "This field is required";
    } else {
      if (values.location === null)
        newErrors.location = "This field is required";
    }
    return newErrors;
  };

  const nextStep = () => {
    const currentErrors = validateStep();
    if (Object.keys(currentErrors).length === 0) {
      if (active == 3) {
        navigate(
          `/predict?job=${encodeURIComponent(
            values.category
          )}&loc=${encodeURIComponent(values.location)}&ed=${
            values.education
          }&exp=${values.experience}`
        );
      }
      setActive((current) => (current < 3 ? current + 1 : current));
    } else {
      setErrors(currentErrors);
    }
  };

  const prevStep = () => {
    if (active == 0) {
      navigate("/myprofile");
      return;
    } else if (active == 3) {
      setActive(0);
      getdatatodefault();
      return;
    }

    setActive((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <>
      <Stepper
        active={active}
        onStepClick={setActive}
        allowNextStepsSelect={false}
        iconSize={isSmallScreen ? (isSmallestScreen ? 24 : 28) : 32}
        size={isSmallScreen ? (isSmallestScreen ? 8 : 10) : 14}
        color="teal"
      >
        {/* Job details */}
        <Stepper.Step label="First step" description="Job Details">
          <Card
            padding="xl"
            radius="md"
            shadow="sm"
            m="lg"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              <Trans>Job Role</Trans>
            </span>

            <Select
              label={<Trans>Select Job Role</Trans>}
              placeholder={t`Select Job Role`}
              checkIconPosition="right"
              value={values.category}
              style={{ marginTop: "16px" }}
              styles={{
                label: { paddingBottom: "10px", paddingLeft: "4px" },
              }}
              error={errors.category}
              onChange={(event) => handlevalues("category", event)}
              data={
                [
                  ...new Set(activeTradeData?.map((item) => item?.masterName)),
                ] || []
              }
              // data={trades?.map((item) => ({
              //                   value: item?.id.toString(),
              //                   label: item?.masterName
              //               }))}
              // data={trades?.map((item) => item?.masterName) || []}
              searchable
            />
          </Card>
        </Stepper.Step>

        {/* Qualification */}
        <Stepper.Step label="Second step" description="Qualifications">
          <Card
            padding="xl"
            radius="md"
            shadow="sm"
            m="lg"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              <Trans>Qualification</Trans>
            </span>

            <Select
              label={<Trans>Select Qualification</Trans>}
              placeholder={t`Select Qualification`}
              checkIconPosition="right"
              value={values.education}
              style={{ marginTop: "16px" }}
              styles={{
                label: { paddingBottom: "10px", paddingLeft: "4px" },
              }}
              error={errors.education}
              onChange={(event) => handlevalues("education", event)}
              data={[
                "8th Class or Below",
                "10th Pass",
                "Diploma",
                "ITI",
                "12th pass",
                "Graduate",
              ]}
              searchable
            />

            <Text
              mt="xl"
              style={{
                fontWeight: "500",
                fontSize: "14px",
                paddingBottom: "10px",
                paddingLeft: "4px",
              }}
            >
              <Trans>Experience</Trans>
            </Text>
            <Slider
              step={1}
              marks={exp}
              mb="8px"
              color="gray"
              max={4}
              value={values.experience}
              label={(val) => exp.find((exp) => exp.value === val).label}
              styles={{
                markLabel: { fontSize: "10px" },
              }}
              onChange={(event) => handlevalues("experience", event)}
            />
          </Card>
        </Stepper.Step>

        {/* location */}

        <Stepper.Step label="Final step" description="Location">
          <Card
            padding="xl"
            radius="md"
            shadow="sm"
            m="lg"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              <Trans>State</Trans>
            </span>
            <Select
              label={<Trans>Select State</Trans>}
              placeholder={t`Select State`}
              checkIconPosition="right"
              style={{ marginTop: "16px" }}
              styles={{
                label: { paddingBottom: "10px", paddingLeft: "4px" },
              }}
              value={values.location}
              error={errors.location}
              data={
                [
                  ...new Set(locationAllData?.map((item) => item?.masterName)),
                ] || []
              }
              onChange={(event) => handlevalues("location", event)}
              searchable
            />
          </Card>
        </Stepper.Step>
        <Stepper.Completed>
          <Card
            padding="xl"
            radius="md"
            shadow="sm"
            m="lg"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            <Flex justify="center" style={{ paddingBottom: "8px" }}>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  paddingBottom: "8px",
                }}
              >
                <Trans>Details Provided</Trans>
              </span>
            </Flex>
            <Flex direction="column" gap="sm">
              <div>
                <span style={{ fontWeight: 600 }}>
                  <Trans>Job Role</Trans> :
                </span>{" "}
                {values.category}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>
                  <Trans>Education</Trans> :
                </span>{" "}
                {values.education}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>
                  <Trans>Experience</Trans> :
                </span>{" "}
                {exparr[values.experience]}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>
                  <Trans>State</Trans> :
                </span>{" "}
                {values.location}
              </div>
            </Flex>
          </Card>
        </Stepper.Completed>
      </Stepper>
      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          {prevcontent}
        </Button>
        <Button onClick={nextStep}>{btncontent}</Button>
      </Group>
    </>
  );
};

const SalaryPredictor = () => {
  const isSmallScreen = useMediaQuery("(max-width: 500px)");
  const isSmallestScreen = useMediaQuery("(max-width: 375px");

  return (
    <div style={{ padding: "20px" }}>
      <Flex
        justify="center"
        style={{
          marginBottom: "24px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: isSmallScreen ? "20px" : "24px",
          }}
        >
          <SiBookmeter style={{ marginRight: "8px" }} />{" "}
          <Trans>Salary Meter</Trans>
        </span>
      </Flex>
      <Steppercomponent
        isSmallScreen={isSmallScreen}
        isSmallestScreen={isSmallestScreen}
      />
    </div>
  );
};

export default SalaryPredictor;
