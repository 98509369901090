import React, { useEffect, useState } from 'react'
import { Text, Center, Avatar, Button, Notification, Loader, Textarea, Card, Group, TypographyStylesProvider, Paper } from '@mantine/core';
import classes from '../styles/ChatBot.module.css'
import ShramINlogo from '../assets/logos/shraminLogo.png'
import { LuSendHorizonal } from "react-icons/lu";
import { IoArrowBack } from "react-icons/io5";
import { UserAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useWindowScroll } from '@mantine/hooks';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';



function Chatbot() {

  const { user } = UserAuth()
  const candidateId = localStorage.getItem('candidateId')
  const [value, setValue] = useState(null);
  const [chatBotSubmitted, setChatBotSubmitted] = useState(true);
  const [askQuestionAnaswerChatBot, setAskQuestionAnaswerChatBot] = useState(false);
  const [chatBotAfterSubmitted, setChatBotAfterSubmitted] = useState(false);
  const [myChatbotQuestion, setMyChatbotQuestion] = useState(null)
  const [myChatbotQuestionAnswer, setMyChatbotQuestionAnswer] = useState(null)
  const [myChatbotAllQuestion, setMyChatbotAllQuestion] = useState(null)
  const [showAlertSubmit, setShowAlertSubmit] = useState(false)
  const [candidateChatBotIdData, setCandidateChatBotIdData] = useState(null)
  const navigate = useNavigate();
  const [scroll, scrollTo] = useWindowScroll();

  const [showAlerttoLong, setShowAlerttoLong] = useState(false)

  const { i18n } = useLingui();


  useEffect(() => {
    scrollTo({ y: 0 })
    if (user) {
      fetchMyChatbotAllQuestionData()
      // console.log(candidateChatBotIdData,"hhhhhhhhhhhhhhhh4")
      // fetchMyChatbotSingleQuestionAswerData(chatbotQuestiondata?.candidateChatBotId)
      if (candidateChatBotIdData) {
        //  console.log(candidateChatBotIdData,"candidateChatBotIdDatapppppp")
        fetchMyChatbotSingleQuestionAswerData(candidateChatBotIdData)
      }
    }
  }, [user, chatBotAfterSubmitted, candidateChatBotIdData])

  // const candidateChatBotIdData = myChatbotQuestion?.map((item) => (item))

  // //aarrrrrrrssss", candidateChatBotIdData)



  const submitChatBotAns = () => {
    // setChatBotSubmitted(true)
    // fetchMyChatbotAllQuestionData()
    if (value != null) {
      setAskQuestionAnaswerChatBot(true)
      fetchMyChatbotQuestionData()
    } else {
      setShowAlertSubmit(true)
      // //Please write a question")
    }
  }

  const askQuestionAnaswerChatBotQues = () => {
    fetchMyChatbotAllQuestionData()
    setChatBotSubmitted(true)
    // setAskQuestionAnaswerChatBot(false)
    // setValue(null)
  }

  const addChatBotQues = () => {
    // candidateChatBotIdData(null)
    setChatBotSubmitted(false)
    setMyChatbotQuestionAnswer(null)
    setAskQuestionAnaswerChatBot(false)
  }

  const arrowBack = () => {
    ////rrrrrrrtttttt-----")
    fetchMyChatbotAllQuestionData()
    setChatBotSubmitted(true)
  }

  const arrowBackHome = () => {
    ////rrrrrrrtttttt11-----")
    navigate(`/`)
  }

  

  const handleRedirect = (item) => {

    // console.log(item,"tttttttyyuuuuuu")
    navigate(`/chatbotsinglequestionanswer?item=${item}`)
  }



  const fetchMyChatbotQuestionData = async () => {
    const url = import.meta.env.VITE_BASE_URL + '/chatbot/candidate/';
    try {

      const obj = {};
      obj.message = value


      const response = await fetch(`${url}${candidateId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotQuestionData');
      }
      const chatbotQuestiondata = await response.json();
      if (chatbotQuestiondata?.message == 0) {
        setShowAlerttoLong(true)
        setAskQuestionAnaswerChatBot(false)
      }
      // setChatBotSubmitted(true)
      setValue(null)
      setShowAlertSubmit(false)
      setChatBotAfterSubmitted(true)
      //console.log("ChatbotQuestion---", chatbotQuestiondata)
      setMyChatbotQuestion(chatbotQuestiondata)
      setCandidateChatBotIdData(chatbotQuestiondata?.candidateChatBotId)
      fetchMyChatbotAllQuestionData()

    } catch (error) {
      console.error('Error fetching ChatbotQuestion :', error);
    }
  };

  const fetchMyChatbotAllQuestionData = async () => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/chatbot';
    try {

      const response = await fetch(`${url}?candidateId=${candidateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotAllQuestionData');
      }
      const chatbotAllQuestiondata = await response.json();
      ////ChatbotAllQuestionmypvideo+++", chatbotAllQuestiondata)
      setMyChatbotAllQuestion(chatbotAllQuestiondata)

    } catch (error) {
      console.error('Error fetching ChatbotAllQuestionData :', error);
    }
  };

  const fetchMyChatbotSingleQuestionAswerData = async () => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/chatbot/';
    try {

      const response = await fetch(`${url}${candidateChatBotIdData}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotSingleQuestionAswerData');
      }
      const chatbotSingleQuestionAswerdata = await response.json();
      //ChatbotSingleQuestionAswermypvideo***", chatbotSingleQuestionAswerdata)
      setMyChatbotQuestionAnswer(chatbotSingleQuestionAswerdata)

    } catch (error) {
      console.error('Error fetching ChatbotSingleQuestionAswerData :', error);
    }
  };





  return (
    <div>

      {showAlertSubmit && (
        <Notification
          color='#eee'
          onClose={() => setShowAlertSubmit(false)}
          style={{
            backgroundColor: "#2aa63d",
            color: "#eee",
            position: "fixed",
            width: "100%",
            maxWidth: "600px",
            zIndex: 9999,
            top: 60
          }}
        >
          <span style={{ color: "#eee", fontSize: "1rem" }}>
            <Trans>Please write a question</Trans>
          </span>
        </Notification>
      )}


      {showAlerttoLong && (
        <Notification
          color='#eee'
          onClose={() => setShowAlerttoLong(false)}
          style={{
            backgroundColor: "#ff0000",
            color: "#eee",
            position: "fixed",
            width: "100%",
            maxWidth: "600px",
            zIndex: 9999,
            top: 60
          }}
        >
          <span style={{ color: "#eee", fontSize: "1rem" }}>
            <Trans>Question is too long. Please make it shorter to proceed.</Trans>
          </span>
        </Notification>
      )}



      {chatBotSubmitted ? (
        <div>

          <div style={{
            position: "fixed",
            width: "100%",
            height: "8%",
            // borderRadius: "30px",
            maxWidth: "600px",
            top: "55px",
            // background: "white",
            padding: "0px",
            display: "grid",
            alignItems: "center",
            justifyContent: "'center",
            alignContent: "center",
            backgroundColor: "white"
          }}>


          <div>
              <IoArrowBack onClick={arrowBackHome} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
            </div>
            <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
              <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
                <Trans>Career Guidance</Trans>
              </Text>
            </div>




            {/* <div style={{ marginTop: "0px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
              <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
                <Trans>Career Guidance</Trans>
              </Text>
            </div> */}
          </div>

          <div style={{ marginTop: "108px" }}>
          <div style={{ paddingTop: "15px" }}>

            <Paper style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px", backgroundColor: "white" }}>
              {myChatbotAllQuestion?.map((item) => {
                let chatBotDate = item?.createdAt
                if (item !== undefined || item !== null) {
                  chatBotDate = chatBotDate?.slice(0, 10).split('-').reverse().join('-');
                }
                return <div>{item?.message ? (
                  <Paper onClick={() => { handleRedirect(item?.id) }} withBorder radius="md" ml={'0px'} mr={'0px'} mt={'10px'} className={classes.comment}>
                    <Group>
                      <img src={item?.photoVirtualPath} style={{ objectFit: "cover", height: "10%", width: "10%", borderRadius: "50%", marginTop: "0rem" }} alt="" />
                      <div>
                        <Text fz="sm">{item?.name}</Text>
                        <Text fz="xs" c="dimmed">
                          {chatBotDate}
                        </Text>
                      </div>
                    </Group>
                    <div style={{ marginTop: "5px" }}>
                      <Text style={{ fontWeight: "400", fontSize: "15px" }} c="dimmed">
                        <Trans>Question</Trans>:- {item?.message}
                      </Text>
                    </div>
                  </Paper>
                ) : (
                  <div>
                  </div>
                )}
                </div>
              }
              )}
            </Paper>


            <div style={{ marginTop: "180px" }}>
              <Text style={{ color: "white" }}>.</Text>
            </div>
          </div>
          </div>






          <div style={{
            position: "fixed",
            width: "100%",
            height: "15%",
            // borderRadius: "30px",
            maxWidth: "600px",
            bottom: "63px",
            background: "white",
            padding: "0px",
            display: "grid",
            alignItems: "center",
            justifyContent: "'center",
            alignContent: "center",
          }}>

            <div>

              <div>

                <Paper radius="md" ml={'5px'} mr={'5px'} mt={'10px'}>
                  <div style={{ display: "flex", flexDirection: "row" }}>

                    <Button onClick={addChatBotQues} w="100%" style={{ marginLeft: "30px", border: "none", marginRight: "30px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                      <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                        <Trans>+ Ask New Question</Trans>
                      </Text>
                    </Button>

                  </div>
                </Paper>

              </div>


            </div>

          </div>

        </div>
      ) : (

        <div>
          <div style={{
            position: "fixed",
            width: "100%",
            height: "8%",
            // borderRadius: "30px",
            maxWidth: "600px",
            top: "55px",
            // background: "white",
            padding: "0px",
            display: "grid",
            alignItems: "center",
            justifyContent: "'center",
            alignContent: "center",
            backgroundColor: "white"
          }}>



            <div>
              <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
            </div>
            <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
              <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
                <Trans>Career Guidance</Trans>
              </Text>
            </div>
          </div>


          {/* ///////////////////////////////////// */}

          <div style={{ marginTop: "108px" }}>
            {chatBotAfterSubmitted ? (
              <div style={{ paddingTop: "5px" }}>

                {myChatbotQuestionAnswer && (
                  <Paper withBorder radius="md" ml={'5px'} mr={'5px'} mt={'20px'} className={classes.comment}>
                    <Group>

                      {/* <img src={myChatbotQuestionAnswer?.photoVirtualPath} style={{ objectFit: "cover", height: "12%", width: "12%", borderRadius: "50%", marginTop: "0rem" }} alt="" /> */}
                      {/* <div>
          <Text fz="sm">Rishav</Text>
          <Text fz="xs" c="dimmed">
            {myChatbotQuestionAnswer?.createdAt}
          </Text>
        </div> */}
                    </Group>
                    <div style={{ marginTop: "5px" }}>
                      <Text style={{ fontWeight: "400", fontSize: "15px" }} c="dimmed">
                        {myChatbotQuestionAnswer?.message}
                      </Text>
                    </div>
                    {/*<TypographyStylesProvider className={classes.body}>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
                   __html: myChatbotQuestionAnswer?.message
             }}
        />
      </TypographyStylesProvider>*/}
                  </Paper>
                )}



              </div>
            ) : (
              <div>
              </div>
            )}

            {/* <Paper withBorder radius="md" ml={'5px'} mr={'5px'} mt={'10px'} style={{backgroundColor: "#ebf7fb"}} className={classes.comment}>
      <Group>
      <img src={ShramINlogo} className={classes.siteLogo}/>
        <div>
          <Text fz="sm">ShramIN Admin</Text>
          <Text fz="xs" c="dimmed">
            10 minutes ago
          </Text>
        </div>
      </Group>
      <TypographyStylesProvider className={classes.body}>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html:
              '<p>We will help you in getting best jobs in Delhi, Gurgoan, Ghaziabad, Noida, Faridabad, Ahmedabad, ... etc.</p>',
          }}
        />
      </TypographyStylesProvider>
    </Paper> */}
          </div>

          {/* <div style={{marginTop: "200px"}}>
      .
    </div> */}


          {/* ////////////////////////////////////// */}



          {askQuestionAnaswerChatBot ? (
            <div style={{
              position: "fixed",
              width: "100%",
              height: "15%",
              // borderRadius: "30px",
              maxWidth: "600px",
              bottom: "63px",
              background: "white",
              padding: "0px",
              display: "grid",
              alignItems: "center",
              justifyContent: "'center",
              alignContent: "center",
            }}>

              <div>

                <div>

                  <Paper radius="md" ml={'5px'} mr={'5px'} mt={'10px'}>
                    <div style={{ display: "flex", flexDirection: "row" }}>

                      <Button onClick={askQuestionAnaswerChatBotQues} w="100%" style={{ marginLeft: "30px", border: "none", marginRight: "30px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                        <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                          <Trans>Go to Chat Home</Trans>
                        </Text>
                      </Button>
                    </div>
                  </Paper>

                </div>


              </div>

            </div>
          ) : (
            <div style={{
              position: "fixed",
              width: "100%",
              height: "25%",
              // borderRadius: "30px",
              maxWidth: "600px",
              bottom: "63px",
              background: "white",
              padding: "0px",
              display: "grid",
              alignItems: "center",
              justifyContent: "'center",
              alignContent: "center",
            }}>

              <div>

                <div>
                  <Text style={{
                    marginLeft: "10px", fontSize: "14px", color: "rgb(151,156,160)"
                  }}>
                    <Trans>Maximum input length is 255 characters.</Trans>
                  </Text>
                </div>

                <Paper radius="md" ml={'5px'} mr={'20px'} mt={'10px'}>
                  <div style={{ display: "flex", flexDirection: "row" }}>

                    <Textarea
                      key={i18n.locale}
                      style={{ width: "94%" }}
                      value={value}
                      placeholder={t`Please write your question`}
                      // label="Autosize with no rows limit"
                      autosize
                      minRows={5}
                      maxRows={5}
                      onChange={(event) => setValue(event.currentTarget.value)}
                    />
                    <LuSendHorizonal onClick={submitChatBotAns} color='#228BE6' size={26} style={{ marginLeft: "10px", marginTop: "50px" }} />
                  </div>
                </Paper>

              </div>

            </div>
          )}


        </div>
      )}




    </div>
  )
}

export default Chatbot