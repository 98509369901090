import React, { useState, useEffect } from 'react'
import { TextInput, Checkbox, Button, Group, Box, Radio, Select, Modal, Combobox,
    Input,
    InputBase,
    useCombobox, Text, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useGetCollegeDetailsQuery, useGetEducationDetailsQuery, useGetTradeDetailsQuery, useGetStateDetailsQuery, useGetDistrictDetailsQuery, useAddEducationMutation } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UserAuth } from '../context/AuthContext';



const Eductaion = () => {
    const { i18n } = useLingui();
    const token = useSelector((state) => state?.user?.token)
    const { user } = UserAuth()
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedValue2, setSelectedValue2] = useState(null);
    const [stateId, setStateId] = useState()
    const [stateName, setStateName] = useState()
    const [distName, setDistName] = useState()
    const [distId, setDistId] = useState()
    const candidateId = localStorage.getItem('candidateId')

    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);

    const [searchHeight, setSearchHeight] = useState(false)
    const [searchHeightDis, setSearchHeightDis] = useState(false)
    const [searchHeightCollege, setSearchHeightCollege] = useState(false)
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const comboboxDis = useCombobox({
        onDropdownClose: () => comboboxDis.resetSelectedOption(),
      });

    const [value, setValue] = useState(null);
    const [search, setSearch] = useState('');

    const [valueDis, setValueDis] = useState(null);
    const [searchDis, setSearchDis] = useState('');

    const [valueCollege, setValueCollege] = useState(null);
    const [searchCollege, setSearchCollege] = useState('');


    const [opened, { open, close }] = useDisclosure(false);
    const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
    const navigate = useNavigate()

    const handleSelectChange = (event) => {
        const filteredData = tradeData.filter(item => item.masterName === event);
        setSelectedValue(event)

    };
    const handleSelectChange2 = (event) => {
        const filteredData = educationData.filter(item => item.masterName === event);
        setSelectedValue2(event)

    };


    const { data: tradeData, isLoading, isError, isFetching, refetch } = useGetTradeDetailsQuery({
        skip: !token
    })
    const { data: educationData, isLoading: eduLoading, isError: eduError, isFetching: eduFetching, refetch: eduRefetch } = useGetEducationDetailsQuery({
        skip: !token
    })

    // const { data: instData, isLoading: instLoading, isError: instError, isFetching: instFetching, refetch: instRefetch } = useGetCollegeDetailsQuery(distId, {
    //     skip: !token
    // })


    // const { data: stateData, isLoading: stateLoading, isError: stateError, isFetching: stateFetching, refetch: stateRefetch } = useGetStateDetailsQuery({
    //     skip: !token
    // })
    // const { data: distData, isLoading: distLoading, isError: distError, isFetching: distFetching, refetch: distRefetch } = useGetDistrictDetailsQuery(stateId, {
    //     skip: !token
    // })



    // const [editProfile] = useAddEducationMutation()
    const [addEducation] = useAddEducationMutation();


    useEffect(() => {
        if (token) {
            refetch();
            eduRefetch()
        }
    }, [token, refetch, eduRefetch]);


    const [stateData, setStateData] = useState();
    const [distData, setDistData] = useState();
    const [instData, setInstData] = useState();

    useEffect(() => {
        const fetchStateData = async () => {
            const url = import.meta.env.VITE_BASE_URL + '/candidate/state';
            try {
                const response = await fetch(`${url}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${user?.accessToken}`,
                        "Accept": 'application/json'
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok in stateData');
                }
                const stateResult = await response.json();                 
                setStateData(stateResult);
                
            } catch (error) {
                console.error('Error fetching state data:', error);
            }
        };

        if (user?.accessToken) {
            // console.log("userTokensssssss", user?.accessToken)
            fetchStateData();
        }
    }, [user]);

    /////////////District/////////////

    const fetchDistData = async (itemState) => {
      const url = import.meta.env.VITE_BASE_URL + '/candidate/district';
      try {
          const response = await fetch(`${url}?state=${itemState?.id}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  "Authorization": `Bearer ${user?.accessToken}`,
                  "Accept": 'application/json'
              },
          });
          if (!response.ok) {
              throw new Error('Network response was not ok in distData');
          }
          const distResult = await response.json();   
         // console.log("distResultm",distResult)              
          setDistData(distResult);
          
      } catch (error) {
          console.error('Error fetching dist data:', error);
      }
  };


  ///////////////////College////////////////


  const fetchInstData = async (itemDis) => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/college-institutes';
    try {
        const response = await fetch(`${url}?district=${itemDis?.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user?.accessToken}`,
                "Accept": 'application/json'
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok in InstData');
        }
        const instResult = await response.json();   
       // console.log("InstResultmyyyyyy",instResult)              
        setInstData(instResult);
        
    } catch (error) {
        console.error('Error fetching dist data:', error);
    }
};



  //////////////////////////////////////////



    // const startYear = 2010;
    // const endYear = 2025;
    const years = ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];

    // for (let year = startYear; year <= endYear; year++) {
    //     years.push(year);
    // }

    const openSearch = () => {
        setSearchHeight(true)
        combobox.openDropdown()
        setValueDis(null)
        combobox.resetSelectedOption()
      }
  
      const closeSearch = () => {
        combobox.closeDropdown()
        if(value == null){
          setSearchHeight(false)
        }
        setValueDis(null)
        combobox.resetSelectedOption()
      }

      const openSearchDis = () => {
        setSearchHeightDis(true)
        comboboxDis.openDropdown()
      }
  
      const closeSearchDis = () => {
        comboboxDis.closeDropdown()
        if(valueDis == null){
          setSearchHeightDis(false)
        }
      }

    //   const openSearchCollege = () => {
    //     setSearchHeightCollege(true)
    //     combobox.openDropdown()
    //   }

    //   const closeSearchCollege = () => {
    //     combobox.closeDropdown()
    //     if(valueCollege == null){
    //       setSearchHeightCollege(false)
    //     }
    //   }
  
     



    // const handleStateId = (id, name) => {
    //     setStateId(id)
    //     setStateName(name)
    //     setDistName('')
    //     close()
    // }
    // const handleDistId = (id, name) => {
    //     setDistId(id)
    //     setDistName(name)
    //     close2()

    // }


    const handleSubmit2 = (itemState) => {
        setStateId(itemState?.id)
        setStateName(itemState?.masterName)
        setDistId(null)
        setDistName(null)
        fetchDistData(itemState)
       
    }

    const handleSubmit3 = (itemDis) => {
        setDistId(itemDis?.id)
        setDistName(itemDis?.masterName)
        // setDistName('')
        fetchInstData(itemDis)
    }



    const handleAddEducation = async (candidateId, educationData) => {
        try {
            openLoading()
            const result = await addEducation({ candidateId, data: educationData });
            if (result) {
                closeLoading();
                navigate('/myprofile');
            }
        } catch (error) {
            console.error("Add education error:", error);
            closeLoading()
        }
    };

    const handleSubmit = (values) => {

        const educationData = {
            educationId: parseInt(values.education, 10),
            tradeId: parseInt(values.trade, 10),
            collegeInstituteId: parseInt(values.institute, 10),
            passingYear: parseInt(values.year, 10)
        };
        handleAddEducation(candidateId, educationData);

    }

    const form = useForm({
        initialValues: {
            education: '',
            trade: '',
            institute: '',
            year: ''
        },

        validate: {
            education: (value) => (value.length == 0 ? <Trans>Education is Required</Trans> : null),
            trade: (value) => (value.length == 0 ? <Trans>Trade is Required</Trans> : null),
            // institute: (value) => (value.length == 0 ? 'Institute is Required' : null),
            year: (value) => (value.length == 0 ? <Trans>Year is Required</Trans> : null),
        },
    });


    const filteredOptions = stateData?.filter((itemState) => itemState?.masterName?.toLowerCase().includes(search.toLowerCase().trim()))
    const options = filteredOptions?.map((itemState) => (
     <Combobox.Option onClick={()=>{handleSubmit2(itemState)}} value={itemState?.masterName} key={itemState?.id}>
       {itemState?.masterName}
     </Combobox.Option>
   ));



   
    const filteredOptionsDis = distData?.districts?.filter((itemDis) => itemDis?.masterName?.toLowerCase().includes(searchDis.toLowerCase().trim()))
    const optionsDis = filteredOptionsDis?.map((itemDis) => (
     <Combobox.Option onClick={()=>{handleSubmit3(itemDis)}} value={itemDis?.masterName} key={itemDis?.id}>
       {itemDis?.masterName}
     </Combobox.Option>
   ));





    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

                {/* <Modal opened={opened} onClose={close} title={<Trans>Select State</Trans>} centered>
                    {
                        stateData?.map((item) => {
                            return <div style={{ marginTop: "5px", cursor: "pointer" }} onClick={() => handleStateId(item?.id, item?.masterName)} key={item?.id}>{item?.masterName}</div>
                        })
                    }
                </Modal>

                <Modal opened={opened2} onClose={close2} title={<Trans>Select Districts</Trans>} centered>
                    {
                        stateId && distData ? distData.districts?.map((item) => {
                            return <div style={{ marginTop: "5px", cursor: "poinetr" }} onClick={() => handleDistId(item?.id, item?.masterName)} key={item?.id}>{item?.masterName}</div>
                        }) : null
                    }
                </Modal> */}
                

                <div>
                    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}
                        style={{ display: "flex", flexDirection: "column", gap: ".5rem", height: "auto", padding: "10px" }}>



                        <Select
                            key={i18n.locale}
                            label={<Trans>Your Education Qualification</Trans>}
                            placeholder={t`Select Qualification`}
                            data={educationData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange2}
                            value={selectedValue2}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('education',)}
                        />



                        <Select
                            label={<Trans>Trade/Job Category</Trans>}
                            placeholder={t`Select Trade`}
                            data={tradeData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange}
                            value={selectedValue}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('trade')}
                        />


                        {/* <label htmlFor="" style={{ fontWeight: "400" }}><Trans>Name of your college/institute</Trans></label>

                        <Button onClick={open} w={"50%"}>{stateName ? stateName : <Trans>Select Your State</Trans>}</Button>
                        {
                            stateId && <Button onClick={open2} w={"50%"}>{distName ? distName : <Trans>Select Your District</Trans>}</Button>
                        } */}
<label htmlFor="" style={{ fontWeight: "400" }}><Trans>Name of your college/institute</Trans></label>              
 <div style={{ marginTop: "0px" }}>
<Text size="sm" fw={500}>
<Trans>State of College</Trans>
</Text>
</div> 

      <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setValue(val);
        setSearch(val);
        closeSearch();
        // combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => openSearch()}
          // onFocus={() => openSearch()}
          onBlur={() => {
            closeSearch()
            setSearch(value || '');
            setSearchDis('')
          }}
          placeholder={t`Search State of College`}
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown style={{zIndex:"1", maxHeight: 200, overflowY: 'auto'}}>
        <Combobox.Options>
          {options?.length > 0 ? options : <Combobox.Empty><Trans>State not found</Trans></Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>

 

{stateId && 
<div>
    <div style={{ marginTop: "5px" }}>
<Text size="sm" fw={500}>
<Trans>District of College</Trans>
</Text>
</div> 
   <Combobox
      store={comboboxDis}
      withinPortal={false}
      onOptionSubmit={(valDis) => {
        setValueDis(valDis);
        setSearchDis(valDis);
        closeSearchDis();
        // comboboxDis.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={searchDis}
          onChange={(eventDis) => {
            comboboxDis.openDropdown();
            comboboxDis.updateSelectedOptionIndex();
            setSearchDis(eventDis.currentTarget.value);
          }}
          onClick={() => openSearchDis()}
          // onFocus={() => openSearch()}
          onBlur={() => {
            closeSearchDis()
            setSearchDis(valueDis || '');
          }}
          placeholder={t`Select District of College`}
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown style={{zIndex:"1", maxHeight: 200, overflowY: 'auto'}}>
        <Combobox.Options>
          {optionsDis?.length > 0 ? optionsDis : <Combobox.Empty><Trans>District not found</Trans></Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
    </div>
    }

    {
                            distId &&
                            <div>
    <div style={{ marginTop: "5px" }}>
<Text size="sm" fw={500}>
<Trans>College</Trans>
</Text>
</div> 
                             <Select
                                // label=" Name of your college/institute"
                                placeholder={t`Select your college/institute`}
                                data={instData?.colleges?.map((item) => ({
                                    value: item?.id.toString(),
                                    label: item?.masterName
                                }))}
                                searchable
                                // onChange={handleSelectChange}
                                // value={selectedValue}
                                nothingFoundMessage={t`Nothing found...`}
                                {...form.getInputProps('institute')}
                            />
                            </div>
                        }






                        <Select
                            label={<Trans>Passing Year (or expected)</Trans>}
                            placeholder={t`Select Your Passing Year`}
                            data={years}
                            {...form.getInputProps('year')}
                        />


                        <Group justify="center" mt="md" >
                            <Button

                                type="submit" style={{
                                    width: '100%',
                                    marginBottom: '5rem',
                                }}><Trans>Add</Trans></Button>
                        </Group>
                    </form>
                </div>
            </Box>
        </>
    )
}

export default Eductaion