import { Text, Card, RingProgress, Group,Button, useMantineTheme } from '@mantine/core';
import classes from '../styles/AppliedJobCard.module.css';
import { IoHammerOutline } from "react-icons/io5";
import { FaPerson } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import {useNavigate} from 'react-router-dom'
import { Trans } from '@lingui/macro';

 function AppliedJobsCard({jobTitle,applicationStatus,salary,appliedDate,trade,logo,companyName,workType,location,jobId}) {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  let jobdate = appliedDate
  if (appliedDate !== undefined || appliedDate !== null) {
    jobdate = jobdate?.slice(0, 10).split('-').reverse().join('-');
  }


  const handleRedirect = (jid)=>{
     navigate(`/jobdescription?jid=${jid}`)     
  }


  return (
  
    <Card withBorder radius="md" className={classes.card} 
     onClick={()=>{handleRedirect(jobId)}}
    >
      <Group mt="md" className={classes.groupContainer}>
          <div>
                <Text fw={500} color="#1F7A8C">
                  {jobTitle}
                </Text>

                <Text fz="sm" c="dimmed" >
                    {companyName}
                </Text>

              <div style={{marginTop:"15px"}}>
                  <Text style={{whiteSpace: "pre-wrap",display: "unset"}}  size="sm" mb={0} mt={0} fw={600}>
                    {trade}
                  </Text>
                  </div>
                  <div>
                  <Text style={{whiteSpace: "pre-wrap",display: "unset"}} size="sm" mb={0} mt={0} fw={600}>
                    {location}
                  </Text>
                  </div>
                  <div>
                  <Text style={{whiteSpace: "pre-wrap",display: "unset"}}  size="sm" mb={0} mt={0} fw={600} >
                    {salary} 
                  </Text>
                  </div>
                  <div>
                  <Text style={{whiteSpace: "pre-wrap",display: "unset",}}  size="sm" mb={0} mt={0} fw={600}>
                   Applied On : {jobdate}
                  </Text>
                  </div>

                  

            {/* <div>
              <label className={classes.upload_resume} style={{ display: "flex", background:"#1F7A8C", color:"#fff", }}>
                <Text  size="sm" mb={0} mt={0} fw={600} >
                   Status : {applicationStatus} 
                  </Text>
              </label>
            </div> */}

            <div style={{ marginTop: "10px" }}>
              <Button variant="outline" style={{borderColor:"#1F7A8C",borderWidth:"1px"}}>
                <Text c="#1F7A8C" size="sm" mb={0} mt={0} fw={600}>
                Status : {applicationStatus} 
                </Text>
              </Button>
            </div>

                  {/* <div style={{background:"#1F7A8C", color:"#fff", borderColor:"#1F7A8C", padding:"5px", borderWidth:"2px"}}>
                  <Text  size="sm" mb={0} mt={0} fw={600} >
                   Status : {applicationStatus} 
                  </Text>
                  </div> */}
               

                 
             

          </div>

          <div style={{textAlign:"center"}}>

          <div style={{textAlign:"center", minHeight:"50px"}}>
               {logo && (
                <img src={logo} style={{width:"40px", height:"40px"}}/>
               )}
            </div>
            
             <div style={{marginTop:"2rem",paddingTop:"1rem"}}>
              <button className={classes.viewjobbtnPrivate}><Trans>view</Trans></button>
             </div>
          </div>

      </Group>  
    </Card>
   
  );
}

export default AppliedJobsCard
