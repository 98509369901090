import React,{useEffect, useState} from 'react'
import GovjobCard from '../components/GovjobCard'
import {useSelector} from 'react-redux'
import {useGetgovtjobsQuery} from '../redux/api/apiSlice'
import {Skeleton} from '@mantine/core'

const Govtjobsection = () => {
    const token = useSelector((state) => state?.user.token)
    const [alljobs, setAllJobs] = useState([])
    const [loading, setloading] = useState(true)
    const candidateId = localStorage.getItem('candidateId')

    const { data: jobdata, isLoading, isError, isFetching, refetch } = useGetgovtjobsQuery(candidateId,{
        skip: !token
    })



    useEffect(() => {
        if (token) {
            refetch();
        }
    }, [token, refetch]);


    useEffect(() => {
        if (jobdata) {
            setAllJobs(jobdata?.content)
            setloading(false)
        }
    }, [jobdata]);


      

  return (
    <div style={{width:"100%"}}>
         {loading ? (
            <>
                <div style={{marginBottom:"20px"}}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                </div>
                <div style={{marginBottom:"20px"}}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                </div>
                <div>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                </div>
            </>
        ) : (
            <>
                {alljobs.length > 0 && (
                    alljobs.map(job=>{
                        return  <GovjobCard
                        id={job?.id}
                        title={job?.jobTitle}
                        company={job?.companyName}
                        trade={job?.trade?.masterName}
                        location={job?.location?.masterName}
                        state={job?.location?.parentModel?.masterName}
                        education={job?.education?.masterName}
                        experience={job?.experience?.masterName}
                        salary={job?.salary?.masterName}
                        logo={job?.logoVirtualPath ?  job?.logoVirtualPath  : job?.employer?.logoVirtualPath }
                    
                        />
                    })
                )}   

                <div style={{marginTop: "60px"}}>
                 <h6>.</h6>
                </div>
            </>
        )}
   </div>
  )
}

export default Govtjobsection