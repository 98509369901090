import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "../styles/MyProfile.module.css";
import {
  Card,
  Image,
  Text,
  Group,
  Badge,
  Center,
  Button,
  Title,
  RingProgress,
  Loader,
  Modal,
  Notification,
  Stack,
  Input,
  AspectRatio,
  LoadingOverlay,
} from "@mantine/core";
import { IoPerson } from "react-icons/io5";
import ReactPlayer from "react-player/lazy";
import Webcam from "react-webcam";
import { FaArrowLeft } from "react-icons/fa";
import { SiBookmeter } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { TbGenderAndrogyne } from "react-icons/tb";
import { RiGraduationCapFill } from "react-icons/ri";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { IoBagSharp } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { MdEdit } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { RxCross2 } from "react-icons/rx";
import {
  useCandidateSignUpMutation,
  useGetProfileDetailsQuery,
  useUploadVideoMutation,
} from "../redux/api/apiSlice";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { MdDirectionsBike } from "react-icons/md";
import { UserAuth } from "../context/AuthContext";
import { MdOutlineAdd } from "react-icons/md";
import { useDisclosure } from "@mantine/hooks";
import { FaVideo } from "react-icons/fa6";
import { FaImage } from "react-icons/fa";
import { FaCamera } from "react-icons/fa6";
import { Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import { appToken } from "../redux/userSlice";
//import WebcamVideo from './WebcamVideo';
import WebcamImage from "./WebcamImage";
import { BsFillCameraFill } from "react-icons/bs";
import OtpInput from 'react-otp-input';
import { useUploadPhotoMutation } from "../redux/api/apiSlice";
import VideoPlayerProfile from "./VideoPlayerProfile";
import { FaCheck } from "react-icons/fa";
import { notifications } from "@mantine/notifications";
import { usePostSendEmailLinkMutation } from '../redux/api/apiSlice';
import { useEditProfileDetailsMutation } from '../redux/api/apiSlice';
import { setupI18n } from "@lingui/core"
import { t } from "@lingui/macro"


//import VideoPlayer from './VideoPlayer';

const MyProfile = () => {
  const { user } = UserAuth();
  var userId = localStorage.getItem("userId");
  const candidateId = localStorage.getItem("candidateId");
  var languageOrigin = localStorage.getItem("languageOrigin");

  const navigate = useNavigate();

  const token = useSelector((state) => state?.user.token);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [openedAccepted, setOpenedAccepted] = useState(false);
  const [openedRejected, setOpenedRejected] = useState(false);
  const [otp, setOtp] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
  const [opened3, { open: open3, close: close3 }] = useDisclosure(false);
  const [opened4, { open: open4, close: close4 }] = useDisclosure(false);

  const [opened5, { open: open5, close: close5 }] = useDisclosure(false);
  const [opened6, { open: open6, close: close6 }] = useDisclosure(false);

  const [loading, { open: openLoading, close: closeLoading }] =
    useDisclosure(false);

  //const [loading, setloading] = useState(true)
  const [id, setId] = useState(null);
  const [show, setshow] = useState(false);

  const [myVideoResumevideos, setMyVideoResumevideos] = useState(null);
  const [openedAlertEmptySkip, setOpenedAlertEmptySkip] = useState(false);
  const [uploadVideoMessage, setUploadVideoMessage] = useState(false);

  const linkRef = useRef(null);
  const fcmTokenData = useSelector((state) => state?.user.fcmtoken);
  const [candidateSignUp] = useCandidateSignUpMutation();
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const [uploadProfilePhoto] = useUploadPhotoMutation();
  const [uploadVideo] = useUploadVideoMutation();

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(null);
  const [postSendEmail] = usePostSendEmailLinkMutation()
  const [reqID, setReqID] = useState('')
  const [openedVerification , { open : openModal, close : closeModal }] = useDisclosure(false);
  const [editProfile] = useEditProfileDetailsMutation()
  const i18n = setupI18n()


     //Msg91Code
     useEffect(() => {
      const configuration = {
        widgetId: "346661676c63343134323336",
        tokenAuth: "423027TFGdtyddpJu665aca03P1",
        exposeMethods: true,   
        success: (data) => {
            // get verified token in response
            console.log('success response', data);
        },
        failure: (error) => {
            // handle error
            console.log('failure reason', error);
        }
      };
  
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = () => {
        if (window.initSendOTP) {
          window.initSendOTP(configuration);
        }
      };
      script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);


  const closeModalAccepted = () => {
    setOpenedAccepted(false);
    open6();
    setShowAlertSuccess(false);
  };

  const closeModalRejected = () => {
    setOpenedRejected(false);
    open5();
    setShowAlertSuccess(false);
  };

  //////////////////////

  const WebcamProfileResumeVideo = ({ handleClick }) => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [blobVideoData, setBlobVideoData] = useState(null);

    const handleStartCaptureClick = useCallback(() => {
      setIsActive(true);
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
      ({ data }) => {
        //console.log(data,"pppppooooooiiiiiiii")
        const url = URL.createObjectURL(data);
        setBlobVideoData(url);
        //console.log(url,"qqqqqqqq")
        if (data.size > 0) {
          setRecordedChunks((prev) => prev.concat(data));
        }
      },
      [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {
      setIsActive(false);
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    useEffect(() => {
      let intervalId;

      if (isActive) {
        intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 59) {
              setMinutes((prevMinutes) => prevMinutes + 1);
              return 0; // Reset seconds to 0 when reaching 60
            }
            return prevSeconds + 1;
          });
        }, 1000);
      } else {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }, [isActive]);

    const formatTime = (value) => {
      return value < 10 ? `0${value}` : value;
    };

    const handleUpload = useCallback(async () => {
      fetchEmptyFeedbackData();
      openLoading();
      if (recordedChunks.length) {
        try {
          setUploading(true);

          const formData = new FormData();
          const blob = new Blob(recordedChunks, { type: "video/webm" });
          formData.append(
            "videoResume",
            blob,
            "react-webcam-stream-capture.webm"
          );
          try {
            setUploadVideoMessage(true);
            uploadVideo(formData)
              .then((res) => {
                // console.log("after uplaod in web cam" + JSON.stringify(res.data))
                console.log(
                  "after uplaod in Profile" + JSON.stringify(res.data.message)
                );
                if (res.data.message == "successfully uploaded") {
                  setUploadVideoMessage(false);
                }
                fetchUploadMyVideoResumeData();
                setShowAlertSuccess(true);
                closeLoading();
                setFile(null);
                //setMyVideoResumevideos(null)
              })
              .catch((err) => {
                console.log("ffffff");
              });
            console.log(handleClick(false));
          } catch (error) {
            console.error("Error uploading video:", error);
            alert("An error occurred. Please try again.");
          }
        } catch (error) {
          console.error("Error uploading video:", error);
        } finally {
          setUploading(false);
          setRecordedChunks([]);
        }
      }
    }, [recordedChunks]);

    return (
      <>
        {recordedChunks.length > 0 && !uploading ? (
          <div>
            <div style={{ marginTop: "10px" }}>
              <Text fw={600} ta="center" size="lg">
                Preview for video
              </Text>
            </div>

            <div
              className={`${styles.videocontainer} ${styles.videocontainerShow}`}
            >
              <ReactPlayer
                url={blobVideoData}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        ) : (
          <>
            <LoadingOverlay
              visible={loading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Webcam
                muted={true}
                audio={true}
                ref={webcamRef}
                className={styles.webcam}
              />
            </div>
            {isActive ? (
              <Center style={{ marginTop: "10px" }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "red",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "20px",
                    margin: "5px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {formatTime(minutes)}:{formatTime(seconds)}
                </Text>
              </Center>
            ) : (
              <Center style={{ marginTop: "10px" }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "Black",
                    backgroundColor: "#A5C7F4",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "20px",
                    margin: "5px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {formatTime(minutes)}:{formatTime(seconds)}
                </Text>
              </Center>
            )}
          </>
        )}

        <div>
          {recordedChunks.length > 0 && !uploading ? (
            <Center>
              <button
                style={{ marginLeft: "0px" }}
                onClick={handleUpload}
                className={styles.upload_video_btn10}
              >
                <Trans>Upload</Trans>
              </button>
            </Center>
          ) : (
            <>
              {capturing ? (
                <Center>
                  <button
                    className={styles.upload_video_btn10}
                    onClick={handleStopCaptureClick}
                  >
                    <Trans>Stop Capture</Trans>
                  </button>
                </Center>
              ) : (
                <Center>
                  <button
                    className={styles.upload_video_btn10}
                    onClick={handleStartCaptureClick}
                  >
                    <Trans>Start Capture</Trans>
                  </button>
                </Center>
              )}
            </>
          )}
          {uploading && (
            <p>
              <Trans>Uploading...</Trans>
            </p>
          )}
        </div>

        {recordedChunks.length > 0 && !uploading ? (
          <></>
        ) : (
          <div style={{ marginTop: "10px" }}>
            <Text fw={600} ta="center" size="md">
              <Trans>
                {" "}
                Hello! My name is [NAME]. I am from [LOCATION]. I am [AGE] years
                old. I have completed [HIGHEST EDUCATION] in [TRADE] from
                [COLLEGE] in [PASSING YEAR]. I have [EXPERIENCE YEAR] years of
                work experience as [TRADE] in [COMPANY NAME]. Thank you!
              </Trans>
            </Text>
          </div>
        )}
      </>
    );
  };

  /////////////////////

  // const input = document.querySelector('[type=file]')
  // const url = URL.createObjectURL(input.files[0])

  // const handleFileChange2 = (e) => {
  //     setFile(e.target.files[0]);
  // };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const url = URL.createObjectURL(file);
    setUrl(url);
    // console.log(url,"qqqqqqqq")
  };

  const handleUploadVideo = async () => {
    fetchEmptyFeedbackData();
    openLoading();
    if (!file) {
      alert("Please select a video file");
      return;
    }
    close4();
    close();
    close3();
    close2();
    const formData = new FormData();
    formData.append("videoResume", file);

    try {
      setUploadVideoMessage(true);
      uploadVideo(formData)
        .then((res) => {
          // alert('succesfully uploaded' + JSON.stringify(res.data))
          console.log(
            "after uplaod in Profile" + JSON.stringify(res.data.message)
          );
          if (res.data.message == "successfully uploaded") {
            // console.log(res.data.message,"aaaaaallllll")
            //    setTimeout(() => {
            //     // console.log('qqqqqqnnnnnnnnn')
            //      fetchMyVideoResumeData()
            //      closeLoading()
            //  }, 10000);
            // setMyVideoResumevideos(null)
            setUploadVideoMessage(false);
          }

          setFile(null);
          fetchUploadMyVideoResumeData();
          setShowAlertSuccess(true);
          closeLoading();
        })
        .catch((err) => {
          console.log("ffffff");
        });
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);
  };

  const uploadPhoto = async () => {
    openLoading();
    try {
      const formData = new FormData();
      formData.append("photo", selectedPhoto);

      uploadProfilePhoto(formData)
        .then(() => {
          closeLoading();
        })
        .catch(() => {
          console.log("error");
        });
    } catch (error) {
      console.error("Error uploading photo:", error.message);
    }
    close2();
    setSelectedPhoto("");
  };

  const {
    data: userData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetProfileDetailsQuery(userId, {
    skip: !token,
  });
  console.log(userData?.candidateEducationModel?.collegeInstitute?.masterName);
  const profileScore = userData?.profileScore;

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (fcmTokenData) {
      candidateSignUp(fcmTokenData);
    }
  }, []);

  /////////////Candidate Video Resume Feedback////////////////
  useEffect(() => {
    if (user) {
      fetchMyVideoResumeData();
    }
  }, [user]);

  //////////////////////Feedback Null////////////////////////

  const fetchEmptyFeedbackData = async () => {
    const url =
      import.meta.env.VITE_BASE_URL + "/public/feedback/candidate/video-resume";
    try {
      const response = await fetch(`${url}?candidateId=${candidateId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok in EmptyFeedback");
      }
      const videoResumemyEmptyFeedbackdata = await response.json();
      fetchMyVideoResumeData();
      //console.log("EmptyFeedbackoooo*-", videoResumemyEmptyFeedbackdata)
      //setMyVideoResumeEmptyFeedback(videoResumemyEmptyFeedbackdata?.feedback)
      // setProData(videoResumemyEmptyFeedbackdata);
    } catch (error) {
      console.error("Error fetching EmptyFeedback data:", error);
    }
  };

  /////////////////////////////////////////////////////////

  const fetchMyVideoResumeData = async () => {
    const url = import.meta.env.VITE_BASE_URL + "/feedback/candidate/";
    try {
      const response = await fetch(`${url}${candidateId}/video-resume`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok in videoResumeData");
      }
      const videoResumemyvideosdata = await response.json();
      //console.log("VideoResumemyoooooooooooooo*-", videoResumemyvideosdata?.feedback)
      setMyVideoResumevideos(videoResumemyvideosdata?.feedback);
      // setProData(videoResumemyvideosdata);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  ////////////////////////////////////////

  const fetchUploadMyVideoResumeData = async () => {
    // console.log("checkProofiiiile222222222")
    const url = import.meta.env.VITE_BASE_URL + "/public/feedback/candidate/";
    try {
      const response = await fetch(`${url}${candidateId}/video-resume`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok in uploadvideoResumeData");
      }
      const uploadvideoResumemyvideosdata = await response.json();
      // console.log("iiiioooUploadVideopppppppppppppp**", uploadvideoResumemyvideosdata)
      if (uploadvideoResumemyvideosdata) {
        fetchMyVideoResumeDataForModal();
      }
      fetchMyVideoResumeData();

      //  setMyVideoResumevideos(uploadvideoResumemyvideosdata)
      // setProData(videoResumemyvideosdata);
    } catch (error) {
      console.error("Error fetching uploadVideoprofile data:", error);
    }
  };

  /////////////////////for modal///////////////////////

  const fetchMyVideoResumeDataForModal = async () => {
    const url = import.meta.env.VITE_BASE_URL + "/feedback/candidate/";
    try {
      const response = await fetch(`${url}${candidateId}/video-resume`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          "Network response was not ok in videoResumemyvideosdataformodal"
        );
      }
      const videoResumemyvideosdataformodal = await response.json();
      // console.log("formodalVideoResumemyoooooooooooooo*-", videoResumemyvideosdataformodal?.feedback?.relevancy)
      if (videoResumemyvideosdataformodal?.feedback?.relevancy == 0) {
        setOpenedRejected(true);
      } else if (videoResumemyvideosdataformodal?.feedback?.relevancy == 1) {
        setOpenedAccepted(true);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  ///////////////////////////////////////////////////////

  const buttonStyles = {
    backgroundColor: "white",
    color: "black",
    boxShadow: "-2px 1px 14px -7px rgba(0, 0, 0, 0.75)",
  };

  // const handleDownload = async () => {
  //     try {
  //         console.log("aaaaallllllldkkkkk", userData?.textResumeVirtualPath)
  //         const response = await fetch(userData?.textResumeVirtualPath);

  //         if (!response.ok) {
  //             throw new Error(`HTTP error! Status Test Resume: ${response.status}`);
  //         }

  //         const blob = await response.blob();
  //         const url = URL.createObjectURL(blob);
  //         linkRef.current.href = url;
  //         linkRef.current.download = 'resume.jpg';
  //         linkRef.current.click();
  //         URL.revokeObjectURL(url);
  //     } catch (error) {
  //         console.error('Error downloading file Text Resume:', error);
  //     }
  // };

  const getTextResume = (e) => {
    //console.log("lllssssss")
    e.stopPropagation();
  };

  const handleDownload = async () => {
    // try {
    //    // console.log("aaaaallllllldkkkkk", userData?.textResumeVirtualPath);
    //     var response = await fetch(userData?.textResumeVirtualPath);

    //     if (!response.ok) {
    //         throw new Error(`HTTP error! Status Text Resume: ${response.status}`);
    //     }

    //     const blob = await response.blob();
    //     const url = URL.createObjectURL(blob);
    //    // console.log("aaaaallllllldkkkkk", url, userData?.textResumeVirtualPath);

    //     // Provide a meaningful name for the downloaded file based on its content
    //     const fileName = 'resume'; // You can adjust the filename based on the actual content

    //     linkRef.current.href = url;
    //     linkRef.current.download = fileName;
    //     linkRef.current.click();

    //     // Properly revoke the object URL to release browser resources
    //     URL.revokeObjectURL(url);
    // } catch (error) {
    //     console.error('Error downloading file Text Resume:', error);
    // }

    try {
      var response = await fetch(userData?.textResumeVirtualPath);

      if (!response.ok) {
        throw new Error(`HTTP error! Status Text Resume: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // Create a new anchor element
      const link = document.createElement("a");
      link.href = url;
      link.download = "resume"; // Provide a meaningful name for the downloaded file based on its content

      // Trigger a click on the link
      link.click();

      // Properly revoke the object URL to release browser resources
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file Text Resume:", error);
    }
  };

  const handlePhotoUpload = () => {
    // open()
    open2();
  };
  const handleClick = (data) => {
    close3();
    close4();
    // console.log("kkkkk" + data)
  };
  const closeModel = () => {
    close();
    close2();
  };

  const openRejectedModel = () => {
    open4();
    close5();
  };

  const openAcceptedModel = () => {
    open4();
    close6();
  };

  const closeSuccessAlert = () => {
    setTimeout(() => {
      // console.log('qqqqqqnnnnnnnnn')
      fetchMyVideoResumeData();
      closeLoading();
    }, 20000);

    setShowAlertSuccess(false);
  };

  const arrowBack = () => {
    // setFile(null)
    // close4()
    setOpenedAlertEmptySkip(true);
  };

  const arrowSampleBack = () => {
    close4();
  };

  const closeSkipTest = () => {
    setOpenedAlertEmptySkip(false);
    setFile(null);
    close4();
  };

  const closeNoSkipTest = () => {
    setOpenedAlertEmptySkip(false);
  };

  
   

  const handleVerifyEmail = ()=>{
    
          var object = {
            candidateId : userData?.id,
            email :  userData?.email , 
          }

         

          postSendEmail(object).then((result) => {
                notifications.show({
                    color:"green",
                    message:i18n._(t`Verification link has been sent to your Email`)
                })
          }).catch((err) => {
               console.log(err)
          });


    
  }

  
  
  const handleMobileVerify = ()=>{
        const mobileNo = `91${userData?.mobileNo}`
        sendOtp(mobileNo)
 }


const sendOtp = async (mobileNumber)=>{
 window.sendOtp(
     mobileNumber,  
    (data) => {
      setReqID(data.message)
      notifications.show({
        color:"green",
         message: i18n._(t`OTP has been sent to your Mobile Number`)
    })

      setTimeout(() => {
          openModal();
      }, 1000);
    }
    ,  
    (error) => {
      notifications.show({
        color: 'red',
        message: error.message,
      })
    } 
  );
}
 




 const handleOTPVerify =  ()=>{
     window.verifyOtp(
         Number(otp),  
         async (data) => {

          
             const object = {
                 mobileNoVerified: true
             };

             editProfile(object).then((result) => {
                  
              notifications.show({
                color:"green",
                message:i18n._(t`Mobile Number verified successfully`)
            })

                    setTimeout(() => {
                       closeModal();
                       setOtp("")
                    }, 2000);
                    
             }).catch((err) => {
                 console.log(err)
             });


           }, 
         (error) =>{
             notifications.show({
                 color: 'red',
                 message: i18n._(t`Wrong OTP Entered`)
               })
         },
         reqID
       );
 }


  return (
    <div className={styles.container}>
      {showAlertSuccess && (
        <Notification
          color="#eee"
          onClose={() => closeSuccessAlert()}
          style={{
            backgroundColor: "#2aa63d",
            color: "#eee",
            position: "fixed",
            width: "100%",
            maxWidth: "600px",
            zIndex: 9999,
            top: 60,
          }}
        >
          <span style={{ color: "#eee", fontSize: "1rem" }}>
            <Trans>
              Video uploaded successfully, Please wait for video resume
              feedback.
            </Trans>
          </span>
        </Notification>
      )}

      <Modal
        withCloseButton={false}
        opened={openedAccepted}
        onClose={() => setOpenedAccepted(false)}
        centered
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0% 100%",
            gridGap: "0px",
          }}
        >
          <div style={{ textAlign: "center" }}></div>
          <Text
            style={{ color: "black", textAlign: "center", marginTop: "10px" }}
            fw={500}
            size="lg"
            mb="md"
          >
            <Trans>Your video resume is</Trans>{" "}
            <span style={{ color: "green", fontWeight: "bold" }}>
              <Trans>Accepted</Trans>
            </span>
            .
          </Text>
        </div>

        <Stack align="center">
          <Group position="center" mt="md">
            <div
              style={{
                marginTop: "0px",
                justifyContent: "center",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ backgroundColor: "purple" }}
                onClick={closeModalAccepted}
              >
                <Trans>View Video Resume</Trans>
              </Button>
            </div>
          </Group>
        </Stack>
      </Modal>

      <Modal
        withCloseButton={false}
        opened={openedRejected}
        onClose={() => setOpenedRejected(false)}
        centered
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0% 100%",
            gridGap: "0px",
          }}
        >
          <div style={{ textAlign: "center" }}></div>
          <Text
            style={{ color: "black", textAlign: "center", marginTop: "10px" }}
            fw={500}
            size="lg"
            mb="md"
          >
            <Trans>Your video resume is</Trans>{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              <Trans>Rejected</Trans>
            </span>
            .
          </Text>
        </div>

        <Stack align="center">
          <Group position="center" mt="md">
            <div
              style={{
                marginTop: "0px",
                justifyContent: "center",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ backgroundColor: "purple" }}
                onClick={closeModalRejected}
              >
                <Trans>View Reason</Trans>
              </Button>
            </div>
          </Group>
        </Stack>
      </Modal>


      <Modal closeOnClickOutside={false} opened={openedVerification} onClose={()=>{
                setOtp("")
                closeModal()
            }} title = {i18n._(t`Enter verification code`)} centered styles={{title : {fontSize:"20px", textAlign:"center", width:"100%", fontWeight:"500"}}}>
                   <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                      renderSeparator={<span style={{margin:"0 5px"}}></span>}
                      containerStyle={{textAlign :"center", width:"100%", padding:"10px"}}
                      inputStyle={{fontSize:"15px", color:"#000", width:"100%", height:"40px", border:"1px solid black", borderRadius:"10px", outline:"none"}}
                      shouldAutoFocus={true}
                   />

                <div style={{textAlign:"center", marginTop:"20px"}}>
                <Button variant="filled" size="sm" style={{marginRight:"10px"}} disabled={otp.length < 1 ? true : false} onClick={()=>{setOtp("")}}><Trans>Reset</Trans></Button>
                <Button variant="filled" size="sm" disabled={otp.length == 6 ? false : true} onClick={handleOTPVerify}><Trans>Verify OTP</Trans></Button>
                </div>

            </Modal>


      {/* /////////////////////////////////////// */}

      <div className={styles.container_header}>
        {/* <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                <div style={{ height: "75%", width: '60%' }} >
                    <video controls src={userData?.videoResumeVirtualPath} typeof='mp4'
                        height={"12%"} width={"100%"} style={{ position: 'absolute', top: "1rem", width: "97%", objectFit: "cover" }} ></video>
                </div> */}
        <div className={styles.profile_photo}>
          <img
            src={userData?.photoVirtualPath}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              borderRadius: "50%",
              marginTop: "1rem",
            }}
            alt=""
          />
          {/* Photo Upload */}
          <BsFillCameraFill
            color="#228BE6"
            size={30}
            style={{
              position: "absolute",
              top: "60%",
              left: "70%",
              cursor: "pointer",
            }}
            onClick={handlePhotoUpload}
          />
        </div>

        {userData?.videoResumeVirtualPath ? (
          <>
            {uploadVideoMessage == true ? (
              <div>
                <Center>
                  <Loader
                    style={{
                      position: "absolute",
                      right: "60px",
                      marginBottom: "60px",
                    }}
                    color="#034078"
                    size={30}
                  />
                </Center>
              </div>
            ) : (
              <>
                {myVideoResumevideos?.relevancy == 0 ? (
                  <div
                    style={{ position: "absolute", right: "8px", width: "45%" }}
                  >
                    <Text
                      style={{
                        marginLeft: "5px",
                        marginBottom: "45px",
                        textAlign: "center",
                      }}
                    >
                      <Trans>Your video resume is</Trans>{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        <Trans>Rejected</Trans>
                      </span>
                      .
                    </Text>
                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        width: "100%",
                      }}
                    >
                      <button
                        onClick={open5}
                        className={styles.upload_video_btn7}
                      >
                        <Trans>View Reason</Trans>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {myVideoResumevideos?.relevancy == 1 ? (
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          width: "45%",
                        }}
                      >
                        <Text
                          style={{
                            marginLeft: "5px",
                            marginBottom: "45px",
                            textAlign: "center",
                          }}
                        >
                          <Trans>Your video resume is</Trans>{" "}
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            <Trans>Accepted</Trans>
                          </span>
                          .
                        </Text>
                        <div
                          style={{
                            position: "absolute",
                            right: "6px",
                            width: "100%",
                          }}
                        >
                          <button
                            onClick={open6}
                            className={styles.upload_video_btn9}
                          >
                            <Trans>View Video Resume</Trans>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          width: "45%",
                        }}
                      >
                        <Text
                          style={{
                            marginLeft: "5px",
                            marginBottom: "45px",
                            textAlign: "center",
                          }}
                        >
                          <Trans>Your video resume is in</Trans>{" "}
                          <span style={{ color: "orange", fontWeight: "bold" }}>
                            <Trans>Under Process</Trans>
                          </span>
                          .
                        </Text>
                        <div
                          style={{
                            position: "absolute",
                            right: "10px",
                            width: "100%",
                          }}
                        >
                          <button
                            onClick={open6}
                            className={styles.upload_video_btn9}
                          >
                            <Trans>View Video Resume</Trans>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div style={{ position: "absolute", right: "0px", width: "70%" }}>
            <button onClick={open4} className={styles.upload_video_btn}>
              <Trans>Upload Video Resume</Trans>
            </button>
          </div>
        )}

        {/* //////Under Process///////
            <div style={{ position: 'absolute', right: "8px", width: "45%", }}>
                    <Text style={{marginLeft:'5px', marginBottom: "10px", textAlign:"center" }}>
                        Your video resume is in <span style={{color :"orange", fontWeight:"bold"}}>Under Process</span>.
                    </Text>
                </div> */}

        {/* ////////Accepted//////
           <div style={{ position: 'absolute', right: "8px", width: "45%" }}>
                       <Text style={{marginLeft:'5px', marginBottom: "45px", textAlign:"center" }}>
                        Your video resume is <span style={{color :"green", fontWeight:"bold"}}>Accepted</span>.
                    </Text>
                       <div style={{ position: 'absolute', right: "6px", width: "100%" }}>
                       <button onClick={open6} className={styles.upload_video_btn9}><Trans>View Video Resume</Trans></button>
                   </div>
                   </div> */}

        {/* //////Rejected////////
             <div style={{ position: 'absolute', right: "8px", width: "45%", }}>
                    <Text style={{marginLeft:'5px', marginBottom: "50px", textAlign:"center" }}>
                        Your video resume is <span style={{color :"red", fontWeight:"bold"}}>Rejected</span>.
                    </Text>
                    <div style={{ position: 'absolute', right: "20px", width: "100%" }}>
                    <button onClick={open5} className={styles.upload_video_btn7}><Trans>View Reason</Trans></button>
                </div>
                </div> */}
      </div>

      <Modal opened={opened4} centered withCloseButton={false} onClose={close4}>
        {file && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              <RxCross2 onClick={arrowBack} color="black" size={20} />
            </div>

            {/* <Button style={{ fontSize: "18px"}} mb={"10px"} mt={"20px"} ml={"10px"} color='purple' onClick={handleUploadVideo} disabled={!file} >
                         <Trans>Upload Video</Trans>
                        </Button>  */}
          </div>
        )}

        <Center style={{ alignContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {file && (
              <div>
                <Button
                  style={{ fontSize: "18px" }}
                  mb={"10px"}
                  mt={"10px"}
                  ml={"10px"}
                  color="purple"
                  onClick={handleUploadVideo}
                  disabled={!file}
                >
                  <Trans>Upload Video</Trans>
                </Button>
              </div>
            )}

            {!file && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginBottom: "25px",
                }}
              >
                <RxCross2 onClick={arrowSampleBack} color="black" size={20} />
              </div>
            )}

            {!file && (
              <Center>
                <Button
                  style={{
                    width: "100%",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                  mb={"10px"}
                  color="purple"
                  onClick={open3}
                >
                  <FaVideo style={{ marginRight: "10px" }} />
                  <Trans>Upload from Camera</Trans>
                </Button>
              </Center>
            )}

            <Center>
              {!file && (
                <label
                  style={{
                    width: "100%",
                    marginLeft: "20px",
                    marginRight: "20px",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  className={styles.upload_image_btn}
                >
                  <input
                    style={{ opacity: "0", width: "5%" }}
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange2}
                  ></input>
                  <FaImage style={{ marginRight: "10px" }} />
                  <Trans>Upload from Gallery</Trans>
                </label>
              )}
            </Center>
          </div>
        </Center>

        {file ? (
          <div>
            <div style={{ marginTop: "10px" }}>
              <Text fw={600} ta="center" size="lg">
                Your selected video
              </Text>
            </div>

            <div
              className={`${styles.videocontainer} ${styles.videocontainerShow}`}
            >
              <ReactPlayer
                url={url}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "10px" }}>
              <Text fw={600} ta="center" size="lg">
                <Trans>Sample Video</Trans>
              </Text>
            </div>

            <div
              className={`${styles.videocontainerPro} ${styles.videocontainerShow}`}
            >
              {/* <ReactPlayer 
            url={`https://shraminmedia-inct.streaming.media.azure.net/ce8da9eb-549d-40e6-a68a-ae5af2efab24/ShramIN%20ADMIN%20instructal%20video_1280x720_AACAudio_1039.mp4`} 
            controls={true} width="100%" height="100%"/> */}
              <VideoPlayerProfile
                url="https://vz-4ecd3a95-59f.b-cdn.net/3189ee11-0618-4f07-8be0-a25a29439776/playlist.m3u8"
                width="100%"
                height="200px"
              />
              {/* <VideoPlayerProfile url="https://ep-shraminmkmedia-mediakind-video-staging.japaneast.streaming.mediakind.com/Testimonial-Calcom/manifest.ism/manifest(format=m3u8-cmaf)" width="100%" height="200px"/> */}
            </div>
          </div>
        )}
      </Modal>

      {/* //////////Reject////////// */}

      <Modal opened={opened5} centered onClose={close5}>
        <Center style={{ alignContent: "center", alignItems: "center" }}>
          <div
            className={`${styles.videocontainerReject} ${styles.videocontainerShow}`}
          >
            <ReactPlayer
              url={userData?.videoResumeVirtualPath}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </Center>

        <div>
          {myVideoResumevideos?.videoPlayback == 0 ? (
            <div>
              <Center>
                {myVideoResumevideos != null && (
                  <Text
                    style={{
                      justifyContent: "center",
                      alignSelf: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Trans>Feedback Video</Trans>
                  </Text>
                )}
              </Center>
              <Text>
                <Trans>
                  The video file failed to open. Please try uploading the video
                  again.
                </Trans>
              </Text>
            </div>
          ) : (
            <div>
              {myVideoResumevideos?.durationType == 0 ? (
                <div>
                  <Center>
                    {myVideoResumevideos != null && (
                      <Text
                        style={{
                          justifyContent: "center",
                          alignSelf: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <Trans>Feedback Video</Trans>
                      </Text>
                    )}
                  </Center>
                  <Text>
                    <Trans>
                      Your video is too short. Aim for 10 to 60 seconds to cover
                      your introduction, skills, and experience effectively.
                    </Trans>
                  </Text>
                </div>
              ) : (
                <div>
                  {myVideoResumevideos?.durationType == 2 ? (
                    <div>
                      <Center>
                        {myVideoResumevideos != null && (
                          <Text
                            style={{
                              justifyContent: "center",
                              alignSelf: "center",
                              fontWeight: "bold",
                            }}
                          >
                            <Trans>Feedback Video</Trans>
                          </Text>
                        )}
                      </Center>
                      <Text>
                        <Trans>
                          Your video is too long. Aim for 10 to 60 seconds to
                          cover your introduction, skills, and experience
                          effectively.
                        </Trans>
                      </Text>
                    </div>
                  ) : (
                    <div>
                      {myVideoResumevideos?.faceDetection == 0 ||
                      myVideoResumevideos?.faceDetection == null ? (
                        <div>
                          <Center>
                            {myVideoResumevideos != null && (
                              <Text
                                style={{
                                  justifyContent: "center",
                                  alignSelf: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                <Trans>Feedback Video</Trans>
                              </Text>
                            )}
                          </Center>
                          <Text>
                            <Trans>
                              We couldn't detect your face in the video. For a
                              better introduction, make sure your face is
                              clearly visible throughout the video.
                            </Trans>
                          </Text>
                        </div>
                      ) : (
                        <div>
                          {myVideoResumevideos?.audioDetected == 0 ||
                          myVideoResumevideos?.audioDetected == null ? (
                            <div>
                              <Center>
                                {myVideoResumevideos != null && (
                                  <Text
                                    style={{
                                      justifyContent: "center",
                                      alignSelf: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Trans>Feedback Video</Trans>
                                  </Text>
                                )}
                              </Center>
                              <Text>
                                <Trans>
                                  The audio seems to be missing from this video.
                                  Please upload a different one.
                                </Trans>
                              </Text>
                            </div>
                          ) : (
                            <div>
                              {myVideoResumevideos?.recognitionResults?.AGE ==
                                null ||
                              myVideoResumevideos?.recognitionResults
                                ?.EDUCATION == null ||
                              myVideoResumevideos?.recognitionResults
                                ?.LOCATION == null ||
                              myVideoResumevideos?.recognitionResults?.TRADE ==
                                null ||
                              myVideoResumevideos?.recognitionResults?.NAME ==
                                null ? (
                                <Center
                                  style={{
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <Text fw={600} ta="center" size="md">
                                      <Trans>
                                        You have not specified the fields given
                                        below in this video resume, so create
                                        the video resume again.
                                      </Trans>
                                      {/* आपने इस वीडियो रिज्यूमे में, निचे दिए गए फील्ड को नहीं बताया है, इसलिए दुबारा वीडियो रिज्यूमे बनाये. */}
                                    </Text>
                                  </div>
                                </Center>
                              ) : (
                                <div></div>
                              )}

                              {myVideoResumevideos?.recognitionResults ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {myVideoResumevideos?.recognitionResults
                                    ?.NAME == null ? (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "red" }}
                                    >
                                      Name :
                                    </Text>
                                  ) : (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "green" }}
                                    >
                                      Name :{" "}
                                      {
                                        myVideoResumevideos?.recognitionResults
                                          ?.NAME
                                      }
                                      ,
                                    </Text>
                                  )}
                                  {myVideoResumevideos?.recognitionResults
                                    ?.AGE == null ? (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "red" }}
                                    >
                                      Age :
                                    </Text>
                                  ) : (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "green" }}
                                    >
                                      Age :{" "}
                                      {
                                        myVideoResumevideos?.recognitionResults
                                          ?.AGE
                                      }
                                      ,
                                    </Text>
                                  )}
                                  {/* {myVideoResumevideos?.recognitionResults?.COMPANY == null && (
           <Text fw={600} size="md" style={{color:"red"}}>
           Company,
           </Text>)} */}
                                  {myVideoResumevideos?.recognitionResults
                                    ?.LOCATION == null ? (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "red" }}
                                    >
                                      Location :
                                    </Text>
                                  ) : (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "green" }}
                                    >
                                      Location :{" "}
                                      {
                                        myVideoResumevideos?.recognitionResults
                                          ?.LOCATION
                                      }
                                      ,
                                    </Text>
                                  )}
                                  {myVideoResumevideos?.recognitionResults
                                    ?.EDUCATION == null ? (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "red" }}
                                    >
                                      Education :
                                    </Text>
                                  ) : (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "green" }}
                                    >
                                      Education :{" "}
                                      {
                                        myVideoResumevideos?.recognitionResults
                                          ?.EDUCATION
                                      }
                                      ,
                                    </Text>
                                  )}
                                  {/* {myVideoResumevideos?.recognitionResults?.EXPERIENCE == null && (
           <Text fw={600} size="md" style={{color:"red"}}>
           Experience,
           </Text>)} */}

                                  {/* {myVideoResumevideos?.recognitionResults?.PASSING_YEAR == null && (
           <Text fw={600} size="md" style={{color:"red"}}>
           Passing Year,
           </Text>)} */}

                                  {myVideoResumevideos?.recognitionResults
                                    ?.TRADE == null ? (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "red" }}
                                    >
                                      Trade :
                                    </Text>
                                  ) : (
                                    <Text
                                      fw={600}
                                      size="md"
                                      style={{ color: "green" }}
                                    >
                                      Trade :{" "}
                                      {
                                        myVideoResumevideos?.recognitionResults
                                          ?.TRADE
                                      }
                                    </Text>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}

                              {/* {myVideoResumevideos?.transcriptHindiText && (
            <div>
            {(languageOrigin == 'hi') ? (
        <Text>
        {myVideoResumevideos?.transcriptHindiText}
        </Text>
            ) : (
            <Text>
        {myVideoResumevideos?.transcriptEnglishText}
        </Text>
            )
            }
        </div>
        
        )} */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <Center
          style={{
            marginTop: "10px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={openRejectedModel}
            className={styles.upload_video_btn8}
          >
            <Trans>Edit Video Resume</Trans>
          </button>
        </Center>
      </Modal>

      {/* //////////////////Skip Alert/////////////////////// */}

      <Modal
        withCloseButton={true}
        opened={openedAlertEmptySkip}
        onClose={() => setOpenedAlertEmptySkip(false)}
        centered
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0% 100%",
            gridGap: "0px",
          }}
        >
          <div style={{ textAlign: "center" }}></div>
          <Text
            style={{ color: "#1F7A8C", textAlign: "center" }}
            fw={500}
            size="lg"
            mb="md"
          >
            Are you sure you want to cancel it?
          </Text>
        </div>

        <Stack align="center">
          <Group position="center" mt="md">
            <Button
              style={{
                marginTop: "0px",
                backgroundColor: "red",
                width: "90px",
              }}
              variant="filled"
              onClick={closeSkipTest}
            >
              <Text c="white" size="md" mb={0} mt={0} fw={500}>
                <Trans>Yes</Trans>
              </Text>
            </Button>
            <Button
              style={{
                marginTop: "0px",
                backgroundColor: "green",
                width: "90px",
              }}
              variant="filled"
              onClick={closeNoSkipTest}
            >
              <Text c="white" size="md" mb={0} mt={0} fw={500}>
                <Trans>No</Trans>
              </Text>
            </Button>
          </Group>
        </Stack>
      </Modal>

      {/* //////////Accepted////////// */}

      <Modal opened={opened6} centered onClose={close6}>
        <Center style={{ alignContent: "center", alignItems: "center" }}>
          <div
            className={`${styles.videocontainer} ${styles.videocontainerShow}`}
          >
            <ReactPlayer
              url={userData?.videoResumeVirtualPath}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </Center>

        <Center
          style={{
            marginTop: "30px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={openAcceptedModel}
            className={styles.upload_video_btn8}
          >
            <Trans>Edit Video Resume</Trans>
          </button>
        </Center>
      </Modal>

      {/* /////////////////// */}

      <Modal opened={opened2} centered onClose={close2}>
        {/* <Button color='purple'>
                        Select Image From Gallary
                        <Input type='file' style={{ opacity: "0", backgroundColor: "white" }} />
                    </Button> */}
        <Center>
          {!selectedPhoto && (
            <Button
              style={{ width: "100%", marginLeft: "20px", marginRight: "20px" }}
              mb={"10px"}
              color="purple"
              onClick={open}
            >
              <FaCamera style={{ marginRight: "10px" }} />
              <Trans>Upload image from Camera</Trans>
            </Button>
          )}
        </Center>
        <Center>
          {!selectedPhoto && (
            <label
              style={{
                width: "100%",
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "15px",
                fontWeight: "500",
              }}
              className={styles.upload_image_btn}
            >
              <input
                style={{ opacity: "0", width: "5%" }}
                type="file"
                onChange={handleFileChange}
              ></input>
              <FaImage style={{ marginRight: "10px" }} />
              <Trans>Upload from Gallery</Trans>
            </label>
          )}
          {selectedPhoto && (
            <Button
              ml={"10px"}
              color="purple"
              onClick={uploadPhoto}
              disabled={!selectedPhoto}
            >
              <Trans>Upload Photo</Trans>
            </Button>
          )}
        </Center>
        {/* <Text onClick={open}>Click Photo</Text> */}
      </Modal>

      <Modal opened={opened} onClose={close} fullScreen>
        <div>
          <WebcamImage closeModel={closeModel} />
        </div>
      </Modal>


      <Modal opened={opened3} onClose={close3} fullScreen>
        <div>
          <WebcamProfileResumeVideo handleClick={handleClick} />
        </div>
      </Modal>
      {/* <div>
                <Button onClick={open}>Open modal</Button>
            </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "95%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <RingProgress
            size={100}
            sections={[{ value: profileScore, color: "blue" }]}
            label={
              <Text c="blue" fw={700} ta="center" size="md">
                {profileScore ? (
                  profileScore + "%"
                ) : (
                  <Loader style={{ marginTop: "6px" }} size={20} />
                )}
              </Text>
            }
          />
          <Text fw={600} ta="center" size="md">
            <Trans>Profile Score</Trans>
          </Text>
        </div>

        {/* <div>
                    <Button style={buttonStyles} 
                   // onClick={handleDownload}
                     ><Trans>View Your Resume</Trans></Button>
                    <a
                    // onClick={(e) => getTextResume(e)} 
                    href={userData?.textResumeVirtualPath}
                    download="Resume"
                    // ref={linkRef} 
                    style={{ display: 'none' }} 
                    target='__blank' />
                </div> */}

        {/* //////////Text Resume////////// */}

        <Button style={buttonStyles}>
          <a
            onClick={(e) => getTextResume(e)}
            href={userData?.textResumeVirtualPath}
            download="Resume"
            target="__blank"
            style={{ color: "black", textDecoration: "none" }}
          >
            <div style={{ color: "black" }}>
              <Trans>View Your Resume</Trans>
            </div>
          </a>
        </Button>

        {/* ////////////////////// */}
      </div>

      {/*SALARY PREDICTOR*/}
      <div style={{ width: "100%", padding: "8px" }}>
        <Button
          variant="filled"
          onClick={() => {
            navigate("/salarymeter");
          }}
          radius="md"
          style={{ width: "100%" }}
        >
          <SiBookmeter style={{ marginRight: "8px" }} />
          <Trans>Salary Meter</Trans>
        </Button>
      </div>

      <div className={styles.container_basicDetails}>
        <div className={styles.container_heading}>
          <Title size={"h5"}>
            <Trans>Basic Details</Trans> :
          </Title>
          <Link to={"/editprofile"}>
            <MdEdit cursor={"pointer"} />
          </Link>
        </div>
        {/* <div className={styles.line}></div> */}
        <hr />
        <Text size="sm" mb={5} mt={5} fw={600}>
          <IoPerson /> <Trans>Name</Trans> : <span>{userData?.name}</span>
        </Text>

        <Text size="sm" mb={5} mt={5} fw={600}>
          <MdEmail /> <Trans>Email</Trans> : <span>{userData?.email}</span>
        </Text>
          
        <Text size="sm" mb={5} mt={5} fw={600}>
          <FaCheck style={{position:"relative" , top:"2px"}}/> <Trans>Email Verification Status</Trans> :  {userData?.emailVerified ?  <span style={{color:"green"}}>Verified</span> : <Button size="xs" onClick={handleVerifyEmail}> <Trans>Verify Now</Trans> </Button>}
        </Text>

        <Text size="sm" mb={5} mt={5} fw={600}>
          <MdPhone /> <Trans>Phone</Trans> : <span>{userData?.mobileNo}</span>
        </Text>

        <Text size="sm" mb={5} mt={5} fw={600}>
          <FaCheck style={{position:"relative" , top:"2px"}}/> <Trans>Phone Verification Status</Trans> :  {userData?.mobileNoVerified ?  <span style={{color:"green"}}>Verified</span> : <Button size="xs" onClick={handleMobileVerify}> <Trans>Verify Now</Trans> </Button>}
        </Text>


        <Text size="sm" mb={5} mt={5} fw={600}>
          <TbGenderAndrogyne /> <Trans>Gender</Trans> :{" "}
          <span>{userData?.gender}</span>
        </Text>
        <Text size="sm" mb={5} mt={5} fw={600}>
          <RiGraduationCapFill /> <Trans>Education</Trans> :{" "}
          <span>{userData?.education?.masterName}</span>
        </Text>
        <Text size="sm" mb={5} mt={5} fw={600}>
          <HiOutlineWrenchScrewdriver /> <Trans>Trade/Job Category</Trans> :{" "}
          <span>{userData?.trade?.masterName}</span>
        </Text>
        {userData?.experience?.masterName &&
        <Text size="sm" mb={5} mt={5} fw={600}>
          <IoBagSharp /> <Trans>Experience</Trans> :{" "}
          <span>{userData?.experience?.masterName}</span>
        </Text>}
        <Text size="sm" mb={5} mt={5} fw={600}>
          <SlCalender /> <Trans>Age</Trans> : <span>{userData?.age}</span>
        </Text>
        <Text size="sm" mb={5} mt={5} fw={600}>
          <SlCalender /> <Trans>Passport</Trans> :{" "}
          <span>{userData?.passport ? "Yes" : "No"}</span>
        </Text>
      </div>

      <div className={styles.container_education}>
        <div className={styles.container_heading}>
          <Title size={"h5"}>
            <Trans>Education</Trans> :
          </Title>
          <Link to={"/education"} className={styles.add_btn}>
            {/* <MdEdit cursor={"pointer"} /> */}
            <MdOutlineAdd fontSize={"1.3rem"} cursor={"pointer"} />
            <Trans>Add</Trans>
          </Link>
        </div>
        {userData?.candidateEducationModel?.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Text size="sm" mb={5} mt={5} fw={600}>
                {item.education?.masterName}
                <br />
                <span>{item?.collegeInstitute?.masterName}</span>
                <br />
                <span>{item?.trade?.masterName}</span>
                <br />
                <span>{item?.passingYear}</span>
                <br />

                {/* <span>Full Time, Noida</span> */}
                <hr />
              </Text>
              <Link to={`/editeducation?id=${item?.id}`}>
                <MdEdit cursor={"pointer"} />
              </Link>
              {item?.length > 1 ? <hr /> : null}
            </div>
          );
        })}
      </div>

      <div className={styles.container_education}>
        <div className={styles.container_heading}>
          <Title size={"h5"}>
            <Trans>Experience</Trans> :
          </Title>
          <Link to={"/experience"} className={styles.add_btn}>
            {/* <MdEdit cursor={"pointer"} /> */}
            <MdOutlineAdd fontSize={"1.3rem"} cursor={"pointer"} />{" "}
            <Trans>Add</Trans>
          </Link>
          {/* <Link to={"/basicprofile"}>
                        <MdEdit cursor={"pointer"} />
                    </Link> */}
        </div>

        {userData?.candidateExperienceModel?.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Text size="sm" mb={5} mt={5} fw={600}>
                {item?.trade?.masterName} <br />
                <span>{item?.companyName}</span> <br />
                <span>{item?.experience?.masterName}</span>
                <br />
                <span>{item?.typeOfWork?.masterName}</span>
                <br />
                <span>{item?.location?.masterName}</span>
                <hr />
              </Text>
              <Link to={`/editexperience?id=${item?.id}`}>
                <MdEdit cursor={"pointer"} />
              </Link>
              {item?.length > 1 ? <hr /> : null}
            </div>
          );
        })}
      </div>

      <div className={styles.container_otherDetails}>
        <div className={styles.container_heading}>
          <Title size={"h5"}>
            <Trans>Other Details</Trans> :
          </Title>
          <Link to={"/otherdetails"}>
            <MdEdit cursor={"pointer"} />
          </Link>
        </div>
        <Text size="sm" mb={5} mt={5} fw={600}>
          <MdOutlineCurrencyRupee /> <Trans>Current Salary</Trans> :{" "}
          <span>{userData?.currentSalary?.masterName}</span>
        </Text>

        <Text size="sm" mb={5} mt={5} fw={600}>
          <MdOutlineCurrencyRupee />
          <Trans>Expected Salary </Trans>:
          <span>{userData?.expectedSalary?.masterName}</span>
        </Text>
        <Text size="sm" mb={5} mt={5} fw={600}>
          <IoLocation /> <Trans>Location</Trans> :{" "}
          <span>{userData?.location?.masterName}</span>
        </Text>

        <Text size="sm" mb={5} mt={5} fw={600}>
          <MdDirectionsBike /> <Trans>Bike/Driving</Trans> :{" "}
          <span>{userData?.bikeAndDl}</span>
        </Text>
      </div>
    </div>
  );
};

export default MyProfile;
