import { useState, useRef, useEffect } from 'react';
import { Combobox , TextInput , useCombobox } from '@mantine/core';
import classes from '../styles/Autocomplete.module.css'
import { FaSearch } from 'react-icons/fa'
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useSearchSuggestionsQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLingui } from '@lingui/react';





function AutocompleteLoading() {
  const combobox = useCombobox()
  const [value, setValue] = useState('');
  const [data, setData] = useState([]);
  const token = useSelector((state) => state?.user?.token)

  const { i18n } = useLingui();
  const timeoutRef = useRef(-1);
   
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()




 
  const shouldFilterOptions = !data.some((item) => item === value);
  const filteredOptions = shouldFilterOptions
    ? data.filter((item) => item.toLowerCase().includes(value.toLowerCase().trim()))
    : data;


    
  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item} key={item}>
      {item}
    </Combobox.Option>
  ));




  const { data: searchSuggestion, isLoading: searchSuggestionLoading, isError: searchSuggestionError, isFetching: searchSuggestionFetching, refetch: searchSuggestionRefetch } = useSearchSuggestionsQuery({
    value,
    skip: !token && !value
  })


  useEffect(() => {
    if (value) {
      searchSuggestionRefetch()
    }
  }, [value])



  const handleChange = (val) => {

    window.clearTimeout(timeoutRef.current);
    setValue(val);
    setData([]);
    if (value.length > 2) {
      setData(searchSuggestion)
    }
    else if (val.trim().length === 0) {
      setLoading(false);
    }
    //  else {
    //   setLoading(true);
    //   timeoutRef.current = window.setTimeout(() => {
    //     setLoading(false);
    //   }, 1000);
    // }


  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };



  const handleSearch = () => {
    navigate(`/jobsearchresult?searchtext=${value}`)
  }


  const handleOptionSubmit = (optionValue)=>{
    navigate(`/jobsearchresult?searchtext=${optionValue}`)
    setData([]);
  }
    
  return (
 
    <div className={classes.container}>

    <Combobox
      onOptionSubmit={(optionValue) => {
        handleOptionSubmit(optionValue)
        setValue(optionValue);
        combobox.closeDropdown();
      }}
      store={combobox}
      withinPortal={false}
    >
      <Combobox.Target>
        <TextInput
          key={i18n.locale}
          placeholder={t`Search Jobs by Trade, Location, Company`}
          value={value}
          onChange={(event) => {
            handleChange(event.target.value)
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          className={classes.input}
          styles={{
            input: {
              height:"100%"
            },
            wrapper:{
              height:"100%"
            }
          }}
          onKeyDown={handleKeyPress}
        />
      </Combobox.Target>

        {options.length > 0 && (
          <Combobox.Dropdown style={{zIndex:"1" , maxHeight: 200, overflowY: 'auto'}}>
            <Combobox.Options>
              {options}
            </Combobox.Options>
          </Combobox.Dropdown>
        )}

    </Combobox>


    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <button onClick={handleSearch} className={classes.search_btn}>
        <FaSearch style={{ color: "#fff" }} />
      </button>
    </div>
    </div>
  );
}

export default AutocompleteLoading;
