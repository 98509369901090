import { Text, Card, RingProgress, Group, useMantineTheme, Flex, Stack,Badge,Button } from '@mantine/core';
import classes from '../styles/BestJobCard.module.css';
import { MdGroups } from "react-icons/md";
import { IoHammerOutline } from "react-icons/io5";
import { FaHardHat } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import {useNavigate} from 'react-router-dom'
import { Trans } from '@lingui/macro';
import { FaBookOpenReader } from "react-icons/fa6";

 function StatsRingCard({jobTitle,trade, companyName,workType, education, location, salary,openings,jobDate, state, id,logo}) {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const handleRedirect = (jid)=>{
     navigate(`/jobdescription?jid=${jid}`)     
  }

  return (
    <Card radius="md" className={classes.card} onClick={()=>{handleRedirect(id)}} style={{boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", marginBottom:"8px", marginLeft:"4px", marginTop:"2px"}}>
      <div>
        <Flex>
          <Flex align="start" gap="sm">
          {logo && (
                <img src={logo} style={{width:"40px", height:"40px"}}/>
          )}
          <Flex direction="column">
          <div style={{fontWeight:700, fontSize:"14px"}}>{jobTitle}</div>
          <div style={{fontSize:"12px", color:"gray", fontWeight:600}}>{companyName}</div>
          </Flex>
          </Flex>
        </Flex>
        <Flex gap="md" style={{marginTop:"12px"}}>
        <Badge variant="light" color="blue" size="xs" radius="sm">{workType}</Badge>
        <Badge variant="light" color="blue" size="xs" radius="sm">{education}</Badge>
        </Flex>
        <Flex direction="column" style={{marginTop:"12px",gap:"2px"}}>
          <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}><FaLocationDot style={{marginTop:"2px",marginLeft:"2px"}} />{location} {(state) ? `(${state})` : ""}</Flex>
          <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}><FaHardHat style={{marginTop:"2px",marginLeft:"2px"}} />{trade}</Flex>
          <Flex align="start" gap="xs" style={{fontSize:"12px",fontWeight:500,color:"#454545"}}><MdGroups size={18} />{openings} <Trans>Openings</Trans></Flex>
        </Flex>
        <Group justify="space-between" style={{marginTop:"12px", borderTop:"gray 1px solid", paddingTop:"8px"}}>
         <div style={{fontSize:"12px", fontWeight:"bold"}}>{salary}</div>

        <Button variant="filled" color="#0180ff" size="xs"><Trans>Apply Now</Trans></Button>

        </Group>
        </div>
      </Card>
  );
}

export default StatsRingCard
