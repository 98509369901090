import React,{useEffect, useState, useRef} from 'react'
import {Carousel} from '@mantine/carousel'
import Autoplay from 'embla-carousel-autoplay'
import classes from '../styles/HomeTopBanner.module.css'
import { useGetHomeTopBannerQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom'


// const images = [
  
//   "onboardimages/banner.png",
//  ];

const HomeTopBanner = () => {

  const token = useSelector((state) => state?.user?.token)
  const navigate = useNavigate();

  const { data: bannerData, isLoading: bannerLoading, isError: bannerError, isFetching: bannerFetching, refetch: bannerRefetch } = useGetHomeTopBannerQuery({
    skip: !token
})

//console.log("bbbbbbaannerrr", bannerData)

useEffect(() => {
  if (token) {
    bannerRefetch()
  }
}, [token, bannerRefetch]);

   const autoplay = useRef(Autoplay({delay:4000}))


   const bannerLink = (url)=>{

    //console.log(url?.bannerLink, "checkkkkkkkkkkkk")
    navigate(url?.bannerLink)
    // alert("abcd")

    //window.open(url?.bannerLink);    
 }


  const slides = bannerData?.map((url) => (
    <Carousel.Slide key={url} >
     {(url?.active === true) && (
      <img onClick={()=>{bannerLink(url)}} src={url?.bannerImageVirtualPath} width="97%" height="100%" style={{borderRadius:"10px", marginLeft:"6px"}}/>
    )}
      {/* <img onClick={()=>{bannerLink(url)}} src={url?.bannerImageVirtualPath} width="100%" height="100%" style={{borderRadius:"10px"}}/> */}
    </Carousel.Slide>
  ));

  

   


  return (
    <>
    {bannerData?.length > 0 && (
    <div style={{paddingTop: "10px"}} className={classes.wrapper}>

     <Carousel styles={
      {
        container : {transform:"none"},
        slide:{height:"150px"},
        controls:{display:"none"},
        // indicator:{backgroundColor:"#0180ff"},
        // indicators:{marginTop:"3rem"} 
      }
     }
     plugins={[autoplay.current]}
     loop
    //  onMouseEnter={autoplay.current.stop}
    //  onMouseLeave={autoplay.current.reset}
     >{slides}</Carousel>
    </div>)}
    </>
  )
}

export default HomeTopBanner