import React, {useState, useEffect} from 'react'
import classes from '../styles/BottomNavigation.module.css'
import { Button } from '@mantine/core'
import HomeLogo from '../assets/logos/Home.png'
import SearchLogo from '../assets/logos/Search.png'
import PersonLogo from '../assets/logos/Person.png'


import { Link, useLocation } from 'react-router-dom'


import { Trans } from '@lingui/macro'
import Homelink from '../assets/logos/homelink.png'
import ActiveHomelink from '../assets/logos/activehomelink.png'
import ActiveJobLink from '../assets/logos/activejoblink.png'
import JobLink from '../assets/logos/joblink.png'
import ActiveProfileLink from '../assets/logos/activeprofilelink.png'
import ProfileLink from '../assets/logos/profilelink.png'


import ActivePlayLogo from '../assets/logos/play.png'
import PlayLogo from '../assets/logos/blackplay.png'

import ActiveCareerLogo from '../assets/logos/career.png'
import CareerLogo from '../assets/logos/blackcareerlogo.png'




const BottomNavigation = () => {
    
   const [activeLink , setActiveLink]  = useState('home')
   const location = useLocation();
   const {pathname} = location


    useEffect(() => {
        if(pathname == '/'){
          setActiveLink('home')
        }else if(pathname == '/myprofile'){
          setActiveLink('profile')
        }else if(pathname == '/jobs'){
          setActiveLink('jobs')
        }else if(pathname == '/editprofile'){
          setActiveLink('profile')
        }else if(pathname == '/education'){
          setActiveLink('profile')
        }else if(pathname == '/experience'){
          setActiveLink('profile')
        }else if(pathname == '/otherdetails'){
          setActiveLink('profile')
        }else if(pathname == '/jobdescription') {
          setActiveLink('jobs')
        }else if(pathname == '/play'){
          setActiveLink('play')
        }else if(pathname=='/shraminshala'){
          setActiveLink('shraminshala')
        }else{
          setActiveLink('')
        }
    }, [pathname])
    

    



   const handleLinkClick = (link)=>{
     setActiveLink(link)
   }




  return (
    <div className={classes.container}>
         <div>
              <Link to="/" style={{textDecoration:"none"}} onClick={()=>{handleLinkClick('home')}}>  
               <div style={{textAlign:"center"}}>
               {activeLink == 'home' ?  <img src={ActiveHomelink} style={{height:"20px", width:"20px"}}/>  :   <img src={Homelink} style={{height:"22px", width:"22px"}}/> }
               <span style={{display:"block", fontSize: "13px", color: activeLink == 'home' ?"#0180ff" : "#000"}}><Trans>Home</Trans></span>  
               </div>
              </Link>
         </div>
         <div> 
               <Link to="/jobs?tab=all" style={{textDecoration:"none"}} onClick={()=>{handleLinkClick('jobs')}}>
               <div style={{textAlign:"center"}}>
               {activeLink == 'jobs' ?  <img src={ActiveJobLink} style={{height:"22px", width:"22px"}}/>  :  <img src={JobLink} style={{height:"22px", width:"22px"}}/> }
                 <span style={{display:"block", fontSize: "13px", color: activeLink == 'jobs' ?"#0180ff" : "#000"}}><Trans>Jobs</Trans></span>  
               </div>
               </Link>
         </div>
         
         <div>
               <Link to="/myprofile" style={{textDecoration:"none"}} onClick={()=>{handleLinkClick('profile')}}>
                <div style={{textAlign:"center"}}>
                {activeLink == 'profile' ?  <img src={ActiveProfileLink} style={{height:"22px", width:"22px"}}/>  :  <img src={ProfileLink} style={{height:"22px", width:"22px"}}/> }    
                 <span style={{display:"block", fontSize: "13px", color: activeLink == 'profile' ? "#0180ff" : "#000"}}><Trans>Profile</Trans></span>  
                </div>  
               </Link>
         </div>
        
         <div>
              <Link to="/play" style={{textDecoration:"none"}} onClick={()=>{handleLinkClick('play')}}>
              <div style={{textAlign:"center"}}>
                 {activeLink == 'play' ?  <img src={ActivePlayLogo} style={{height:"22px", width:"22px"}}/>  :  <img src={PlayLogo} style={{height:"22px", width:"22px"}}/> }
                 <span style={{display:"block", fontSize: "13px", color: activeLink == 'play' ? "#0180ff" : "#000"}}><Trans>Play</Trans></span>  
              </div>
            
              </Link>
         </div>

         <div>
         <Link to="/shraminshala" style={{textDecoration:"none"}} onClick={()=>{handleLinkClick('shraminshala')}}>
              <div style={{textAlign:"center"}}>
                 {activeLink == 'shraminshala' ?  <img src={ActiveCareerLogo} style={{height:"22px", width:"22px"}}/>  :  <img src={CareerLogo} style={{height:"22px", width:"22px"}}/> }
                 <span style={{display:"block", fontSize: "13px", color: activeLink == 'shraminshala' ? "#0180ff" : "#000"}}><Trans>Career</Trans></span>  
              </div>
            
         </Link>
        
         </div>
    </div>
  )
}

export default BottomNavigation