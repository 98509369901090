import React, { useEffect, useState } from 'react'
import { Text, Center, Avatar, Button, Notification, Textarea, Card, Group, TypographyStylesProvider, Paper, Loader } from '@mantine/core';
import classes from '../styles/ChatBot.module.css'
import ShramINlogo from '../assets/logos/shraminLogo.png'
import { LuSendHorizonal } from "react-icons/lu";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';


function ChatbotSingleQuestionAnswer() {

  const { user } = UserAuth()
  const candidateId = localStorage.getItem('candidateId')
  const [value, setValue] = useState(null);
  const [chatBotSubmitted, setChatBotSubmitted] = useState(false);
  const [chatBotAfterSubmitted, setChatBotAfterSubmitted] = useState(false);
  const [myChatbotQuestion, setMyChatbotQuestion] = useState(null)
  const [myChatbotAllQuestionAnswer, setMyChatbotAllQuestionAnswer] = useState(null)
  const [myChatbotMainAnswer, setMyChatbotMainAnswer] = useState(null)
  const [myChatbotQuestionAnswer, setMyChatbotQuestionAnswer] = useState(null)
  const [myChatbotAllQuestion, setMyChatbotAllQuestion] = useState(null)
  const [showAlertSubmit, setShowAlertSubmit] = useState(false)
  const [showAlerttoLong, setShowAlerttoLong] = useState(false)
  const [candidateChatBotIdData, setCandidateChatBotIdData] = useState(null)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemData = searchParams.get('item')
  const navigate = useNavigate();

  const { i18n } = useLingui();

  //console.log(itemData,"iiiiiiqqqqqq")

  useEffect(() => {
    if (user) {
      if (itemData) {
        // console.log(itemData,"ppppprrrrrrrr")
        fetchMyChatbotSingleQuestionAswerData(itemData)
        fetchMyChatbotMainAswerData(itemData)
        fetchMyChatbotAllQuestionAnswerData(itemData)
      }
    }
  }, [user, itemData, chatBotAfterSubmitted])


  const scrollToTop = () => {
    window.scrollTo({
      // top: 0,
      top: document.documentElement.scrollHeight,
      behavior: 'smooth' // Smooth scrolling behavior
    });
  };


  const fetchMyChatbotAllQuestionAnswerData = async (itemData) => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/chatbot/';
    try {

      const response = await fetch(`${url}${itemData}/parentChat`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotAllQuestionAnswerData');
      }
      const chatbotAllQuestionAnswerdata = await response.json();
      //setValue(null)
      // console.log("ChatbotAllQuestionAnswermypvideo++--**//", chatbotAllQuestionAnswerdata)
      setMyChatbotAllQuestionAnswer(chatbotAllQuestionAnswerdata)

    } catch (error) {
      console.error('Error fetching ChatbotAllQuestionAnswerData :', error);
    }
  };

  ///////////////////////////Main/////////////////

  const fetchMyChatbotMainAswerData = async (itemData) => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/chatbot/mainAnswer/';
    try {

      const response = await fetch(`${url}${itemData}/parentChat`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotMainAnswerData');
      }
      const chatbotMainAnswerdata = await response.json();
      //setValue(null)
       //console.log("mainwwwwwwwwwww++--**//", chatbotMainAnswerdata)
      setMyChatbotMainAnswer(chatbotMainAnswerdata)

    } catch (error) {
      console.error('Error fetching ChatbotMainAnswerData :', error);
    }
  };

  ////////////////////////////////////////////////////


  const fetchMyChatbotSingleQuestionAswerData = async (itemData) => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/chatbot/';
    try {

      const response = await fetch(`${url}${itemData}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotSingleQuestionAswerData');
      }
      const chatbotSingleQuestionAswerdata = await response.json();
      // console.log("ChatbotSingleQuestionAswermypvideo***", chatbotSingleQuestionAswerdata)
      setMyChatbotQuestionAnswer(chatbotSingleQuestionAswerdata)

    } catch (error) {
      console.error('Error fetching ChatbotSingleQuestionAswerData :', error);
    }
  };



  const fetchMyChatbotQuestionData = async (itemData) => {
    const url = import.meta.env.VITE_BASE_URL + '/chatbot/candidate/';
    try {

      const obj = {};
      obj.message = value
      obj.parentChatId = itemData

      const response = await fetch(`${url}${candidateId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ChatbotQuestionData');
      }
      const chatbotQuestiondata = await response.json();
      // setChatBotSubmitted(true)
      setValue('')
      fetchMyChatbotAllQuestionAnswerData(itemData)
      setShowAlertSubmit(false)
      setChatBotAfterSubmitted(true)
      // console.log("ChatbotQuestion---", chatbotQuestiondata)
      if (chatbotQuestiondata?.message == 0) {
        setShowAlerttoLong(true)
      }
      setMyChatbotQuestion(chatbotQuestiondata)
      setCandidateChatBotIdData(chatbotQuestiondata?.candidateChatBotId)

    } catch (error) {
      console.error('Error fetching ChatbotQuestion :', error);
    }
  };


  const arrowBack = () => {
    navigate(`/chatbot`)
  }

  const submitChatBotAns = (itemData) => {
    // console.log(itemData,"kkkkllllllllllzzz")
    // setChatBotSubmitted(true)
    // fetchMyChatbotAllQuestionData()
    if (value != null) {
      scrollToTop()
      fetchMyChatbotQuestionData(itemData)
      //setValue('')
    } else {
      setShowAlertSubmit(true)
      // console.log("Please write a question")
    }
  }







  return (
    <div>

      {showAlertSubmit && (
        <Notification
          color='#eee'
          onClose={() => setShowAlertSubmit(false)}
          style={{
            backgroundColor: "#2aa63d",
            color: "#eee",
            position: "fixed",
            width: "100%",
            maxWidth: "600px",
            zIndex: 9999,
            top: 60
          }}
        >
          <span style={{ color: "#eee", fontSize: "1rem" }}>
            <Trans>Please write a question</Trans>
          </span>
        </Notification>
      )}

      {showAlerttoLong && (
        <Notification
          color='#eee'
          onClose={() => setShowAlerttoLong(false)}
          style={{
            backgroundColor: "#ff0000",
            color: "#eee",
            position: "fixed",
            width: "100%",
            maxWidth: "600px",
            zIndex: 9999,
            top: 60
          }}
        >
          <span style={{ color: "#eee", fontSize: "1rem" }}>
            <Trans>Question is too long. Please make it shorter to proceed.</Trans>
          </span>
        </Notification>
      )}

      <div>

        <div style={{
          position: "fixed",
          width: "100%",
          height: "8%",
          // borderRadius: "30px",
          maxWidth: "600px",
          top: "55px",
          // background: "white",
          padding: "0px",
          display: "grid",
          alignItems: "center",
          justifyContent: "'center",
          alignContent: "center",
          backgroundColor: "white"
        }}>



          <div>
            <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
          </div>
          <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
            <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
              <Trans>Question/Answer</Trans>
            </Text>
          </div>
        </div>

        {myChatbotQuestionAnswer?.message ? (
<>

        <div style={{ marginTop: "95px" }}>
          <div style={{ paddingTop: "5px" }}>


            <Paper withBorder radius="md" ml={'10px'} mr={'10px'} mt={'30px'} className={classes.comment}>

              <div style={{ marginTop: "5px" }}>
                <Text style={{ fontWeight: "600", fontSize: "15px" }} c="dimmed">
                  {myChatbotQuestionAnswer?.message}
                </Text>
              </div>
            </Paper>
          </div>
        </div>

       
            {myChatbotMainAnswer != null ? (
              <div style={{ marginTop: "10px" }}>
                  <Paper withBorder style={{ backgroundColor: "#ebf7fb" }} radius="md" ml={'10px'} mr={'10px'} mt={'10px'} className={classes.comment}>
                    <div style={{ marginTop: "5px" }}>
                      <Text style={{ fontWeight: "400", fontSize: "15px" }} c="dimmed">
                        {myChatbotMainAnswer?.message}
                      </Text>
                    </div>
                  </Paper>
                </div>
            ) : (
              <div style={{ marginTop: "10px" }}>
                  <Paper withBorder style={{ backgroundColor: "#ebf7fb" }} radius="md" ml={'10px'} mr={'10px'} mt={'10px'} className={classes.comment}>
                    <div style={{ marginTop: "5px" }}>
                      <Text style={{ fontWeight: "400", fontSize: "15px" }} c="dimmed">
                        <Trans>Our career guidance experts are currently reviewing your question and will get back to you as soon as possible</Trans>
                      </Text>
                    </div>
                  </Paper>
                </div>
            )}
              

        <div style={{ marginTop: "10px", backgroundColor: "white" }}>
          {myChatbotAllQuestionAnswer?.map((item) => {
            return <div>{item?.candidateId ? (
              <Paper withBorder radius="md" ml={'50px'} mr={'10px'} mt={'10px'} className={classes.comment}>
                {/* <Group>
        <img src={item?.photoVirtualPath} style={{ objectFit: "cover", height: "12%", width: "12%", borderRadius: "50%", marginTop: "0rem" }} alt="" />
        <div>
          <Text fz="sm">Rishav Kumar</Text>
          <Text fz="xs" c="dimmed">
            {item?.createdAt}
          </Text>
        </div>
      </Group> */}
                <div style={{ marginTop: "0px" }}>
                  <Text style={{ fontWeight: "400", fontSize: "15px" }} c="dimmed">
                    {/* Question:- {item?.message} */}
                    {item?.message}
                  </Text>
                </div>
              </Paper>
            ) : (

              <Paper style={{ backgroundColor: "#ebf7fb" }} withBorder radius="md" ml={'10px'} mr={'50px'} mt={'10px'} className={classes.comment}>
                {/* <Group>
        <img src={item?.photoVirtualPath} style={{ objectFit: "cover", height: "12%", width: "12%", borderRadius: "50%", marginTop: "0rem" }} alt="" />
        <div>
          <Text fz="sm">ShramIN Admin</Text>
          <Text fz="xs" c="dimmed">
            {item?.createdAt}
          </Text>
        </div>
      </Group> */}
                <div style={{ marginTop: "0px" }}>
                  <Text style={{ fontWeight: "400", fontSize: "15px" }} c="dimmed">
                    {/* Answer:- {item?.message} */}
                    {item?.message}
                  </Text>
                </div>
              </Paper>
            )}
            </div>
          }
          )}

          <div style={{ marginTop: "280px" }}>
            .
          </div>



        </div>

</>

) : (
  <div>
  <Center>
            <Loader style={{ marginTop: "220px" }} color="#034078" size={30} />
          </Center>
  </div>
          
          )}

        <div style={{
          position: "fixed",
          width: "100%",
          height: "25%",
          // borderRadius: "30px",
          maxWidth: "600px",
          bottom: "63px",
          background: "white",
          padding: "0px",
          display: "grid",
          alignItems: "center",
          justifyContent: "'center",
          alignContent: "center",
        }}>

          <div>

            <div>
              <Text style={{
                marginLeft: "10px", fontSize: "14px", color: "rgb(151,156,160)"
              }}>
                <Trans>Maximum input length is 255 characters.</Trans>
              </Text>
            </div>


            <Paper radius="md" ml={'5px'} mr={'20px'} mt={'10px'}>
              <div style={{ display: "flex", flexDirection: "row" }}>

                <Textarea
                  key={i18n.locale}
                  style={{ width: "94%" }}
                  value={value}
                  placeholder={t`Please write your question`}
                  // label="Autosize with no rows limit"
                  autosize
                  minRows={4}
                  maxRows={4}
                  onChange={(event) => { setValue(event.currentTarget.value) }}
                />
                <LuSendHorizonal onClick={() => { submitChatBotAns(itemData) }} color='#228BE6' size={26} style={{ marginLeft: "10px", marginTop: "50px" }} />
              </div>
            </Paper>

          </div>

        </div>
      </div>


    </div>
  )
}

export default ChatbotSingleQuestionAnswer