import { useQuery, keepPreviousData } from "@tanstack/react-query";

export function useGetAllNotification(candidateId, token) {

  const url = import.meta.env.VITE_BASE_URL + '/notifications/candidate/';

  const query = useQuery({
    queryKey: ["allNotification"],
    queryFn: async () => 
       // fetch('https://shramin-stag.azurewebsites.net/notifications/candidate/8a4eab65-6749-4725-ad36-c11f14a662b9', {
        fetch(`${url}${candidateId}`, {
            headers: {
               'Content-Type': 'application/json',
               "Authorization": `Bearer ${token}`,
               "Accept": 'application/json'
            },
          }).then((res) => {
            if (!res.ok) {
              throw new Error('Network response was not ok');
            }
           // console.log("datakkkkk4", res)
            return res.json();
           
          }),

    //   return await response.data;
    
    //placeholderData: keepPreviousData,
  });
  return query;

  ////////////
}