import React, { useState , useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import {  Modal } from '@mantine/core';
import classes from '../styles/FeatureJobModal.module.css'
import FeatuereJobCard from './FeatureJobCard'
import { IoMdClose } from "react-icons/io";
import {useGetPrivateJobQuery} from '../redux/api/apiSlice'
import {useSelector} from 'react-redux'
import { Trans } from '@lingui/macro';


const FeatureJobModal = () => {
     const [opened , setopened] = useState(false)
     const featuredjobremainingtime =   localStorage.getItem("featuredJobModalLastShown")
     const token = useSelector((state) => state?.user.token)
     const candidateId = localStorage.getItem('candidateId')
     const candidateData = useSelector((state) => state?.user.candidateData)
     const candidateCreatedAt = candidateData?.createdAt
     const [featuredJobs, setFeaturedJobs] = useState([])
     const [singlefeaturedjob, setrandomfeaturedjob] = useState(null)

    // console.log(singlefeaturedjob,"singlefeaturedjob")



     const { data: jobdata, isLoading, isError, isFetching, refetch } = useGetPrivateJobQuery(candidateId,{
        skip: !token
    })

  
    useEffect(() => {
        if (token && candidateId) {
                 refetch();          
        }
    }, [token, refetch]);


    useEffect(() => {

        if (jobdata) {
            var allJobs =  jobdata?.content

            var featuredjobs = allJobs.filter(job=>{
                return(
                    job?.featureJob === true
                )
            })
  
            if(featuredjobs.length > 0){
                if(featuredjobs.length == 1){
                    setrandomfeaturedjob(featuredjobs[0])
                }else{
                    const randomIndex = Math.floor(Math.random() * featuredjobs.length)
                    setrandomfeaturedjob(featuredjobs[randomIndex])
                }
            }


            setFeaturedJobs(featuredjobs)
        }
    }, [jobdata]);






     useEffect(() => {
        if(featuredjobremainingtime){
            const lastDeniedDate = new Date(featuredjobremainingtime);
            const currentDate = new Date();
            const timeDiff = currentDate - lastDeniedDate;
            const hoursDiff = timeDiff / (1000 * 60 * 60);
            if(hoursDiff > 24){
                setopened(true)
            }
        }else{
            const givenDate = new Date(candidateCreatedAt);
            const currentDate = new Date();
            const timeDifference = currentDate - givenDate;
            const minutesDifference = Math.floor(timeDifference / (1000 * 60));
            
            if(minutesDifference >= 5){
                setopened(true)
            }
        }
       
        
     }, [])
     




     const handleModalClose = ()=>{
        setopened(false)
        localStorage.setItem("featuredJobModalLastShown", new Date().toString())
     }

   
   


    
      
  return (
    <>
    {featuredJobs.length > 0 && (
        <Modal  opened={opened} onClose={handleModalClose} withCloseButton={false} closeOnClickOutside={false} centered padding="10px">
            <div className={classes.modalHeader}>
                   <p style={{textAlign:"center", fontSize:"20px", fontWeight:"500", margin:"5px"}}><Trans>Job of the Day</Trans></p>
                    <IoMdClose className={classes.closeIcon} onClick={handleModalClose}/>
            </div>
             <div style={{width:"100%"}}>
                      <FeatuereJobCard 
                       id={singlefeaturedjob?.id}
                       jobTitle={singlefeaturedjob?.jobTitle}
                       trade={singlefeaturedjob?.trade?.masterName}
                       companyName={singlefeaturedjob?.companyName}
                       workType={singlefeaturedjob?.typeOfWork?.masterName}
                       education={singlefeaturedjob?.education?.masterName}
                       location={singlefeaturedjob?.location?.masterName}
                       state={singlefeaturedjob?.location?.parentModel?.masterName}
                       logo={singlefeaturedjob?.logoVirtualPath ? singlefeaturedjob?.logoVirtualPath : singlefeaturedjob?.employer?.logoVirtualPath  }
                       handleModalClose={handleModalClose}
                      />
                    
            </div>
        </Modal>
    )}
    </>
  )

}

export default FeatureJobModal