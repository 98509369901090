import {
  Container,
  Badge,
  Stack,
  Flex,
  Spoiler,
  Group,
  Center,
  Tabs,
  rem,
  Card,
  Button,
  Text,
} from "@mantine/core";
import { PiAirplaneTilt } from "react-icons/pi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";

import { FaLocationDot } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa6";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { LuGraduationCap } from "react-icons/lu";
import { AreaChart, BarChart } from "@mantine/charts";
import "@mantine/charts/styles.css";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactSpeedometer from "react-d3-speedometer";
import { IoArrowBack } from "react-icons/io5";
import { CiShare2 } from "react-icons/ci";
import Experience from "../Experience";
import { UserAuth } from "../../context/AuthContext";
import { Skeleton } from "@mantine/core";
import { FaGraduationCap } from "react-icons/fa";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { Paper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

function ChartTooltip({ label, payload }) {
  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text fw={500} mb={5}>
        {label}
      </Text>
    </Paper>
  );
}

const LocGraph = ({ arr, isSmallestScreen }) => {
  let datas = [];
  Object.keys(arr).map((key) => {
    if (key == "Dadra and Nagar Haveli and Daman and Diu") {
      datas.push({
        Location: "DND",
        Salary: Math.floor(parseInt(arr[key]) / 100) * 100,
      });
    } else {
      datas.push({
        Location: key,
        Salary: Math.floor(parseInt(arr[key]) / 100) * 100,
      });
    }
  });

  datas.sort((a, b) => b.Salary - a.Salary);

  return (
    <Flex justify="center">
      <BarChart
        h={1200}
        w={isSmallestScreen ? "82%" : "87%"}
        orientation="vertical"
        data={datas}
        dataKey="Location"
        mt="md"
        xAxisProps={{ orientation: "top" }}
        yAxisProps={{ padding: { top: 20, bottom: 15 } }} // Increased top padding
        series={[{ name: "Salary", color: "indigo.6" }]}
        curveType="linear"
        withTooltip={false}
      />
    </Flex>
  );
};

const InterlocGraph = ({ arr, isSmallestScreen }) => {
  if (arr.length == 0) {
    return (
      <Flex justify="center" align="center" h={300}>
        <Card
          padding="lg"
          pb="md"
          m="lg"
          radius="md"
          shadow="sm"
          style={{
            border: "0.2px solid #D2D2D2",
            boxShadow:
              "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
          }}
        >
          <div>Currently no International data available for this job</div>
        </Card>
      </Flex>
    );
  }

  let len = arr.length * 75;
  len = Math.min(600, len);
  len = Math.max(100, len);

  return (
    <Flex justify="center">
      <BarChart
        h={len}
        w={isSmallestScreen ? "82%" : "87%"}
        orientation="vertical"
        data={arr}
        dataKey="location"
        mt="md"
        xAxisProps={{ orientation: "top" }}
        yAxisProps={{ padding: { top: 20, bottom: 15 } }} // Increased top padding
        series={[{ name: "estimatedMedian", color: "violet.7" }]}
        curveType="linear"
        withTooltip={false}
      />
    </Flex>
  );
};

const EdGraph = ({ arr }) => {
  const ed = [
    "8th pass",
    "10th pass",
    "ITI",
    "Diploma",
    "12th Pass",
    "Graduate",
  ];

  const datas = arr.map((val, indx) => {
    return { Education: ed[indx], Salary: parseInt(val) };
  });

  const order = [0, 1, 2, 4, 3, 5];

  // Rearranging the `datas` array
  const rearrangedDatas = order.map((index) => datas[index]);

  return (
    <AreaChart
      h={300}
      data={rearrangedDatas}
      dataKey="Education"
      p="xs"
      mt="md"
      xAxisProps={{ padding: { left: 15, right: 15 } }}
      series={[{ name: "Salary", color: "green.6" }]}
      curveType="linear"
      tooltipProps={{
        content: ({ label, payload }) => (
          <ChartTooltip label={label} payload={payload} />
        ),
      }}
      style={{
        paddingLeft: "0px",
      }}
    />
  );
};

const ExpGraph = ({ arr }) => {
  const exp = ["Fresher", "1-2 Yrs", "2-5 Yrs", "5-10 Yrs", "10+ Yrs"];

  const datas = arr.map((val, indx) => {
    return { Experience: exp[indx], Salary: parseInt(val) };
  });

  return (
    <AreaChart
      h={300}
      data={datas}
      dataKey="Experience"
      p="xs"
      mt="md"
      xAxisProps={{ padding: { left: 15, right: 15 } }}
      series={[{ name: "Salary", color: "orange.6" }]}
      curveType="linear"
      tooltipProps={{
        content: ({ label, payload }) => (
          <ChartTooltip label={label} payload={payload} />
        ),
      }}
      style={{
        paddingLeft: "0px",
      }}
    />
  );
};

const Predictedsalary = () => {
  const { user } = UserAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const job = searchParams.get("job");
  const loc = searchParams.get("loc");
  const ed = searchParams.get("ed");
  const exp = searchParams.get("exp");
  const [data, setData] = useState(null);
  const [iserror, seterror] = useState(false);
  const formatter = new Intl.NumberFormat("en-US");
  const isSmallestScreen = useMediaQuery("(max-width: 420px)");

  // for fetching user token
  useEffect(() => {
    if (user?.accessToken) {
      fetchpred();
    }
  }, [user]);

  // for fetching the prediction
  async function fetchpred() {
    const url = import.meta.env.VITE_BASE_URL + "/candidate/salary-calculator";
    try {
      const response = await fetch(
        `${url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
            Accept: "application/json",
          },
          body: JSON.stringify({
            education: ed,
            state: loc,
            trade: job,
            experience: exp,
            numberOfOpenings: "1",
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        seterror(true);
      } else {
        setData(data);
      }
    } catch (e) {
      seterror(true);
      console.log(e);
    }
  }

  const exparr = [
    "Fresher",
    "1-2 years",
    "2-5 years",
    "5-10 years",
    "10+ years",
  ];

  return (
    <div>
      {data != null ? (
        <Container fluid p="md">
          {/* heading */}
          <Group justify="space-between">
            <IoArrowBack
              onClick={() => {
                navigate("/salarymeter");
              }}
              color="black"
              size={26}
              style={{ cursor: "pointer" }}
            />
            {/* <Button
              variant="outline"
              color="black"
              radius="xl"
              size="xs"
              style={(theme) => ({
                borderWidth: "2px",
                padding: "5px 10px",
                borderColor: "#000000",
              })}
            >
              <Flex style={{ gap: "6px" }}>
                <CiShare2 style={{ color: "#000000" }} strokeWidth="2" />
                <span style={{ fontWeight: "bold", color: "#000000" }}>
                  <span>Share</span>
                </span>
              </Flex>
            </Button> */}
          </Group>

          <Stack gap="8px" mb="sm" mt="xs">
            <span style={{ fontWeight: "bold", fontSize: "28px" }}>{job}</span>
            <Flex gap="sm" wrap="wrap">
              <Badge variant="light" color="blue" size="md" radius="sm">
                <FaLocationDot
                  style={{ color: "#228be6", marginRight: "3px" }}
                />
                {loc}
              </Badge>
              <Badge variant="light" color="blue" size="md" radius="sm">
                <FaBusinessTime
                  size="14px"
                  style={{
                    color: "#228be6",
                    marginRight: "2px",
                    paddingTop: "3px",
                  }}
                />
                {exparr[exp]}
              </Badge>
              <Badge variant="light" color="blue" size="md" radius="sm">
                <FaGraduationCap
                  size="14px"
                  style={{
                    color: "#228be6",
                    marginRight: "3px",
                    paddingTop: "3px",
                  }}
                />
                {ed}
              </Badge>
            </Flex>
          </Stack>
          <Card
            padding="lg"
            pb="md"
            mb="md"
            radius="lg"
            shadow="sm"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            {/* Circular Guage */}
            <Flex justify="center" p="xs">
              <ReactSpeedometer
                width={300}
                height={200}
                maxValue={80}
                minValue={0}
                value={parseInt(parseInt(data.estimatedSalary) / 1000)}
                valueFormat={"d"}
                customSegmentStops={[0, 10, 20, 30, 40, 50, 60, 70, 80]}
                startColor="#C1EBE1"
                endColor="#0AB186"
                needleColor="#000000"
                labelFontSize="12px"
                valueTextFontSize="12px"
                textColor="#696969"
                currentValueText={`Approx Market Salary`}
                needleTransitionDuration={5000}
                needleTransition="easeElastic"
              />
            </Flex>
            {/* price range */}
            <Stack gap="0px" mb="xs">
              <span
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  marginBottom: "4px",
                }}
              >
                <Trans>Base Pay Range</Trans>
              </span>
              <Text fw={700} size={isSmallestScreen ? "24px" : "30px"}>
                {`₹${formatter.format(
                  Math.floor(data.predictedSalaryMin / 100) * 100
                )} -₹${formatter.format(
                  Math.floor(data.predictedSalaryMax / 100) * 100
                )}`}
                <Text span fw={500} c="#696969" size="14px">
                  {" "}
                  /mo
                </Text>
              </Text>
            </Stack>

            <div>
              <span style={{ fontSize: "12px", fontWeight: 600 }}>
                The Average Market Salary of {job} is in the range of ₹
                {formatter.format(
                  Math.floor(data.predictedSalaryMin / 100) * 100
                )}
                -₹
                {formatter.format(
                  Math.floor(data.predictedSalaryMax / 100) * 100
                )}{" "}
                per month
              </span>
            </div>

            <Card
              padding="xs"
              radius="sm"
              mt="md"
              shadow="none"
              style={{
                backgroundColor: "#e8f3fc",
              }}
            >
              <span
                style={{ fontWeight: 600, color: "#228be6", fontSize: "12px" }}
              >
                Minimum wages in {loc} is ₹
                {formatter.format(
                  Math.floor(data.minWageGivenState / 100) * 100
                )}
              </span>
            </Card>
          </Card>

          <Card
            padding="lg"
            radius="lg"
            shadow="sm"
            mt="md"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            <Text fw={700} size="24px">
              <Trans>How you can Earn More?</Trans>
            </Text>
            <span
              style={{ fontSize: "12px", fontWeight: 600, marginTop: "20px" }}
            >
              <Trans>
                You can compare the salary for this job based on the experience
                and Education which is demanded in the market for this specified
                job.
              </Trans>
            </span>

            <Tabs defaultValue="experience" mt="md">
              <Tabs.List>
                <Tabs.Tab
                  value="experience"
                  leftSection={<MdOutlineBusinessCenter />}
                >
                  <Trans>Experience</Trans>
                </Tabs.Tab>
                <Tabs.Tab value="education" leftSection={<LuGraduationCap />}>
                  <Trans>Education</Trans>
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="experience">
                <ExpGraph arr={data.experienceWiseSalary} />
              </Tabs.Panel>
              <Tabs.Panel value="education">
                <EdGraph arr={data.educationWiseSalary} />
              </Tabs.Panel>
            </Tabs>
          </Card>

          <Card
            padding="lg"
            radius="lg"
            shadow="sm"
            mt="md"
            style={{
              border: "0.2px solid #D2D2D2",
              boxShadow:
                "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
            }}
          >
            <Text fw={700} size="24px">
              <Trans>Where you can Earn More?</Trans>
            </Text>
            <span
              style={{ fontSize: "12px", fontWeight: 600, marginTop: "20px" }}
            >
              <Trans>
              You can compare the salary for this job across different states in the country and various locations worldwide.
              </Trans>
            </span>
            <Spoiler
              maxHeight={isSmallestScreen ? 400 : 490}
              showLabel="Read more"
              hideLabel="Read less"
              styles={(theme) => ({
                control: {
                  right: 0,
                  insetInlineStart: "initial",
                  color: "black",
                },
              })}
            >
              <Tabs defaultValue="states" mt="md">
                <Tabs.List>
                  <Tabs.Tab
                    value="states"
                    leftSection={<HiOutlineBuildingLibrary />}
                  >
                    <Trans>Statewise Salary</Trans>
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="international"
                    leftSection={<PiAirplaneTilt />}
                  >
                    <Trans>Worldwide Salary</Trans>
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="states">
                  <LocGraph
                    arr={data.locationWiseSalary}
                    isSmallestScreen={isSmallestScreen}
                  />
                </Tabs.Panel>
                <Tabs.Panel value="international">
                  <InterlocGraph
                    arr={data.internationalSalary}
                    isSmallestScreen={isSmallestScreen}
                  />
                </Tabs.Panel>
              </Tabs>
            </Spoiler>
          </Card>

          {/* <Button variant="outline" color="black" radius="lg" mt="md" fullWidth>
            Jobs for this Profile
          </Button> */}
          <div>
            <Text size="11px" c="#696969" mt="lg" mb="60px">
              <Trans>
                Disclaimer: The salary information presented on this website is
                based on our analysis of various data sources, it is important
                to understand that these are estimates and should not be
                considered a guaranteed salary.
              </Trans>
            </Text>
          </div>
        </Container>
      ) : iserror ? (
        <div>
          <IoArrowBack
            onClick={() => {
              navigate("/salarymeter");
            }}
            color="black"
            size={26}
            style={{ cursor: "pointer", margin: "24px" }}
          />
          <Flex
            direction="column"
            justify="center"
            align="center"
            style={{ height: "80vh", fontWeight: "bold" }}
          >
            <Card
              padding="lg"
              pb="md"
              m="md"
              radius="lg"
              shadow="sm"
              style={{
                border: "0.2px solid #D2D2D2",
                boxShadow:
                  "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)",
                textAlign: "center",
              }}
            >
              <div>
                <Trans>Sorry , No data Available</Trans>
              </div>
            </Card>
          </Flex>
        </div>
      ) : (
        <div style={{ paddingTop: "64px" }}>
          <Skeleton height={24} width="80%" radius="sm" ml={8} />
          <Skeleton height={16} width="20%" radius="sm" mt={8} ml={8} />
          <Flex justify="center">
            <Skeleton h={280} width="80%" radius="sm" mt={128} />
          </Flex>
        </div>
      )}
    </div>
  );
};

export default Predictedsalary;
