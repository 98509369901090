import React, {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'

// import { useGetVerifyEmailQuery } from '../redux/api/apiSlice'
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Trans } from '@lingui/macro';



const VerifyEmailComponent = () => {
    var [searchParams] = useSearchParams();
    var publictoken = searchParams.get('token')

    console.log("publictoken",publictoken)



    // const {
    //     data: result,
    //     isFetching,
    //     isLoading,
    //   } = useGetVerifyEmailQuery(publictoken, {
    //     pollingInterval: 3000,
    //     refetchOnMountOrArgChange: true,
    //     skip: false,
    //   })

    useEffect(() => {
      const fetchVerifyEmailQuery = async () => {
        const response = await fetch(
          `${import.meta.env.VITE_BASE_URL}/public/candidate/verify-email-link/${publictoken}`
        );
        const data = await response.json();
       
  
        console.log("publictokenResult",data)
      };
  
      fetchVerifyEmailQuery();
      
    }, []);

      

      
    
     

     
   

  return ( 
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
            <IoMdCheckmarkCircleOutline style={{position:"relative",bottom:"10px", fontSize:"30px", marginRight:"10px",color:"green"}}/>

            <h3 style={{position:"relative",bottom:"10px", color:"green"}}> <Trans>Your Email has been verified successfully </Trans> </h3>
    </div>
  )
}

export default VerifyEmailComponent