import { Card, Image, Text, Group, Badge, Center, Button } from '@mantine/core';
import classes from '../styles/JobsCard.module.css';
import { IoHammerOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaPerson } from "react-icons/fa6";
import { FaMoneyBills } from "react-icons/fa6";
import {useNavigate} from 'react-router-dom'
import { Trans } from '@lingui/macro';

function GovtJobSearchCard(props) {
  const {
    title, 
    company, 
    trade,
    location, 
    state,
    experience, 
    salary,
    logo,
    id,
    
  } = props
  

  const navigate = useNavigate();

  const handleRedirect = (jid)=>{
     navigate(`/jobdescription?jid=${jid}`)     
  }



  return (
    <Card key={id} withBorder radius="md" className={classes.card}  onClick={()=>{handleRedirect(id)}}>
      <Group mt="md" className={classes.groupContainer}>
          <div>
                <Text fw={500} color="#38A78A">
                  {title}
                </Text>

                <Text fz="sm" c="dimmed">
                    {company}
                </Text>

              <div style={{marginTop:"15px"}}>
                  <Text size="sm" mb={5} mt={5} fw={600}>
                     <IoHammerOutline style={{color:"gray", marginRight:"5px"}}/>  <Trans>Trade/Job Category</Trans> : <span style={{whiteSpace: "pre-wrap",display: "unset"}}>{trade}</span>
                  </Text>
                  <Text size="sm" mb={5} mt={5} fw={600}>
                     <FaLocationDot style={{color:"gray", marginRight:"5px"}}/>  <Trans>Location</Trans> :  <span style={{whiteSpace: "pre-wrap",display: "unset"}}>{location} {(state) ? `(${state})` : ""}</span>
                  </Text>
                  <Text size="sm" mb={5} mt={5} fw={600}>
                     <FaPerson style={{color:"gray", marginRight:"5px"}}/> <Trans>Experience</Trans> : <span>{experience}</span>
                  </Text>
                  <Text size="sm" mb={5} mt={5} fw={600} >
                    <FaMoneyBills style={{color:"gray", marginRight:"5px"}}/> <Trans>Salary</Trans> : <span>{salary}</span> 
                  </Text>
              </div>

          </div>
          <div style={{textAlign:"center"}}>
             {logo &&  <img style={{width:"70px", height:"70px"}} src={logo} alt="job-logo" />}
             <div style={{marginTop:"2rem", paddingTop:"1rem"}}>
              <button className={classes.viewjobbtnGovt}><Trans>view</Trans></button>
             </div>
          </div>
      </Group>  
    </Card>
  );
}


export default GovtJobSearchCard