import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'video.js/dist/video-js.css';


const VideoPlayerProfile = ({ url, modelOpen, hideControls }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    const videoContain = document.querySelector('.videoo');
    const videoElement = videoRef.current;
    if (videoContain && videoElement) {
        videoContain.style.width = '100%';
        // videoContain.style.height = '100%';
        // videoElement.style.width = '100%';
        //  videoElement.style.height = '100%';
      }
  }, [modelOpen]);

  useEffect(() => {
    const videoContain = document.querySelector('.videoo1');
    const videoElement = videoRef.current;
    if (videoContain && videoElement) {
        videoContain.style.width = '85%';
         videoContain.style.height = '100%';
        // videoElement.style.width = '100%';
        //  videoElement.style.height = '100%';
      }
  }, [modelOpen]);

  useEffect(() => {
 // Create the Video.js player
 playerRef.current = videojs(videoRef.current, {
    controls: !hideControls // Set controls based on hideControls prop
  });

    // Set the source of the video
    playerRef.current.src({
      src: url,
      type: 'application/x-mpegURL'
    });

    // Cleanup function to destroy the player when the component unmounts
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [url, hideControls]);

  return (
    <div className={modelOpen ? 'videoo1':'videoo'} data-vjs-player>
    <video
id="my_video_1" className="video-js" width="640px" height="267px"
controls
ref={videoRef}
data-setup='{ "aspectRatio":"640:567", "playbackRates": [1, 1.5, 2] }'

    />
    
  </div>
  );
};

export default VideoPlayerProfile;