import React, { useState , useEffect } from 'react';
 
import {  Modal } from '@mantine/core';
import classes from '../styles/CustomModalInapp.module.css'
import { IoMdClose } from "react-icons/io";
import { Trans } from '@lingui/macro';


const FeatureJobModal = ({handleModalClose}) => {
     
 
  return (
    <>
    
        <Modal  opened={true} withCloseButton={false} closeOnClickOutside={false} centered padding="10px">
            <div className={classes.modalHeader}>
                   <p style={{textAlign:"center", fontSize:"20px", fontWeight:"500", margin:"5px"}}><Trans>Install ShramIN Jobs App</Trans></p>
                    <IoMdClose className={classes.closeIcon} onClick={handleModalClose}/>
            </div>
             <div style={{width:"100%", lineHeight:"20px", textAlign:"center", padding:"0 10px", paddingBottom:"10px"}}>
             <Trans>Your current browser doesn't support installing this app. For the best experience, we recommend using Chrome. Open shramin.app website in Chrome.</Trans>
            </div>
        </Modal>
 
    </>
  )

}

export default FeatureJobModal