import React, {useEffect, useState} from 'react'
import classes from '../styles/BestJobHomeSection.module.css'
import {Link} from 'react-router-dom'
import BestJobCard from './BestJobCard'
import {useGetBestMatchedJobsQuery} from '../redux/api/apiSlice'
import {useSelector} from 'react-redux'
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { defaultLocale } from '../../i18n';
import { loadCatalog } from '../../i18n'
import {Skeleton, Badge, Center, Text,Flex} from '@mantine/core'
import { UserAuth } from '../context/AuthContext'
import { MdRemoveRedEye } from "react-icons/md";


//i18n.activate(loadCatalog);

const BestJobHomeSection = ({ bestmatchingjobs }) => {

  // const {
  //  bestmatchingjobs 
  // } = props

  //console.log("zzzzzoooooooooo44", bestmatchingjobs);

    const [bestJobArray, setBestJobArray] = useState([])
    const [loading , setloading] = useState(true)
    // const { user } = UserAuth()
    // var userId = localStorage.getItem('userId');
    // const candidateId = localStorage.getItem('candidateId')
    // const token = useSelector((state) => state?.user.token)

    //console.log("candidateId and UserId in BestMatching", candidateId, userId, token )


    // const {data: bestJobs, isLoading, isError, isFetching,refetch} = useGetBestMatchedJobsQuery(candidateId,{
    //       skip:!token
    // })

    //console.log("zzzzzoooooooooo",bestJobs)

  //   useEffect(() => {
  //     const fetchData = async () => {
  //         const url = import.meta.env.VITE_BASE_URL + '/jobs';
  //         console.log("zzzzzoooooooooo candidate in best match & token", candidateId, user?.accessToken)
  //         try {
  //             if(userId){
  //             const response = await fetch(`${url}?candidateId=${candidateId}&page=0&size=25`, {
  //                 method: 'GET',
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                     "Authorization": `Bearer ${user?.accessToken}`,
  //                     "Accept": 'application/json'
  //                 },
  //             });

  //             if (!response.ok) {
  //                 throw new Error('Network response was not ok in Best Match');
  //             }


  //             const profileResult = await response.json();
  //             console.log("zzzzzoooooooooo2222222",profileResult)

  //             }
  //         } catch (error) {
  //             console.error('Error fetching profile data:', error);
  //         }


  //     };

  //     if (user?.accessToken && candidateId) {
  //         // console.log("userTokensssssss", user?.accessToken)
  //              fetchData();
  //     }
  // }, [user]);


    
    //  useEffect(() => {
    //   if (token && candidateId) {
    //       refetch();
    //   }
    //  }, [token, refetch]);

 
     useEffect(() => {
      if (bestmatchingjobs) {
        setBestJobArray(bestmatchingjobs?.content)
        setloading(false)
      }
     }, [bestmatchingjobs]);

      
    
  return (
    <div className={classes.container}>
             <Flex justify="space-between" gap="xl" p={5}>
        <Badge color="#F6F6F6" size="md" radius="sm" mt={0} p="sm">
          <span style={{ textTransform: "none", fontWeight: 700, color: "black", fontSize: "16px" }}>
            <Trans>Best Jobs for You</Trans>
          </span>
        </Badge>
        <Badge 
          color="#B0C5FA" 
          size="md" 
          radius="sm" 
          mt={0} 
          p="sm"
          onClick={() => window.location.href='/jobs?tab=all'}
          style={{ cursor: "pointer",marginRight:"15px" }}
        >
          <Text style={{ textTransform: "none", fontWeight: 700, color: "black", fontSize: "16px" }}>
            <Trans>See All</Trans>
          </Text>
        </Badge>
      </Flex>

       
          {loading ? (
                <div style={{paddingBottom:"10px", display:"grid", gridTemplateColumns:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="100%" radius="xl" style={{marginBottom:"100px"}} />
                  </div>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="100%" radius="xl" style={{marginBottom:"100px"}} />
                  </div>
                </div>
          ) : (
            <>
              <div className={classes.bestjobcardcontainer}>
              {bestJobArray?.length > 0 && (
                <>
              {bestJobArray?.map(job=>{
                return(
                  <BestJobCard 
                   id={job?.id}
                   jobTitle={job?.jobTitle}
                   trade={job?.trade?.masterName}
                   companyName={job?.companyName}
                   workType={job?.typeOfWork?.masterName}
                   education={job?.education?.masterName}
                   location={job?.location?.masterName}
                   salary={job?.salary?.masterName}
                   openings={job?.numberOfOpenings}
                   jobDate={job?.createdAt}
                   state={job?.location?.parentModel?.masterName}
                   logo= {job?.logoVirtualPath ? job?.logoVirtualPath : job?.employer?.logoVirtualPath}
                  />
                )
              })
              }
              <>
              {bestJobArray?.length == 10 && (
                <Center>
                <button style={{borderWidth:"2px", borderColor:"#0190ff"}} className={classes.roundedbutton} color='#0190ff' onClick={() => window.location.href='/jobs?tab=all'}>
                <MdRemoveRedEye size={20} style={{marginTop:"0px", marginRight:"5px", color:"#0190ff"}} />
                <Text style={{fontWeight:"600", fontSize:"14px", marginTop:"0px", color:"#0190ff"}}><Trans>View All</Trans></Text>
                </button>
                    </Center>
                   )}
                   </>
                   </>
              )}
              
              </div>

              {/* <div style={{marginTop:"60px"}}>
              <h6>.</h6>
              </div> */}
              </>
          )}
         

    </div>
  )

  
}

export default BestJobHomeSection