import React, { useState, useEffect, Suspense} from 'react'
import styles from '../styles/HomePage.module.css'
import AutocompleteLoading from '../components/AutoCompleteSearch'
import ActionHomeSection from '../components/ActionHomeSection'
import BestJobHomeSection from '../components/BestJobHomeSection'
import { UserAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { auth } from '../configFirebase'
import {  Modal, Button, Group, Text, Flex, Badge, Card, Loader, Center, Skeleton } from '@mantine/core';
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useSelector, useDispatch } from 'react-redux'
import { useDisclosure, useCounter } from '@mantine/hooks';
import { useCandidateSignUpMutation, useGetProfileDetailsQuery, useGetBestMatchedJobsQuery } from '../redux/api/apiSlice'
import { candidateData } from '../redux/userSlice'
import { useEditProfileDetailsMutation } from '../redux/api/apiSlice'
import { messaging } from '../configFirebase';
import HomeTopBanner from '../components/HomeTopBanner'
import { IoSchool } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaHardHat } from "react-icons/fa";
import { Trans, t } from '@lingui/macro';

// import TrendingStateHomeSection from '../components/TrendingState'
// import HomeTrandingMidBanner from '../components/HomeTrandingMidBanner'
// import TrendingSalaryHomeSection from '../components/TrendingSalary'
// import InterviewTipsVideoInHome from '../components/InterviewTipsVideoInHome'
// import TrendingExperienceHomeSection from '../components/TrendingExperience'
// import TrendingFresherHomeSection from '../components/TrendingFresher'
// import LastSectionVideoInHome from '../components/LastSectionVideoInHome'

const LazyTrendingStateHomeSection = React.lazy(()=>import("../components/TrendingState"))
const LazyHomeMidBanner = React.lazy(()=>import("../components/HomeMidBanner"))
const LazyHomeTrandingMidBanner = React.lazy(()=>import("../components/HomeTrandingMidBanner"))
const LazyTrendingSalaryHomeSection = React.lazy(()=>import("../components/TrendingSalary"))
const LazyInterviewTipsVideoInHome = React.lazy(()=>import("../components/InterviewTipsVideoInHome"))
const LazyTrendingExperienceHomeSection = React.lazy(()=>import("../components/TrendingExperience"))
const LazyTrendingFresherHomeSection = React.lazy(()=>import("../components/TrendingFresher"))
const LazyLastSectionVideoInHome = React.lazy(()=>import("../components/LastSectionVideoInHome"))
 


const Home = () => {
    const dispatch = useDispatch();
    const { user } = UserAuth()
    const navigate = useNavigate();
    var userId = localStorage.getItem('userId');
    var candidateId = localStorage.getItem('candidateId');

    const fcmTokenData = useSelector((state) => state?.user.fcmtoken)
    const [candidateSignUp] = useCandidateSignUpMutation()

    const token = useSelector((state) => state?.user.token)
    const userDetails = useSelector((state) => state?.user.candidateData)
    const [stateId, setStateId] = useState(552)
    const [experienceId, setExperienceId] = useState(30)
    const [salaryId, setSalaryId] = useState(41)
    //const [opened, { close, open }] = useDisclosure(false);
    const [opened , setopened] = useState(false)
    const [profileLastUpdateDate, setProfileLastUpdateDate] = useState(null)
    

    //console.log("candidateId and UserId in Home", candidateId, userId, token )

    const {data: bestJobs, isLoadingbestJobs, isErrorbestJobs, isFetchingbestJobs, refetch:refetchbestJobs} = useGetBestMatchedJobsQuery(candidateId,{
        skip:!token
  })


  const { data: candidateData1, isLoading, isError, isFetching, refetch } = useGetProfileDetailsQuery(userId, {
    skip: !token
})



    const [editProfile] = useEditProfileDetailsMutation()
    const [saveAttribution] = useEditProfileDetailsMutation()


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log("Home Parameters " + params);
        const utmSource = params.get('utm_source') ? `utm_source=${params.get('utm_source')}` : null;
        const utmMedium = params.get('utm_medium') ? `utm_medium=${params.get('utm_medium')}` : null;
        const utmCampaign = params.get('utm_campaign') ? `utm_campaign=${params.get('utm_campaign')}` : null;
    
        // Create an array with all UTM parameters
        const utmParams = [utmSource, utmMedium, utmCampaign];
        console.log("Home UTM Parameters " + utmParams);
    
        // Filter out null or undefined values and join them into a single string
        const utmString = utmParams.filter(param => param != null).join('&');
        if (utmString.length > 1)
        {
        localStorage.setItem('utmParams', utmString);
        }
        if (!user) {
            navigate('/')
        }
    })


    const [proData, setProData] = useState();

    useEffect(() => {

        const lastDeniedTime = localStorage.getItem("profileUpdateDeniedTime");
        const profileUpdateDetailtrue = localStorage.getItem("profileUpdatetrue");

        const fetchData = async () => {
            const url = import.meta.env.VITE_BASE_URL + '/candidate';
             // console.log("userToken and UserId in Homeeeeeeeee", userId)
            try {
                if(userId){
                const response = await fetch(`${url}?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${user?.accessToken}`,
                        "Accept": 'application/json'
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok in ProfileData');
                }


                const profileResult = await response.json();
                 //console.log(profileResult, "ddddddddddssssssssqazzzz")

                ////////////////////

                 let profileUpdateModyfy = profileResult?.modifiedAt
                 if (profileResult?.modifiedAt !== undefined || profileResult?.modifiedAt !== null) {
                   profileUpdateModyfy = profileUpdateModyfy?.slice(0, 10).split('-').reverse().join('-');
                 }

                // console.log(profileUpdateModyfy, "ddddddddddssssssssqazzz");
                 setProfileLastUpdateDate(profileUpdateModyfy)

            //console.log(profileUpdate, "ddddddddddssssssssqazzzz1111111")

            ////////////////////////////

            let profileUpdate = profileResult?.modifiedAt;

if (profileUpdate !== undefined && profileUpdate !== null) {
    profileUpdate = profileUpdate.slice(0, 10);
    const profileUpdateDate = new Date(profileUpdate.split('-').join('-'));
    //setProfileLastUpdateDate(profileUpdateDate)
    
    
    const subtractMonths = (date, months) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() - months);
        return newDate;
    };

    const currentDate = new Date();
    const sixMonthsAgo = subtractMonths(currentDate, 6);

    const formattedDate = sixMonthsAgo.toISOString().slice(0, 10);

    if (profileUpdateDate < new Date(formattedDate)) {
        if(profileUpdateDetailtrue === 'true'){
           // console.log("mmmmmmmmmmmmmtrue",profileUpdateDetailtrue)
        }
        else{
            setTimeout(() => {
                setopened(true);
               // console.log("mmmmmmmmmmmmm120000",profileUpdateDetailtrue)
            }, 30000);
        }
        

        if (lastDeniedTime) {
            const lastDeniedDate = new Date(lastDeniedTime);
            const profilecurrentDate = new Date();
            const timeDiff = profilecurrentDate - lastDeniedDate;
            const hoursDiff = timeDiff / (1000 * 60 * 60);

            if (hoursDiff > 24) {
               // console.log("mmmmmmmmmmmmmToday")
                setopened(true);
            }
        }
        
    }
}
      ///console.log(profileUpdate, "ddddddddddssssssssqazzzzProfile Update Date");


                ////////////////////

                 
                console.log("check Profile data in Home trade&name", profileResult?.tradeId, profileResult?.name)
                setProData(profileResult);
                if (profileResult?.tradeId == null) {
                    navigate('/onboardingbasicinformation')
                } else if (profileResult?.name == null) {
                    navigate('/createprofileonboarding')
                } else {
                    navigate('/')
                }

                //console.log("OneSignal check trade and candidate Data---", candidateId, profileResult?.trade?.masterName, profileResult?.selectedLocation?.masterName, profileResult?.education?.masterName)

                OneSignal.login(candidateId)
                OneSignal.User.addTag("trade", profileResult?.trade?.masterName)
                OneSignal.User.addTag("state", profileResult?.selectedLocation?.masterName)
                OneSignal.User.addTag("education", profileResult?.education?.masterName)

                /// console.log("Candidate API calling for Profile", profileResult);

                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }


        };

        if (user?.accessToken && userId) {
            // console.log("userTokensssssss", user?.accessToken)
                 fetchData();
        }
    }, [user]);


    const handleModalClose = () => {
        localStorage.setItem("profileUpdatetrue", true)
       localStorage.setItem("profileUpdateDeniedTime", new Date().toString())
       setopened(false)
    }


  
    const handleSuccess = (position) => {
        const { latitude, longitude } = position.coords;
        editProfile({
            latitude: latitude,
            longitude: longitude
        })
    };

    const handleError = (error) => {
        console.log(error.message)
    };

    // useEffect(() => {
    //     if (!navigator.geolocation) {
    //         console.log('Geolocation is not supported by your browser');
    //     } else {
    //         navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    //     }
    // }, []);

   /*  if (Notification.permission === "granted") {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration()
                .then(function (registration) {
                    if (registration) {
                        getToken(messaging, {
                            vapidKey: import.meta.env.VITE_VAP_ID,
                            serviceWorkerRegistration: registration
                        })
                            .then((currentToken) => {
                                if (currentToken) {
                                    dispatch(fcmToken(currentToken));
                                    candidateSignUp(currentToken)
                                }
                            })
                            .catch((error) => {
                                console.error("Error in getting FCM token:", error);
                            });
                    } else {
                        console.log('No Service Worker is currently registered.');
                    }
                })
                .catch(function (error) {
                    console.error('Error while getting Service Worker registration:', error);
                });
        }

    } */

    useEffect(() => {
        if (token) {
            if (localStorage.getItem('utmParams')) {

                const jsonObject = {
                    attribution_details: localStorage.getItem('utmParams')
                }
                saveAttribution(jsonObject)

            }
            refetch();
            refetchbestJobs()
            //fetchGetMyNotificationDetailData()
            // refetchstateJobs()
            // refetchfresherJobs()
            // refetchsalaryJobs()

        }

    }, [token, refetch, refetchbestJobs]);


    useEffect(() => {
        if (userDetails) {
            dispatch(candidateData(userDetails))
        }

    }, [userDetails])



    useEffect(() => {
        if (fcmTokenData) {
            candidateSignUp(fcmTokenData)
        }
    }, [])

    ///////////////////////////////

    const handleRedirectbtn = ()=>{
        localStorage.setItem("profileUpdatetrue", true)
        localStorage.setItem("profileUpdateDeniedTime", new Date().toString())
        setopened(false)
        navigate(`/editprofile`)     
     }



    useEffect(() => {


    const fetchGetMyNotificationDetailData = async () => {

        const url = import.meta.env.VITE_BASE_URL + '/notifications/candidate/';
    
        //const url = `https://shramin-dev.azurewebsites.net`;
        try {

            if(candidateId){

    
          const response = await fetch(`${url}${candidateId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${user?.accessToken}`,
              "Accept": 'application/json'
            },
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok in NotificationData');
          }
          const notificationdata = await response.json();
          console.log("Notificationcountt", notificationdata[0]?.readStatus)
    
          var bellicondata = notificationdata?.map((item) => item.readStatus) ?? [];
    
          var notificationCount = bellicondata.filter(item => item === null).length;
    
          // console.log("notificationCountpppHome", notificationCount)
    
          localStorage.setItem('notificationCount', notificationCount)

        }

    

        } catch (error) {
          console.error('Error fetching NotificationData :', error);
        }
      };


      if (user?.accessToken && candidateId) {
        // console.log("userTokensssssss", user?.accessToken)
        fetchGetMyNotificationDetailData();
    }
}, [user]);


      /////////////////////////


    return (
        <div className={styles.homeContainer}>
        {userId && candidateId && bestJobs ? (

           <>

           <Modal opened={opened} onClose={handleModalClose} centered title={t`Profile Update`} style={{fontSize:"12px",fontWeight:700,color:"green",}}>
        <Text style={{fontSize:"14px", color:"gray", fontWeight:600, margin: "10px"}}>
        <Trans> Keep your profile updated to receive job matches tailored to your skills and experience. Click 'Edit' to discover the best job opportunities for you.</Trans>
        {/* Kya aapke profile me diye gaye details sahi hai? */}
        </Text>

        <Card radius="md" className={styles.cardUpdateProfile} style={{boxShadow:"rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", marginBottom:"8px", marginLeft:"4px", marginTop:"2px"}}>
      <div>
        <Flex>
          <Flex align="start" gap="sm">
          {/* {proData?.photoVirtualPath && (
                <img src={proData?.photoVirtualPath} style={{width:"40px", height:"40px"}}/>
          )} */}
          <Flex direction="column">
          <div style={{fontWeight:700, fontSize:"14px"}}>{proData?.name}</div>
          <div style={{fontSize:"11px", color:"gray", fontWeight:600}}>{proData?.mobileNo}</div>
          <div style={{fontSize:"12px", color:"gray", fontWeight:600}}>{proData?.email}</div>
          </Flex>
          </Flex>
        </Flex>
        <Flex gap="md" style={{marginTop:"12px"}}>
        <Badge variant="light" color="blue" size="xs" radius="sm">{proData?.age}</Badge>
        <Badge variant="light" color="blue" size="xs" radius="sm">{proData?.gender}</Badge>
        <Badge variant="light" color="blue" size="xs" radius="sm">{profileLastUpdateDate}</Badge>
        </Flex>
        <Flex direction="column" style={{marginTop:"12px",gap:"2px"}}>
          <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}><FaHardHat style={{marginTop:"2px",marginLeft:"2px"}} />{proData?.trade?.masterName}</Flex>
          <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}><IoSchool style={{marginTop:"2px",marginLeft:"2px"}} />{proData?.education?.masterName}</Flex>
          <Flex align="start" gap="sm" style={{fontSize:"12px",fontWeight:500,color:"#454545",}}><FaHardHat style={{marginTop:"2px",marginLeft:"2px"}} />{proData?.experience?.masterName}</Flex>
        </Flex>
        <Group justify="space-between" style={{marginTop:"12px", borderTop:"gray 1px solid", paddingTop:"8px"}}>
         <div style={{fontSize:"12px", fontWeight:"bold", color:"#686868"}}><FaLocationDot style={{marginTop:"2px",marginRight:"2px", color:"#686868"}} />{proData?.location?.masterName} {(proData?.selectedLocation?.masterName) ? `(${proData?.selectedLocation?.masterName})` : ""}</div>
        </Group>
        {/* <Group justify="space-between" style={{marginTop:"12px", borderTop:"gray 1px solid", paddingTop:"8px"}}>
         <div style={{fontSize:"12px", fontWeight:"bold"}}>Last Update :- {profileUpdateDate}</div>
        </Group> */}
        </div>
      </Card>

        <Center justify="space-between" mt="lg">
          <Button variant="outline" style={{borderColor:"grey",borderWidth:"1px",marginLeft:"5px", marginRight:"5px", width:"140px"}} onClick={handleModalClose} color="grey" size="xs"><Trans>Remind me later</Trans></Button>
          <Button style={{marginLeft:"5px", marginRight:"5px", width:"140px"}} onClick={handleRedirectbtn} variant="filled" color="#0180ff" size="xs"><Trans>Edit</Trans></Button>
        </Center>
      </Modal>

            <HomeTopBanner/>
            <AutocompleteLoading />
            <ActionHomeSection />
            <BestJobHomeSection bestmatchingjobs={bestJobs} />
            {/* <TrendingStateHomeSection />
            <InterviewTipsVideoInHome />
            <TrendingFresherHomeSection />
            <HomeTrandingMidBanner />
            <TrendingSalaryHomeSection />
            <TrendingExperienceHomeSection />
            <LastSectionVideoInHome /> */}

            <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyHomeMidBanner />
           </Suspense>

            <Suspense fallback={
             <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyTrendingStateHomeSection />
           </Suspense>

            <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyInterviewTipsVideoInHome />
           </Suspense>
 
           <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyTrendingFresherHomeSection />
           </Suspense>
 
          <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyHomeTrandingMidBanner />
           </Suspense>
 
           <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyTrendingSalaryHomeSection />
           </Suspense>
 
            <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyTrendingExperienceHomeSection />
           </Suspense>
 
           <Suspense fallback={
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                </div>
           }><LazyLastSectionVideoInHome />
           </Suspense>
 
 

          </>

          
        ) : (
            
            <div style={{padding:"10px", display:"grid", gridTemplateRow:"1fr 1fr", gridGap:"20px"}}>
                  <div >
                  <Skeleton height={80} mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="100%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" style={{marginBottom:"10px"}}/>
                  </div>
                  <div >
                  <Skeleton height={50} circle mb="xl" />
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="100%" radius="xl" style={{marginBottom:"10px"}} />
                  </div>
                </div>
        )}
             
        </div>

    )
}

export default Home
