import React from 'react'
import styles from '../styles/Coursepage.module.css'
import { Title } from '@mantine/core';
import Coursesection from '../components/Coursesection/Coursesection'
import { Trans } from '@lingui/macro';


const Course = () => {
  return (
    <div className={styles.coursemainwrapper}>
              <Title order={1} style={{textAlign:"center", fontWeight:"500"}} p={4}><Trans>Courses</Trans></Title>
              <Coursesection/>
    </div>
  )
}

export default Course