import React, { useState, useEffect } from 'react'
import { TextInput, Checkbox, Button, Group, Box, Radio, Select, Modal, Text, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useGetTradeDetailsQuery, useGetLocationQuery, useGetExperienceDetailsQuery, useGetWorkTypeDetailsQuery, useAddExperienceMutation } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';


const Experience = () => {
    const { i18n } = useLingui();
    const token = useSelector((state) => state?.user?.token)
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedValue2, setSelectedValue2] = useState(null);
    const [stateId, setStateId] = useState()
    const [distId, setDistId] = useState()
    const candidateId = localStorage.getItem('candidateId')

    const userDetails = useSelector((state) => state?.user.candidateData)

    // const [opened, { open, close }] = useDisclosure(false);
    // const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);
    const navigate = useNavigate()

    const handleSelectChange = (event) => {
        const filteredData = tradeData.filter(item => item.masterName === event);
        setSelectedValue(event)

    };
    const handleSelectChange2 = (event) => {
        // const filteredData = educationData.filter(item => item.masterName === event);
        setSelectedValue2(event)

    };
    const [addExperience] = useAddExperienceMutation();



    const handleAddExperience = async (candidateId, educationData) => {
        try {
            openLoading()
            const result = await addExperience({ candidateId, data: educationData });
            if (result) {
                closeLoading()
                navigate('/myprofile')

            }
        } catch (error) {
            console.error("Add education error:", error);
            closeLoading()
        }
    };

    const { data: tradeData, isLoading, isError, isFetching, refetch } = useGetTradeDetailsQuery({
        skip: !token
    })

    const { data: locationData, isLoading: locationLoading, isError: locationError, isFetching: locationFetching, refetch: locationRefetch } = useGetLocationQuery({
        skip: !token
    })

    const { data: expData, isLoading: expLoading, isError: expError, isFetching: expFetching, refetch: expRefetch } = useGetExperienceDetailsQuery({
        skip: !token
    })

    const { data: workData, isLoading: workLoading, isError: workError, isFetching: workFetching, refetch: workRefetch } = useGetWorkTypeDetailsQuery({
        skip: !token
    })







    useEffect(() => {
        if (token) {
            refetch();
            locationRefetch()
            expRefetch()
            workRefetch()

        }
    }, [token, refetch, locationRefetch, expRefetch, workRefetch]);

    // const startYear = 2010;
    // const endYear = 2025;
    // const years = [];

    // for (let year = startYear; year <= endYear; year++) {
    //     years.push(year);
    // }
    const years = ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];


    const months = [
        { label: t`January`, value: 1 },
        { label: t`February`, value: 2 },
        { label: t`March`, value: 3 },
        { label: t`April`, value: 4 },
        { label: t`May`, value: 5 },
        { label: t`June`, value: 6 },
        { label: t`July`, value: 7 },
        { label: t`August`, value: 8 },
        { label: t`September`, value: 9 },
        { label: t`October`, value: 10 },
        { label: t`November`, value: 11 },
        { label: t`December`, value: 12 },
    ];




    const handleSubmit = (values) => {
        console.log(values)
        const experiencedata = {
            experienceId: Number(values.experience, 10),
            companyName: values.companyname,
            typeOfWorkId: Number(values.work, 10),
            tradeId: Number(values.trade, 10),
            locationId: Number(values.location, 10),
            fromMonth: Number(values.frommonth),
            fromYear: Number(values.fromyear, 10),
            currentlyWorking: Boolean(values.currentwork, 10),
            toYear: Number(values.toyear, 10),
            toMonth: Number(values.tomonth)

        };
        handleAddExperience(candidateId, experiencedata)
        console.log(experiencedata)

    }
    console.log(userDetails)

    const form = useForm({
        initialValues: {
            experience: '',
            trade: '',
            location: '',
            companyname: '',
            work: '',
            frommonth: '',
            fromyear: '',
            currentwork: '',
            toyear: '',
            tomonth: ''
        },

        validate: {
            experience: (value) => (value.length == 0 ? <Trans>Education is Required</Trans> : null),
            trade: (value) => (value.length == 0 ? <Trans>Trade is Required</Trans> : null),
            location: (value) => (value.length == 0 ? <Trans>Location is Required</Trans> : null),
            work: (value) => (value.length == 0 ? <Trans>Work Type is Required</Trans> : null),
            companyname: (value) => (value.length == 0 ? <Trans>Company Name is Required</Trans> : null),
            frommonth: (value) => (value.length == 0 ? <Trans>Month is Required</Trans> : null),
            fromyear: (value) => (value.length == 0 ? <Trans>Year is Required</Trans> : null),
            currentwork: (value) => (value == '' ? <Trans>Current Work is Required</Trans> : null)
        },
    });

    const currentWorkValue = form.values.currentwork


    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

                <div>
                    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}
                        style={{ display: "flex", flexDirection: "column", gap: ".5rem", height: "auto", padding: "10px" }}>
                        <Select
                            key={i18n.locale}
                            label={<Trans>Your Work Experience</Trans>}
                            placeholder={t`Select Your Work Experience`}
                            data={expData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange2}
                            value={selectedValue2}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('experience')}
                        />


                        <TextInput
                            withAsterisk
                            label={<Trans>Company Name</Trans>}
                            // leftSection={<IoPerson />}
                            placeholder={t`Enter Company Name`}
                            {...form.getInputProps('companyname')}
                        />

                        <Select
                            label={<Trans>Type of Work</Trans>}
                            placeholder={t`Select Your Type of Work`}
                            data={workData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            // onChange={handleSelectChange}
                            // value={selectedValue}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('work')}
                        />

                        <Select
                            label={<Trans>Trade/Job Category</Trans>}
                            placeholder={t`Select Trade`}
                            data={tradeData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange}
                            value={selectedValue}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('trade')}
                        />



                        <Select
                            label={<Trans>Your Job Location</Trans>}
                            placeholder={t`Select Your Job Location`}
                            data={locationData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            // onChange={handleSelectChange}
                            // value={selectedValue}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('location')}
                        />

                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            {/* <Select
                            // label="Passing Year (or expected)"
                            placeholder="Month"
                            data={month}
                            {...form.getInputProps('frommonth')}
                        /> */}
                            <Select

                                placeholder={t`Select Month`}
                                data={months?.map((item) => ({
                                    value: item?.value.toString(),
                                    label: item?.label
                                }))}
                                searchable
                                nothingFoundMessage={t`Nothing found...`}
                                {...form.getInputProps('frommonth')}
                            />
                            <Select
                                // label="Passing Year (or expected)"
                                placeholder={t`Year`}
                                data={years}
                                {...form.getInputProps('fromyear')}
                            />
                        </div>

                        <div>
                            <label><Trans>I Am Currently Working Here?</Trans></label>
                            <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                <Radio
                                    label={<Trans>Yes</Trans>}
                                    value="true"
                                    checked={form.values.currentwork === "true"}
                                    onChange={(event) => form.setFieldValue('currentwork', event.currentTarget.value)}

                                />
                                <Radio
                                    label={<Trans>No</Trans>}
                                    value="false"
                                    checked={form.values.currentwork === 'false'}
                                    onChange={(event) => form.setFieldValue('currentwork', event.currentTarget.value)}
                                />
                            </div>
                        </div>
                        {
                            currentWorkValue == 'false' &&
                            <div style={{ display: "flex", gap: "5px" }}>
                                <Select

                                    placeholder={t`Select Month`}
                                    data={months?.map((item) => ({
                                        value: item?.value.toString(),
                                        label: item?.label
                                    }))}
                                    searchable
                                    nothingFoundMessage={t`Nothing found...`}
                                    {...form.getInputProps('tomonth')}
                                />
                                <Select
                                    // label="Passing Year (or expected)"
                                    placeholder={t`Year`}
                                    data={years}
                                    {...form.getInputProps('toyear')}
                                />
                            </div>
                        }




                        <Group justify="center" mt="md" >
                            <Button

                                type="submit" style={{
                                    width: '100%',
                                    marginBottom: '5rem',
                                }}><Trans>Add</Trans></Button>
                        </Group>
                    </form>
                </div>
            </Box>
        </>
    )
}

export default Experience