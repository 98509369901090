import React, { useEffect, useState } from 'react'
import classes from '../styles/Coursedescription.module.css'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useGetCourseDetailQuery } from '../redux/api/apiSlice'
import { useSelector } from 'react-redux'
import { Button } from '@mantine/core'
import { CiShare2 } from "react-icons/ci";
import { Notification, Center, Stack, Modal, Text, Group, Loader, Flex, Card, Badge, Divider, Spoiler } from '@mantine/core'
import { useWindowScroll, useDisclosure } from '@mantine/hooks'
import { usePostCourseApplyMutation, useGetcourseapplystatusQuery } from '../redux/api/apiSlice'
import { Trans } from '@lingui/macro'
import { UserAuth } from '../context/AuthContext'
import { MdLocationOn } from "react-icons/md";
import { GiSandsOfTime } from "react-icons/gi";
import { PiNotebookFill } from "react-icons/pi";
import parse from "html-react-parser";


const Coursedescription = () => {
  const candidateid = localStorage.getItem('candidateId')
  const token = useSelector((state) => state?.user.token)
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const courseid = searchParams.get('courseid')
  const { user } = UserAuth();
  //console.log("courseidaaaaaa", courseid)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [applied, setapplied] = useState(false)
  const [scroll, scrollTo] = useWindowScroll();
  const [coursedata, setCourseData] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const isWithinCallTime = currentHour >= 9 && currentHour < 18;




  // const { data: coursedata, isLoading, isError, isFetching, refetch } = useGetCourseDetailQuery(courseid, {
  //   skip: !token
  // })

 // console.log(coursedata,"aaaaaaaaaallllllll")

  /////////////////////////

  useEffect(() => {
    const fetchCoursedescriptiondata = async () => {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/public/v2/courses/${courseid}`
      );
      const data = await response.json();
      setCourseData(data);
      // console.log(coursedata,"aaaaaaaaaallllllll555")
    };

    fetchCoursedescriptiondata();
    
  }, []);


  const { data: courseapplieddata, refetch: refetch1 } = useGetcourseapplystatusQuery(
    { courseid, candidateid }, {
    skip: !token
  })

  const [trigger, { data: result, error: resulterror }] = usePostCourseApplyMutation()
  const [trigger2, { data: result2, error: error2 }] = usePostCourseApplyMutation()
  const [trigger3, { data: result3, error: error3 }] = usePostCourseApplyMutation()
  const [trigger4, { data: result4, error: error4 }] = usePostCourseApplyMutation()


  useEffect(() => {
    if (token) {
      // refetch();
      refetch1();
    }

    if (candidateid && courseid) {
      const data = {
        candidateId: candidateid,
        courseId: courseid,
        //  applyCourse: null,
        viewCourse: "Y"
      }
      trigger2(data)
    }

  }, [token]);


  useEffect(() => {
    if (courseapplieddata) {
      if (courseapplieddata.appliedCourse) {
        setapplied(true)
      } else {
        setapplied(false)
      }
    }
  }, [courseapplieddata]);


  const handleRedirectLogin = () => {
    navigate("/");
  };



  const handleCourseApply = () => {
    const data = {
      candidateId: candidateid,
      courseId: courseid,
      applyCourse: "Y"
    }

    trigger(data)
    setShowAlertSuccess(true)
    setapplied(true)
    scrollTo({ y: 0 })

  }

  const handleCourseCall = () => {
    const data = {
      candidateId: candidateid,
      courseId: courseid,
      // applyCourse: null,
      callCourse: "Y"
    }

    trigger3(data)
    scrollTo({ y: 0 })
    window.open("tel:" + coursedata?.trainingPartnerPhoneNo);

  }

  const handleCourseCallNew = () => {
    open();
    const data = {
      candidateId: candidateid,
      courseId: courseid,
      // applyCourse: null,
      callCourse: "Y"
    }

    trigger3(data)
    scrollTo({ y: 0 })

  }

  const handlethirdPartyCourseApply = () => {
    const data = {
      candidateId: candidateid,
      courseId: courseid,
      applyCourse: "Y"
    }

    trigger4(data)
    scrollTo({ y: 0 })

  }

  //////////////


  const shareToWhatsApp = () => {
       
    if (navigator.share) {
      navigator.share({
        title: 'ShramIN App',
        text: `Training on SharmIN App` + `${"\n"}` + `Course Details:` + `${"\n"}` + `${"\n"}` + `Course Name` + `${"\n"}` + `${coursedata?.courseName}`+ `${"\n"}` + `${"\n"}` + `Course Training Partner` + `${"\n"}` + `${((coursedata?.organizationName) ? coursedata?.organizationName : "")}` +
        //  `${"\n"}` + `${"\n"}` +  `Course Fee` + `${"\n"}` + `${coursedata?.courseFee}` +
          `${"\n"}` + `${"\n"}` + `Course Location` + `${"\n"}` + `${coursedata?.location.masterName}` + `${"\n"}` + `${"\n"}` + `Apply Here` + `${"\n"}` ,
        url: (coursedata?.deepLink === null
          ? 'https://www.shramin.app/'
          : coursedata?.deepLink + '?jobId=' + candidateid)
      }).then(() => {
       // console.log('Thanks for sharing!');
      }).catch(err => {
        //console.log("Error while using Web share API:");
        console.log(err);
      });
    } else {
      alert("Browser doesn't support this API !");
    }
  };

  

  // const shareToWhatsApp = () => {

  //   const textToSend =
  //     'Training on SharmIN App' +
  //     '%0a' +
  //     '' +
  //     "Course Details" +
  //     ' :' +
  //     '%0a' +
  //     '%0a' +
  //     '*' +
  //     "Course Name" +
  //     '*' +
  //     '%0a' +
  //     encodeURIComponent(coursedata?.courseName) +
  //     '%0a' +
  //     '%0a' +
  //     '*' +
  //     "Course Training Partner" +
  //     '*' +
  //     '%0a' +
  //     encodeURIComponent((coursedata) ? coursedata?.organizationName : "") +
  //     '%0a' +
  //     '%0a' +
  //     '*' +
  //     "Course Fee" +
  //     '*  ' +
  //     '%0a' +
  //     encodeURIComponent(coursedata?.courseFee) +
  //     '%0a' +
  //     '%0a' +
  //     '*' +
  //     "Course Location" +
  //     '* ' +
  //     '%0a' +
  //     encodeURIComponent(coursedata?.location.masterName) +
  //     '%0a' +
  //     '%0a' +
  //     '*' +
  //     "Apply Here" +
  //     '* ' +
  //     '%0a' +
  //     (coursedata?.deepLink === null
  //       ? 'https://www.shramin.app/'
  //       : coursedata?.deepLink + '?jobId=' + candidateid);

  //   window.open(`whatsapp://send?text=${textToSend}`);


  // };

  ///////////////






  return (
    <div className={classes.wrapper}>
      {coursedata != null ?
        <div>

          <Modal
            withCloseButton={false}
            opened={opened}
            centered

          >
            <div style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "0px" }}>
              <div style={{ textAlign: "center" }}>
              </div>
              <Text align="left" fw={500} size="sm" mb="md">
                <Trans>Please Call to HR between 9 AM to 6 PM</Trans>
              </Text>
            </div>


            <Stack align="center">
              <Group position="center" mt="md">
                <Button onClick={close}><Trans>OK</Trans></Button>
              </Group>
            </Stack>

          </Modal>

          {showAlertSuccess && (
            <Notification
              color='#eee'
              onClose={() => setShowAlertSuccess(false)}
              style={{
                backgroundColor: "#2aa63d",
                color: "#eee",
                position: "fixed",
                width: "100%",
                maxWidth: "600px",
                zIndex: 9999,
                top: 60
              }}
            >
              <span style={{ color: "#eee", fontSize: "1rem" }}>
                <Trans>Congratulations! You have successfully submitted your application.</Trans>
              </span>
            </Notification>
          )}



          <div style={{ padding: "10px 10px",backgroundColor:"white" }}>

          <div>
                      <Card
                        style={{ minWidth: "350px",marginBottom:"4px", padding:"12px 12px 0px 12px" }}
                      >

                        <Flex direction="column" gap="md">

                          <Group fluid justify="space-between" align="flex-start">
                            <Flex direction="column" style={{maxWidth: "75%"}}>
                              <span
                                style={{ fontWeight: "bold", fontSize: "24px" }}
                              >
                                {coursedata?.courseName}
                              </span>
                              {coursedata?.trainingPartnerOrganizationName ? (
                                <span
                                style={{
                                  fontWeight: 600,
                                  color: "#808080",
                                }}
                              >
                                {coursedata?.trainingPartnerOrganizationName}
                              </span>
                              ) : (
                                <>
                                {coursedata?.organizationName && (
                                  <span
                                style={{
                                  fontWeight: 600,
                                  color: "#808080",
                                }}
                              >
                                {coursedata?.organizationName}
                              </span>
                                )}
                                </>
                              )}
                              
                            </Flex>
                            <Flex>
                              {coursedata?.logoVirtualPath ? (
                                <img
                                  src={coursedata?.logoVirtualPath}
                                  alt="Company Logo"
                                  style={{ width: "55px", height: "55px" }}
                                />
                              ) : (
                                <>
                                {coursedata?.trainingPartnerLogoVirtualPath && (
                                  <img
                                    src={coursedata?.trainingPartnerLogoVirtualPath}
                                    alt="Company Logo"
                                    style={{ width: "55px", height: "55px" }}
                                  />
                                )}
                                </>
                              )}
                            </Flex>
                          </Group>
                          <Flex direction="column" gap="sm" ml={"5px"}>
                            <Flex align="flex-start">
                              <GiSandsOfTime style={{marginTop:"7px", fontSize:"12px", marginLeft:"2px"}} />
                              
                              <span style={{fontWeight:"600", marginLeft:"2px"}}><Trans>Course Duration</Trans>:</span>
                              &nbsp;
                              {coursedata?.courseDuration?.masterName}
                            </Flex>

                            <Flex align="flex-start">
                              <PiNotebookFill style={{marginTop:"6px",fontSize:"14px",}}/>
                              <span style={{fontWeight:"600", marginLeft:"2px"}}><Trans>Course Type</Trans>:</span>
                              &nbsp;
                              {coursedata?.courseType?.masterName}
                            </Flex>

                            <Flex align="flex-start">
                              <MdLocationOn style={{marginTop:"6px",fontSize:"14px",}}/>
                              
                              <span style={{fontWeight:"600", marginLeft:"2px"}}><Trans>Location</Trans>:</span>
                              &nbsp;
                              {coursedata?.location?.masterName}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex gap="md" style={{padding:"14px 4px"}}>
                      <Badge color="green" variant="light" size="lg" radius="sm" p="sm">
                        <span style={{textTransform: "none", fontWeight:600}}>
                        {coursedata?.noOfSeats} <Trans>Seats</Trans>
                        </span>
                      </Badge>
                      </Flex>
                      </Card>
                    </div>
                    
                    <Divider my="sm" variant="dashed" />

                    <Card radius="lg" p="xs">
                      <Flex direction="column" gap="md">
                      <span style={{ fontWeight: 'bold', marginLeft:"10px"}}>
                        <Text style={{ fontWeight: 'bold'}}><Trans>Qualifications</Trans></Text>
                      </span>
                      <Flex gap="sm" ml={"5px"}>
                      <Badge variant="outline" color="gray" size="lg" p="sm" style={{color:"#3490e6"}}><span style={{textTransform:"none", fontWeight:600}}><Trans>Education</Trans> :{" "}
                      {coursedata?.education?.masterName}</span></Badge>
                      </Flex>
                      </Flex>
                    </Card>

                    <Card padding="lg" radius="md" shadow="sm" style={{marginTop:"12px",marginBottom:"120px",border:"0.5px solid #D2D2D2",
                      boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                    }}>
                    <Flex direction="column">
                    <span style={{fontWeight:"bold"}}><Trans>Course Description</Trans></span>
                    <Spoiler maxHeight={50} showLabel="Read more" hideLabel="Read less" styles={(theme) => ({
                        control: {
                          right:0,
                          insetInlineStart: "initial",
                          color: "green",
                          fontWeight: 600,
                        },
                      })} >
                        {parse(coursedata?.courseDescription)}
                    </Spoiler>
                  </Flex>
                    </Card>


            {/* {(coursedata?.logoVirtualPath || coursedata?.trainingPartner?.logoVirtualPath) && <img src={coursedata?.logoVirtualPath || coursedata?.trainingPartner?.logoVirtualPath} alt="course-image" style={{ width: "100px", height: "100px" }} />}
            <p style={{ textTransform: "capitalize", fontSize: "18px", color: "#0180ff", margin: "0" }}>{coursedata?.courseName}</p>
            <p style={{ textTransform: "capitalize", fontSize: "18px", color: "gray", margin: "0" }}>{coursedata?.trainingPartner?.organizationName}</p>
            <p style={{ textTransform: "capitalize", fontSize: "16px", color: "#000", margin: "0", marginTop: "10px", marginBottom: "5px" }}><Trans>Course Duration</Trans> : {coursedata?.courseDuration?.masterName}</p>
            <p style={{ textTransform: "capitalize", fontSize: "16px", color: "#000", margin: "0", marginBottom: "5px" }}><Trans>Location</Trans> : {coursedata?.location?.masterName}</p>
            <p style={{ textTransform: "capitalize", fontSize: "16px", color: "#000", margin: "0", marginBottom: "5px" }}><Trans>Course Type</Trans> : {coursedata?.courseType?.masterName}</p>
            <p style={{ textTransform: "capitalize", fontSize: "16px", color: "#000", margin: "0", marginBottom: "5px" }}><Trans>No of Seats</Trans> : {coursedata?.noOfSeats}</p>
            <p style={{ textTransform: "capitalize", fontSize: "16px", color: "#000", margin: "0", marginBottom: "5px" }}><Trans>Minimum Education</Trans> : {coursedata?.education?.masterName}</p>
            <p style={{ textTransform: "capitalize", fontSize: "16px", color: "#000", margin: "0", marginBottom: "5px" }}><Trans>Course Description</Trans> : {coursedata?.courseDescription}</p>
          */}
          </div>



           
          <div className={`${
                candidateid
                  ? classes.buttonsectionwrapper
                  : classes.buttonsectionwrapperpublic
              }`}>
          {user ? (
                <>
                {!coursedata?.courseThirdPartyApplyLink && (
              <>
                {coursedata?.phoneNoOption == 'Yes' ? (
                  <>
                    <div className={classes.buttonsection1}>
                      {applied ? (
                        <Button disabled w="100%">
                          <Trans>Applied</Trans>
                        </Button>
                      ) : (
                        <Button w="100%" onClick={handleCourseApply}>
                          <Trans>Apply</Trans>
                        </Button>
                      )}

                      {isWithinCallTime ? (

                        <div>
                          <Button onClick={handleCourseCall} style={{ border: "none", cursor: "pointer", background: "green", color: "#fff", width: "100%" }}>
                            <Trans>Call</Trans>
                          </Button>
                        </div>

                      ) : (

                        <div>
                          <Button onClick={handleCourseCallNew} style={{ border: "none", cursor: "pointer", background: "grey", color: "#fff", width: "100%" }}>
                            <Trans>Call</Trans>
                          </Button>
                        </div>

                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.buttonsection3}>
                      {applied ?
                        <Button disabled w="100%">
                          <Trans>Applied</Trans>
                        </Button> :
                        <Button w="100%" onClick={handleCourseApply}>
                          <Trans>Apply</Trans>
                        </Button>
                      }
                    </div>
                  </>
                )}
              </>
            )}
            {coursedata?.courseThirdPartyApplyLink && (
              <div className={classes.buttonsection2}>
              <a href={coursedata?.courseThirdPartyApplyLink} target="_blank" style={{ color: "#fff", textDecoration: "none" }}>
                <Button onClick={handlethirdPartyCourseApply} w="100%">
                    <Trans>View course</Trans>
                </Button>
                </a>
              </div>
            )}


            <div className={classes.sharesection}>
              <Button onClick={shareToWhatsApp} className={classes.sharebtn}>
                <CiShare2 style={{ color: "green", fontWeight: "500", fontSize: "30px", marginRight: "10px" }} />
                <Trans>Share</Trans>
              </Button>
            </div>
                </>
              ) : (
                 <>
                 {!coursedata?.courseThirdPartyApplyLink && (
              <>
                {coursedata?.phoneNoOption == 'Yes' ? (
                  <>
                    <div className={classes.buttonsection1}>
                      {applied ? (
                        <Button disabled w="100%">
                          <Trans>Applied</Trans>
                        </Button>
                      ) : (
                        <Button w="100%" onClick={handleRedirectLogin}>
                          <Trans>Apply</Trans>
                        </Button>
                      )}

                      {isWithinCallTime ? (

                        <div>
                          <Button onClick={handleRedirectLogin} style={{ border: "none", cursor: "pointer", background: "green", color: "#fff", width: "100%" }}>
                            <Trans>Call</Trans>
                          </Button>
                        </div>

                      ) : (

                        <div>
                          <Button onClick={handleRedirectLogin} style={{ border: "none", cursor: "pointer", background: "grey", color: "#fff", width: "100%" }}>
                            <Trans>Call</Trans>
                          </Button>
                        </div>

                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.buttonsection3}>
                      {applied ?
                        <Button disabled w="100%">
                          <Trans>Applied</Trans>
                        </Button> :
                        <Button w="100%" onClick={handleRedirectLogin}>
                          <Trans>Apply</Trans>
                        </Button>
                      }
                    </div>
                  </>
                )}
              </>
            )}
            {coursedata?.courseThirdPartyApplyLink && (
              <div className={classes.buttonsection2}>
              <Button w="100%" onClick={handleRedirectLogin}>
                      <Trans>View course</Trans>
              </Button>
                {/* <Button onClick={handleRedirectLogin} w="100%">
                  <a href={coursedata?.courseThirdPartyApplyLink} target="_blank" style={{ color: "#fff", textDecoration: "none" }}>
                    <Trans>View course</Trans>
                  </a>
                </Button> */}
              </div>
            )}


            <div className={classes.sharesection}>
              <Button onClick={shareToWhatsApp} className={classes.sharebtn}>
                <CiShare2 style={{ color: "green", fontWeight: "500", fontSize: "30px", marginRight: "10px" }} />
                <Trans>Share</Trans>
              </Button>
            </div>
                 </>
            )}
           
          </div>
        </div>
        :
        <Center>
          <Loader style={{ marginTop: "220px" }} color="#034078" size={30} />
        </Center>
      }
    </div>
  )
}

export default Coursedescription