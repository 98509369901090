import React,{useEffect, useState} from 'react'
import classes from './Coursesection.module.css'
import {useSelector} from 'react-redux'
import {Skeleton} from '@mantine/core'
import {useGetAllCoursesQuery} from '../../redux/api/apiSlice'

import Coursecard from './Coursecard/Coursecard'

const Coursesection = () => {
  const token = useSelector((state) => state?.user.token)
  const [allcourses, setAllcourses] = useState([])
  const [loading, setloading] = useState(true)


      const { data: coursedata, isLoading, isError, isFetching, refetch } = useGetAllCoursesQuery({
        skip: !token
      })

      useEffect(() => {
          if (token) {
              refetch();
          }
      }, [token, refetch]);


      useEffect(() => {
          if (coursedata) {
              setAllcourses(coursedata?.content)
              setloading(false)
          }
      }, [coursedata]);



      
     

  return (
    <>
    {loading ? (
        <>
                <div style={{marginBottom:"20px"}}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                </div>
                <div style={{marginBottom:"20px"}}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                </div>
                <div>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                </div>
        </>
    ) : (
      <>
       {allcourses.length > 0 && (
              allcourses.map(course=>{
                  return  <Coursecard
                         id={course?.id}
                         title={course?.courseName}
                         name={course?.trainingPartner?.organizationName}
                         duration={course?.courseDuration?.masterName}
                         fee={course?.courseFee}
                         location={course?.location?.masterName}
                         seats={course?.noOfSeats}
                         education={course?.education?.masterName}
                         logo = {course?.logoVirtualPath ? course?.logoVirtualPath : course?.trainingPartner?.logoVirtualPath }
                   />
              })
          )}
      </>
    )}
   
    </>
  )
}

export default Coursesection