import React from 'react'
import {
    Card,
    Text,
    Image,
    SimpleGrid,
    UnstyledButton,
    Anchor,
    Box,
    Group,
    Modal,
    Stack,
    Select,
    useMantineTheme,
    Center,
    Combobox,
    Input,
    InputBase,
    useCombobox
    
  } from '@mantine/core';
import classes from '../styles/JobCategorySection.module.css'
import { FaPen } from "react-icons/fa";
import { useDisclosure } from '@mantine/hooks';
import { apiSlice, useActiveJobCategoryMutation, useGetTradeDetailsQuery, useGetActiveTradeDetailsQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans,t } from '@lingui/macro';
import OneSignal from 'react-onesignal';
import { MdOutlineWork } from "react-icons/md";
import { useLingui } from '@lingui/react';


const JobCategory = () => {
  const { i18n } = useLingui();

  
  var candidateId = localStorage.getItem('candidateId');
  //console.log("asssssssswwwwwww", candidateId)

    const navigate = useNavigate()


    const token = useSelector((state) => state?.user?.token)
    const [tradeValue, setTradeValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);

    const [searchHeight, setSearchHeight] = useState(false)


    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });
  
    const [value, setValue] = useState(null);
    const [search, setSearch] = useState('');
  

    const theme = useMantineTheme();

    const { data: tradeData, isLoading: tradeLoading, isError: tradeError, isFetching: tradeFetching, refetch: tradeRefetch } = useGetActiveTradeDetailsQuery({
        skip: !token
    })

    const { data: tradeAllData, isLoading, isError, isFetching, refetch } = useGetTradeDetailsQuery({
      skip: !token
  })

  //console.log("asssssssswwwwwww", tradeAllData)

    

    const [jobTradeCategory] = useActiveJobCategoryMutation()

    useEffect(() => {
        if (token) {
            tradeRefetch()
            refetch();
        }
    }, [token, tradeRefetch, refetch]);

    const openSearch = () => {
      setSearchHeight(true)
      combobox.openDropdown()
    }

    const closeSearch = () => {
      combobox.closeDropdown()
      if(value == null){
        setSearchHeight(false)
      }
    }

   


    const handleSubmit = (item) => {
     // console.log("asssssssswwwwwww", item?.id, candidateId)
     // console.log(JSON.stringify(values) + "before convert")
      const convertedObject = {
          
          Id: parseInt(candidateId),
          tradeId: parseInt(item?.id),
          
      };
      console.log(JSON.stringify(convertedObject) + "after convert");
      jobTradeCategory(convertedObject);

      navigate('/joblocation');
  }

//   const handleSubmit3 = () => {
//      open();   
//  }


  const handleSubmit2 = (itemTrade) => {
     //console.log("asssssssswwwwwww", itemTrade?.id, candidateId)
    // console.log(JSON.stringify(values) + "before convert")
     const convertedObject = {
         
         Id: parseInt(candidateId),
         tradeId: parseInt(itemTrade?.id),
         
     };
     console.log(JSON.stringify(convertedObject) + "after convert");
     jobTradeCategory(convertedObject);
     navigate('/joblocation');
 }

  
   // style={{backgroundColor: '#F6F6F6'}}

  const items = tradeData?.map((item) => (
    <UnstyledButton onClick={()=>{handleSubmit(item)}} key={item.masterName} style={{backgroundColor: '#F6F6F6'}} className={classes.item}>
   <Image style={{width: 45,
    height: 45,}}
              src={item.logoVirtualPath}/>
              <Text style={{textAlign: "center"}}
               size="xs" mt={7}>
        {item?.masterName}
      </Text>
    
      </UnstyledButton>
   
  ));


  ////////////////

  // const itemsTrade = tradeAllData?.map((itemTrade) => (
  //   <UnstyledButton onClick={()=>{handleSubmit2(itemTrade)}} value={itemTrade?.masterName} key={itemTrade?.masterName} style={{backgroundColor: '#F6F6F6'}} className={classes.item}>
  //             <Text style={{textAlign: "center"}}
  //              size="xs" mt={5} mb={5}>
  //       {itemTrade?.masterName}
  //     </Text>
    
  //     </UnstyledButton>
   
  // ));

  // console.log("optionsgroceries",groceries) 
  // console.log(tradeAllData[1]?.masterName,"iiiiiiioo")
  
  


  //  const shouldFilterOptions = tradeAllData?.every((itemTrade) => itemTrade !== search);
  // const filteredOptions = shouldFilterOptions
  //   ? tradeAllData?.filter((itemTrade) => itemTrade?.toLowerCase().includes(search.toLowerCase().trim()))
  //   : tradeAllData;
  //   console.log("filteredOptions", filteredOptions)

   const filteredOptions = tradeAllData?.filter((itemTrade) => itemTrade?.masterName?.toLowerCase().includes(search.toLowerCase().trim()))

   const options = filteredOptions?.map((itemTrade) => (
    <Combobox.Option onClick={()=>{handleSubmit2(itemTrade)}} value={itemTrade?.masterName} key={itemTrade?.id}>
      {itemTrade?.masterName}
    </Combobox.Option>
  ));


    // console.log("optionsqqqqq",options)

  ///////////////



  return (
  
    
        <Card withBorder radius="md" className={classes.card}>

         {/* <Modal
           withCloseButton={false}
            opened={opened}
            centered
            
        >

         <SimpleGrid cols={1} mt="md">
          {itemsTrade}
         </SimpleGrid>
            

        </Modal> */}

        <Center>
            <Text mt={10} size="xl" fw={500}>
              <Trans>Select Job Category</Trans>
            </Text>
          </Center>
         
       
        

       

      
        {/* <Center className={classes.itemTrade} style={{alignItems: "center", alignSelf: "center", backgroundColor: "#F6F6F6", width: "100px"  }}>
        <div style={{alignItems: "center", alignSelf: "center", backgroundColor: "#F6F6F6",  }}  onClick={handleSubmit3}>
        <MdOutlineWork style={{alignItems: "center", alignSelf: "center", marginLeft: "0px"  }}  size={40}/>
        <div>
        <Text style={{textAlign: "center"}}
            size="sm" mt={2} mb={2}>
               Others
        </Text>
        </div>
        </div>
        
        </Center> */}

        <Center style={{marginTop: "20px",}}>
        <Text style={{textAlign: "center"}}
            size="lg" mt={2} mb={2}>
               <Trans>Search Trades</Trans>
        </Text>
        </Center>
       


    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setValue(val);
        setSearch(val);
        closeSearch();
        // combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => openSearch()}
          // onFocus={() => openSearch()}
          onBlur={() => {
            closeSearch()
            setSearch(value || '');
          }}
          placeholder={t`Search Your Trade`}
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown style={{zIndex:"0"}}>
        <Combobox.Options>
          {options?.length > 0 ? options : <Combobox.Empty><Trans>Trade not found</Trans></Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>


    <SimpleGrid cols={3} mt="md">
          {items}
        </SimpleGrid>

        {searchHeight ? 
        (
        <div style={{marginTop : "3500px", fontWeight: "lighter"}}>
           <Text>.</Text>
        </div>
        )
        : 
        (<div>

        </div>
        )}

       

    </Card> 

       
     
          
  )
}

export default JobCategory