import React , {useState, useEffect} from 'react'
import { Button } from '@mantine/core';
import { IoMdDownload } from "react-icons/io";
import classes from '../styles/installbutton.module.css'
import { Trans } from '@lingui/macro';

const InstallApp = ({beforeInstallPrompt}) => {

  
  const [showInstallButton , setshowinstallbutton] = useState(false)



  

  useEffect(() => {

        if(window.matchMedia('(display-mode: standalone)').matches){
          setshowinstallbutton(false)
        }else{
          setshowinstallbutton(true)
        }

  }, []);


  const installApp = () => { 
    if (beforeInstallPrompt) {
      beforeInstallPrompt.prompt();
    }
  };



  
  return (
    <>   
    {(showInstallButton && beforeInstallPrompt) && (
     
      <Button 
      id='installbutton' 
      variant="filled" 
      size="sm" 
      style={{display:"flex", justifyContent:"center", alignItems:"center", marginRight:"10px", position:"relative", bottom:"5px"}} onClick={installApp} 
      >
        <IoMdDownload style={{marginRight:"5px"}}/>
        <Trans>Install App</Trans>
      </Button> 
    
    )}
    </>
  )
}

export default InstallApp