import { Text, Card, RingProgress, Group, useMantineTheme } from '@mantine/core';
import classes from '../styles/FeaturedJobcard.module.css';
import { IoHammerOutline } from "react-icons/io5";
import { FaPerson } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import {useNavigate} from 'react-router-dom'
import { Trans } from '@lingui/macro';
import { FaBookOpenReader } from "react-icons/fa6";

 function FeatureJobcard({trade, companyName,workType, education, location, state, id,logo , handleModalClose, jobTitle}) {

  const theme = useMantineTheme();

  const navigate = useNavigate();

  const handleRedirect = (jid)=>{
     navigate(`/jobdescription?jid=${jid}`) 
     handleModalClose();    
  }

  return (
    <Card withBorder radius="md" className={classes.card} onClick={()=>{handleRedirect(id)}}>
      <div className={classes.inner}>
        <div>
          <Text fz="xs" style={{fontWeight:"700"}} mb={2}>
              {jobTitle}
          </Text>
          <Text fz="xs">
             {companyName}
          </Text>

          <div style={{marginTop:'20px'}}>
            <Text fz="xs">
             <IoHammerOutline/> {trade}
            </Text>
            <Text fz="xs">
             <FaPerson/> {workType}
            </Text>
            <Text fz="xs">
             <FaBookOpenReader/> {education}
            </Text>
            <Text fz="xs">
             <FaLocationDot/> {location} {(state) ? `(${state})` : ""}
            </Text>
          </div>
          

        </div>

        <div>
            <div style={{textAlign:"center", minHeight:"50px"}}>
               {logo && (
                <img src={logo} style={{width:"40px", height:"40px"}}/>
               )}
            </div>
            <div style={{textAlign:"center", marginTop:"20px"}}>
                  <button className={classes.applynowlink}> <Trans>Apply Now</Trans> </button>
            </div>
        </div>
      </div>
    </Card>
  );
}

export default FeatureJobcard