import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { useUploadPhotoMutation } from '../redux/api/apiSlice';
import { Button, LoadingOverlay } from '@mantine/core';
import { Trans } from '@lingui/macro';
import { useDisclosure } from '@mantine/hooks';


const WebcamImage = ({ closeModel }) => {
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };
    const [uploadPhoto] = useUploadPhotoMutation()

    const capturePhoto = async () => {
        openLoading()
        const screenshot = await webcamRef.current.getScreenshot();
        setImgSrc(screenshot);

        // Upload image to API
        // try {
        const formData = new FormData();
        const blob = dataURItoBlob(screenshot);

        // Append the blob with a specific file name to the FormData
        formData.append('photo', blob, 'profile.jpeg');

        console.log(JSON.stringify(formData) + "form data")
        uploadPhoto(formData).then(() => {
            console.log('Photo uploaded successfully!');
            closeLoading()
        }).catch(() => {
            console.log("error")
        })

        closeModel()
    };



    return (
        <>
            <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "10px", height: "100%", width: "100%" }}>
                <div >
                    {
                        !imgSrc && <Webcam
                            ref={webcamRef}
                            audio={false}
                            videoConstraints={{ facingMode: 'user' }}
                        />
                    }
                    {imgSrc && <img src={imgSrc} alt="Captured Photo" />}

                </div>
                <Button onClick={capturePhoto}><Trans>Capture/Upload Photo</Trans></Button>
            </div>
        </>
    );
};

export default WebcamImage;
