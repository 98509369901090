import {Center, Container, Paper, Text} from '@mantine/core';
import ShramINlogo from "../../public/shraminLogo.png"
import styles from "../styles/LoginSection.module.css";
import React, {useEffect, useState} from "react";
import { RiWifiOffLine } from "react-icons/ri";
import { Trans } from '@lingui/macro';

function NoInternetComponent() {
    const [beforeInstallPrompt, setBeforeInstallPrompt] = useState(null);
    const installApp = () => {
        if (beforeInstallPrompt) {
            beforeInstallPrompt.prompt();
        }
    };
    useEffect(() => {
        const eventHandler = (event) => {
            setBeforeInstallPrompt(event);
        };

        const errorHandler = (event) => {
            console.log("error: " + event);
        };

        window.addEventListener("beforeinstallprompt", eventHandler);
        window.addEventListener("error", errorHandler);

        return () => {
            window.removeEventListener("beforeinstallprompt", eventHandler);
            window.removeEventListener("error", errorHandler);
        };
    }, []);

    return (
        <div className="container">
            <div className="app">
        <Paper radius="md" p="xl" >
            <Center>
                <img src={ShramINlogo} className={styles.siteLogo}  alt={"ShramIN Jobs"}/>
            </Center>

            <Center>
                <Text size="lg" fw={500}>
                    <Trans>Welcome to ShramIN Jobs</Trans>
                </Text>
            </Center>

            <Center>
                <Text style={{textAlign:"center"}} size="sm" fw={400}>
                    <Trans>Job Search and Career Guidance App for ITI and Diploma Candidates</Trans>
                </Text>
            </Center>
            <Center >
                <RiWifiOffLine size={50} style={{marginTop:20}} />
            </Center>

            <Text align="center" size="xl" weight={700}>
                <Trans>No internet connection</Trans>
            </Text>
            <Text align="center" color="dimmed" size="md" weight={700}>
                <Trans>Please check your internet connection</Trans>
            </Text>
            <div style={{backgroundColor:"#0180ff", padding:"10px", borderRadius:"10px", textAlign:"center", marginTop:"2rem", display: !beforeInstallPrompt && "none"}}>
                <p style={{ color: "#fff", width: "100%", margin: "auto", textAlign: "center", marginBottom: "10px" }}><Trans>Job Search and Career Guidance App for ITI and Diploma Candidates</Trans></p>
                <button id="installBtn" disabled={!beforeInstallPrompt}  onClick={installApp} style={{backgroundColor:"#fff", color:"#0180ff", border:"none", borderRadius:"10px", padding:"5px 30px", cursor:"pointer", fontSize:"16px", fontWeight:"400"}}>
                    <Trans>Install App</Trans>
                </button>
            </div>

        </Paper>
            </div>
        </div>
    );
}

export default NoInternetComponent;
